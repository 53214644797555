import React, {useState} from "react";
import TitleSection from "../../../TitleSection";
import FormContainer from "../../../form/FormContainer";
import UseVerify from "../../../../hooks/useVerify";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {getSocialMediaIcon} from "../../../../utils/typeOfSocialAccounts";
import arrow from "../../../../images/icons/arrow.svg";
import Loading from "../../../form/PageLoading/pageLoading";
import {encryptAndEncode} from "../../../../utils/crypt";
import {NotificationManager} from "react-notifications";
import {Error} from "../../../form/Error/Error";
import {useQuery} from "react-query";

const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}.${formattedMonth}.${year}`;
};

const returnStatus = (statusCampaign) => {
    if (statusCampaign === "wait") {
        return "pending";
    } else if (statusCampaign === "work") {
        return "distributing";
    } else if (statusCampaign === "estimate") {
        return "estimating";
    } else if (statusCampaign === "po waiting") {
        return "po waiting";
    } else {
        return "confirmed";
    }
};

const getBackgroundColor = (statusCampaign) => {
    switch (statusCampaign) {
        case "wait":
            return "#FF7A09";
        case "estimate":
            return "#FF3509";
        case "work":
            return "#3330E4";
        case "po waiting":
            return "#17A937";
        default:
            return "#3330E41A";
    }
}

const fetchData = async () => {
    const {dataFetch} = await UseVerify();
    const result = await axios(
        `${process.env.REACT_APP_SERVER}/promos/ongoing-campaigns-client?clientId=${encryptAndEncode(dataFetch._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}`
    );
    if (result.data.code === 200) {
        return {campaigns: result.data.campaigns, clientId: dataFetch._id};
    }

    throw new Error(result.data.message);
};

const AcountClientOngoingPromos = () => {
    const navigation = useNavigate();
    const [clientId, setClientId] = useState(null);

    const {data, isError, isLoading, isFetching} = useQuery(
        ["ongoing-promos", clientId],
        () => fetchData(),
        {
            retry: 2,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchIntervalInForeground: false,
            staleTime: 0,
            cacheTime: 0,
            onSuccess: (data) => {
                setClientId(data.clientId);
            },
            onError: (error) => {
                NotificationManager.error('Error loading campaigns', 'Error', 3000);
            }
        }
    );

    return (
        <section className="account-client-past-promos">
            <div className="container-form">
                <div className="account-client-past-promos-block" style={{position: 'relative'}}>
                    <div className="back-btn-title">
                        <button onClick={() => navigation("/account/client")}>
                            <img src={arrow} alt=''/>
                        </button>
                        <TitleSection title="MY" span="account"/>
                    </div>
                    <p className="account-client-past-promos-second">Ongoing promos</p>

                    {isError && <Error errorMessage="Something went wrong, please try again later" marginTop={30}/>}
                    {(isLoading || isFetching) && <Loading marginTop={30}/>}
                    {!isError && !isLoading && !isFetching && data.campaigns.length > 0 && (
                        <FormContainer
                            style={{
                                marginTop: window.innerWidth > 800 ? "70px" : "30px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                display: data.length !== 0 ? "block" : "none",
                                marginBottom: window.innerWidth > 400 ? "0" : "40px",
                                width: window.innerWidth < 900 ? "90%" : "100%",
                            }}
                        >
                            <div className="account-client-past-promos-form">
                                <ul className="account-client-past-promos-form-list">
                                    {data.campaigns.map((item, index) => (
                                        <li
                                            className="account-client-past-promos-form-item"
                                            key={item._id}
                                        >
                                            <div>
                                                <button
                                                    onClick={() => navigation(`/account/client/ongoing-promos/${encryptAndEncode(item._id, process.env.REACT_APP_URL_PAGES_SECRET_KEY)}`)}
                                                    className="account-client-past-promos-form-item-button">
                                                    <div
                                                        className="account-client-past-promos-form-item-button-inner-content">
                                                        <img src={getSocialMediaIcon(item?.socialMedia)} alt={"inst"}/>
                                                        <p>{item?.campaignName?.length > 10 ? `${item.campaignName.slice(0, 10)}...` : item.campaignName}</p>
                                                    </div>
                                                    <span
                                                        style={{background: getBackgroundColor(item.statusCampaign)}}>{returnStatus(item.statusCampaign)}</span>
                                                </button>
                                            </div>
                                            <p style={{
                                                fontFamily: "Geometria",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                                color: "#00000080",
                                            }}>{formatDate(item.createdAt)}</p>
                                            <p className="account-client-past-promos-form-text">
                                                Promo {index + 1}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </FormContainer>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AcountClientOngoingPromos;
