import React, {lazy, Suspense} from "react";
import {useSelector} from "react-redux";
import Loading from "../../../../form/PageLoading/pageLoading";

const CreateCampaignSelectAccounts = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignSelectAccounts"));
const CreateCampaignDetailsQuestion = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignDetailsQuestion"));
const CreateCampaignSaveAndEstimateCampaign = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignSaveAndEstimateCampaign"));
const CreateCampaignPostContent = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignPostContent"));
const CreateCampaignCampaignStrategy = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignCampaignStrategy"));
const CreateCampaignPayment = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignPayment"));

export const CreateCampaign = () => {
    const currentWindow = useSelector((state) => state.createPromo.currentWindow);

    const components = [
        CreateCampaignSelectAccounts,
        CreateCampaignDetailsQuestion,
        CreateCampaignSaveAndEstimateCampaign,
        CreateCampaignPostContent,
        CreateCampaignCampaignStrategy,
        CreateCampaignPayment,
    ]

    const SelectedComponent = components[currentWindow];

    return (
        <Suspense fallback={<Loading/>}>
            <SelectedComponent />
        </Suspense>
    );
};
