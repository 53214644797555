import React, { Component } from 'react';

class JivoChat extends Component {
  componentDidMount() {
    if (window.location.pathname.includes('admin')) {
      return;
    }

    const script = document.createElement('script');
    script.src = '//code.jivosite.com/widget/BCyqCB2cwk';
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    return <div id="jivo-chat-container"/>;
  }
}

export default JivoChat;
