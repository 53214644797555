import React, {useState} from "react";
import TitleSection from "../../../TitleSection";
import {useNavigate} from "react-router-dom";
import client from "../../../../images/icons/adminPanel/homePage/client 1.svg"
import campaigns from "../../../../images/icons/adminPanel/homePage/ads 1.svg"
import invoicesClients from "../../../../images/icons/adminPanel/homePage/takeover 1.svg"
import influencer from "../../../../images/icons/adminPanel/homePage/influencer 1.svg"
import offers from "../../../../images/icons/adminPanel/homePage/best-price 1.svg"
import invoicesInfluencers from "../../../../images/icons/adminPanel/homePage/money 1.svg"
import groupOfInfluencersForModalWindow from "../../../../images/icons/adminPanel/users-alt.svg";
import historyIconForModalWindow from "../../../../images/icons/adminPanel/time-past.svg";
import campaignsListIcon from "../../../../images/icons/adminPanel/homePage/clipboard-list.svg";
import proposalSystemIcon from "../../../../images/icons/adminPanel/homePage/pen.svg";
import '../../../../styles/components/_admin.scss';
import ModalWindow from "../../../ModalWindow";

const AdminHome = () => {
    const navigate = useNavigate();
    
    const [isSelectInfluencersPagesModalOpen, setIsSelectInfluencersPagesModalOpen] = useState(false);
    const [isSelectCampaignsPagesModalOpen, setIsSelectCampaignsPagesModalOpen] = useState(false);

    return (
        <section className="admin">
            <div>
                {isSelectInfluencersPagesModalOpen && (
                    <ModalWindow isOpen={isSelectInfluencersPagesModalOpen}
                                 setClose={setIsSelectInfluencersPagesModalOpen}>
                        <div style={{
                            padding: '64px 70px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: 30
                        }}>
                            <div onClick={() => navigate('/admin/influencers')}>
                                <button
                                    className="admin-home-menu-button"
                                >
                                    <img
                                        style={{width: 65}}
                                        src={groupOfInfluencersForModalWindow}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Influencers List</p>
                                </button>
                            </div>
                            <div onClick={() => navigate('/admin/influencers/history')}>
                                <button
                                    className="admin-home-menu-button"
                                >
                                    <img
                                        style={{width: 65}}
                                        src={historyIconForModalWindow}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Influencers History</p>
                                </button>
                            </div>
                        </div>
                    </ModalWindow>
                )}
                {isSelectCampaignsPagesModalOpen && (
                    <ModalWindow isOpen={isSelectCampaignsPagesModalOpen}
                                 setClose={setIsSelectCampaignsPagesModalOpen}>
                        <div style={{
                            padding: '64px 70px',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            gap: 30
                        }}>
                            <div onClick={() => navigate('/admin/campaigns')}>
                                <button
                                    className="admin-home-menu-button"
                                >
                                    <img
                                        style={{width: 65}}
                                        src={campaignsListIcon}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Campaigns List</p>
                                </button>
                            </div>
                            <div onClick={() => navigate('/admin/proposal-system')}>
                                <button
                                    className="admin-home-menu-button"
                                >
                                    <img
                                        style={{width: 65}}
                                        src={proposalSystemIcon}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Proposal System</p>
                                </button>
                            </div>
                        </div>
                    </ModalWindow>
                )}

                <div className="container" style={{display: 'flex', justifyContent: "center"}}>
                    <div className="admin-block">
                        <div className="admin-title-section">
                            <TitleSection title='the mighty' span='admin panel'/>
                        </div>
                        <ul className="admin-home-menu">
                            <li className="admin-home-menu-item">
                                <button
                                    className="admin-home-menu-button"
                                    onClick={() => navigate('/admin/clients')}
                                >
                                    <img
                                        src={client}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Clients</p>
                                </button>
                            </li>
                            <li className="admin-home-menu-item">
                                <button
                                    className="admin-home-menu-button"
                                    onClick={() => setIsSelectCampaignsPagesModalOpen(true)}
                                >
                                    <img
                                        src={campaigns}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Campaigns</p>
                                </button>
                            </li>
                            <li className="admin-home-menu-item">
                                <button
                                    className="admin-home-menu-button"
                                    onClick={() => navigate('/admin/invoices-clients')}
                                >
                                    <img
                                        src={invoicesClients}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Invoices Clients</p>
                                </button>
                            </li>
                            <li className="admin-home-menu-item">
                                <button
                                    className="admin-home-menu-button"
                                    onClick={() => setIsSelectInfluencersPagesModalOpen(true)}
                                >
                                    <img
                                        src={influencer}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Influencers</p>
                                </button>
                            </li>
                            <li className="admin-home-menu-item">
                                <button
                                    className="admin-home-menu-button"
                                    onClick={() => navigate('/admin/offers')}
                                >
                                    <img
                                        src={offers}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Offers</p>
                                </button>
                            </li>
                            <li className="admin-home-menu-item">
                                <button
                                    className="admin-home-menu-button"
                                    onClick={() => navigate('/admin/invoices-influencers')}
                                >
                                    <img
                                        src={invoicesInfluencers}
                                        alt="account-menu-icon"
                                    />
                                    <p className="admin-home-menu-button-text">Invoices Influencers</p>
                                </button>
                            </li>
                        </ul>

                        <p style={{
                            fontFamily: "Geometria",
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "center",
                            marginTop: 50
                        }}>
                            Patience!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AdminHome;