import React from 'react'
import errorIcon from "../../../images/icons/error-cross.svg";
import './error.css';

export const Error = ({errorMessage="Something went wrong on our end.", marginTop}) => {
    return (
        <div className="error-container" style={{marginTop: marginTop + "px"}}>
            <img id="error-image" src={errorIcon} alt="error" />
            <p id='error-title'>ERROR</p>
            <p id='error-text'>{errorMessage}</p>
        </div>
    )
}
