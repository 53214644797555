import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom";
import {NotificationContainer, NotificationManager} from "react-notifications";
import axios from "axios";
import {decodeAndDecrypt, encryptAndEncode} from "../../../../../../utils/crypt";
import Loading from "../../../../../form/PageLoading/pageLoading";
import {useDispatch, useSelector} from "react-redux";
import {
    clearState,
    setCreatedDate,
    setFullState,
    setPrice,
    setTotalFollowers
} from "../../../../../../redux/slice/proposal-system";
import {
    setFullState as setFullStateCreatePromo,
    setProposalSystemCampaignId
} from "../../../../../../redux/slice/create-promo";
import TitleSection from "../../../../../TitleSection";
import arrow from "../../../../../../images/icons/arrow.svg";
import shareIcon from "../../../../../../images/icons/Share.svg";
import watch from "../../../../../../images/icons/view 1.svg";
import {NewCampaignDetails} from "../../../../../form/NewCampaignDetails/NewCampaignDetails";
import {
    AccountClientSharedCampaignTableDesktop
} from "./AccountClientSharedCampaignTableDesktop";
import StandardButton from "../../../../../form/StandardButton";
import {AccountClientSharedCampaignTableMobile} from "./AccountClientSharedCampaignTableMobile";

export const AccountClientSharedCampaignPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const campaignId = decodeAndDecrypt(useParams().campaignId, process.env.REACT_APP_PROPOSAL_SHARE_SECRET_KEY);
    const campaignName = useSelector(state => state.proposalSystem.campaignName);
    const totalFollowers = useSelector(state => state.proposalSystem.totalFollowers);
    const price = useSelector(state => state.proposalSystem.price);
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector(state => state.proposalSystem.campaignContent);
    const createdDate = useSelector(state => state.proposalSystem.createdDate);
    const selectedSocialMedias = useSelector(state => state.proposalSystem.selectedSocialMedias);

    const [isLoading, setIsLoading] = useState(true);
    const [isGenresShown, setIsGenresShown] = useState(false);
    const [isCountriesShown, setIsCountriesShown] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        getCampaignData();
    }, [campaignId]);

    const getCampaignData = async () => {
        try {
            const result = await axios.get(`${process.env.REACT_APP_SERVER}/proposal-system/get-proposal-campaign/${campaignId}`);

            if (result.data.status === 200) {
                const selectedSocialMedias = [];

                result.data.proposalCampaign.selectInfluencers && result.data.proposalCampaign.selectInfluencers.forEach(acc => {
                    if (selectedSocialMedias && !selectedSocialMedias.includes(acc.socialMedia)) {
                        selectedSocialMedias.push(acc.socialMedia);
                    }
                })

                setIsLoading(false);
                dispatch(setFullState({
                    campaignId: campaignId,
                    selectedSocialMedias: selectedSocialMedias,
                    selectedClientId: result.data.proposalCampaign.clientId,
                    campaignName: result.data.proposalCampaign.campaignName,
                    addedAccounts: result.data.proposalCampaign.selectInfluencers.map(acc => (
                        {
                            influencerId: acc.accountId,
                            instagramUsername: acc.socialMediaUsername,
                            selectedCampaignContentItem: acc.selectedCampaignContentItem ?? '',
                            dateRequest: acc.dateRequest ?? 'ASAP',
                            followersNumber: acc.followersNumber,
                            socialMedia: acc.socialMedia,
                            genres: acc.genres,
                            countries: acc.countries,
                        }
                    )),
                    campaignContent: result.data.proposalCampaign.campaignContent,
                    price: result.data.proposalCampaign.publicPrice ?? 0,
                }))
                dispatch(setTotalFollowers(result.data.proposalCampaign.totalFollowers));
                dispatch(setPrice(result.data.proposalCampaign.publicPrice));
                dispatch(setCreatedDate(result.data.proposalCampaign.createdAt));
                return;
            }

            NotificationManager.error('Failed to load campaign', 'Error', 3000);
        } catch (err) {
            NotificationManager.error('Failed to load campaign', 'Error', 3000);
            console.error(err);
        }
    };

    const handleCopyLink = () => {
        try {
            navigator.clipboard.writeText(window.location.href);
            NotificationManager.success('Link copied to clipboard', '', 3000);
        } catch (err) {
            NotificationManager.error('Failed to copy link to clipboard');
        }
    };

    const approveCampaign = () => {
        try {
            const socialMedia = selectedSocialMedias?.length > 1 ? 'multipromo' : selectedSocialMedias[0];

            dispatch(setFullStateCreatePromo({
                currentWindow: socialMedia === 'multipromo' ? 3 : 5,
                currency: "€",
                amount: price,
                selectPrice: {
                    variant: 0,
                    price: price,
                },
                selectInfluencers: addedAccounts,
                selectPriceInfluencers: addedAccounts,
                campaignName: campaignName,
                paymentType: "",
                paymentStatus: "wait",
                createdAt: createdDate,
                videos: campaignContent,
                socialMedia: socialMedia,
                canGoBack: false,
            }))
            dispatch(setProposalSystemCampaignId(campaignId));
            navigate(`/account/client/promo/${socialMedia}`);
        } catch (err) {
            NotificationManager.error(err.message || 'Failed to approve campaign', 'Error', 3000);
            console.error(err);
        }
    };

    const sendMailAboutChanges = async () => {
        try {
            const encryptedCampaignId = encryptAndEncode(campaignId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);
            const result = await axios.post(`${process.env.REACT_APP_SERVER}/proposal-system/send-email-to-admin-changes/${encryptedCampaignId}/${campaignName}`);

            if (result.data.status === 200) {
                NotificationManager.success('Email sent', '', 3000);
                return;
            }

            NotificationManager.error('Failed to send email', 'Error', 3000);
        } catch (err) {
            NotificationManager.error('Failed to send email', 'Error', 3000);
            console.error(err);
        }
    };

    if (isLoading) return <Loading/>;
    return (
        <section className="proposal-system">
            <div className="proposal-system-title-with-btn">
                <div id="back-btn" onClick={() => {
                    dispatch(clearState());
                    navigate("/account/client")
                }}>
                    <img src={arrow} alt="" style={{transform: "rotate(180deg)"}}/>
                </div>
                <div id="title">
                    <TitleSection title="Campaign" span="strategy"/>
                </div>
                <div id="share" onClick={() => handleCopyLink()}>
                    <img src={shareIcon} alt={""}/>
                </div>
            </div>

            <div className="proposal-system-influencers">
                <div className="details">
                                <div className="details-campaign-name" style={{marginTop: windowWidth < 600 ? 30 : 0}}>
                                    {windowWidth > 634 ? (
                                        <input
                                            type="text"
                                            placeholder="Campaign Name"
                                value={campaignName}
                                readOnly={true}
                            />
                        ) : (
                            <p>{campaignName}</p>
                        )}
                    </div>

                    {windowWidth < 800 && (
                        <div className="mobile-share-button">
                            <img src={shareIcon} alt={"share"} onClick={() => handleCopyLink()}/>
                            <p>Public Share Link</p>
                        </div>
                    )}

                    <div>
                        <NewCampaignDetails date={createdDate} totalFollowers={totalFollowers} price={price}
                                            countOfAccounts={addedAccounts.length}
                                            countOfVideos={campaignContent.length}/>
                    </div>
                </div>
            </div>

            <div className="proposal-system-shared-campaign-buttons">
                <StandardButton style={{width: 203, height: 41, fontSize: 15, padding: 0}} isBlue={true}
                                onClick={approveCampaign}
                                text={"APPROVE CAMPAIGN"}/>
                <StandardButton style={{width: 203, height: 41, fontSize: 15, padding: 0}} isBlue={true}
                                onClick={sendMailAboutChanges}
                                text={"REQUEST CHANGE"}/>
            </div>

            {windowWidth > 1150 && (
                <div className={`hide-genres-countries-buttons ${isGenresShown && isCountriesShown && "hidden"}`}>
                    <button className={`btn ${isGenresShown && 'hidden'}`}
                            onClick={() => setIsGenresShown(!isGenresShown)}>
                        <img src={watch} alt={""}/>
                        <p>SHOW MUSIC GENRES</p>
                    </button>

                    <button className={`btn ${isCountriesShown && 'hidden'}`}
                            onClick={() => setIsCountriesShown(!isCountriesShown)}>
                        <img src={watch} alt={""}/>
                        <p>SHOW AUDIENCE LOCATION</p>
                    </button>
                </div>
            )}

            {windowWidth > 1150 ? (
                <div
                    className={`proposal-system-shared-campaign-table-container ${(isGenresShown || isCountriesShown) ? "wider" : ""}`}>
                    {addedAccounts.length > 0 &&
                        <AccountClientSharedCampaignTableDesktop isGenresShown={isGenresShown}
                                                                 setIsCountriesShown={setIsCountriesShown}
                                                                 setIsGenresShown={setIsGenresShown}
                                                                 isCountriesShown={isCountriesShown}/>}
                </div>
            ) : (
                <div className="proposal-system-shared-campaign-mobile-table-container-">
                    {addedAccounts.length > 0 && <AccountClientSharedCampaignTableMobile/>}
                </div>
            )}

            <NotificationContainer/>
        </section>
    )
}
