import React from "react";
import "./loading.css";
import {BarLoader} from "react-spinners";

const Loading = ({marginTop = 0}) => {
    return (
        <div style={{marginTop: marginTop}} className="loading-container">
            <BarLoader color={"#3330e4"} loading={true} size={100}/>
        </div>
    );
};

export default Loading;
