import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import ReportCampaigns from "../../../components/layout/account/client/ReportCampaign/ReportCampaigns";

const AcountClientOngoingCurrentPage = () => {
  return (
    <>
      <Header path="Sponsoring client / My account / Campaigns in Progress" />
      <ReportCampaigns />
      <Background />
    </>
  );
};

export default AcountClientOngoingCurrentPage;
