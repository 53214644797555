import React from 'react'
import TitleSection from "../../../../../TitleSection";
import {
    SelectSocialMediaHorizontalList
} from "../../../../../form/SelectSocialMediaHorizontal/SelectSocialMediaHorizontalList";
import {useDispatch, useSelector} from "react-redux";
import {setSelectSocialMedia} from "../../../../../../redux/slice/proposal-system";

export const SelectSocialMedia = () => {
    const dispatch = useDispatch();

    const selectedSocialMedias = useSelector(state => state.proposalSystem.selectedSocialMedias);
    
    const selectSocialMedia = (socialMedia) => {
        dispatch(setSelectSocialMedia(socialMedia));
    }

    return (
        <section className="proposal-system-component" style={{height: '100%'}}>
            <div className="account-client-title">
                <TitleSection title={"Select"} span={"social Media"}/>
            </div>
            {selectedSocialMedias && (
                <div style={{marginTop: 40}}>
                    <SelectSocialMediaHorizontalList selectSocialMedia={selectSocialMedia} selectedSocialMedia={selectedSocialMedias.map(socialMedia => socialMedia) || ""}/>
                </div>
            )}
        </section>
    )
}
