import React, {lazy, Suspense} from 'react';
import { useSelector } from "react-redux";
import Loading from "../../../../form/PageLoading/pageLoading";

const CreateMultiCampaignSelectAccounts = lazy(() => import("./CreateCampaignPagesComponents/CreateMultiCampaignSelectAccounts"));
const CreateCampaignPostContent = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignPostContent"));
const CreateCampaignCampaignStrategy = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignCampaignStrategy"))
const CreateCampaignPayment = lazy(() => import("./CreateCampaignPagesComponents/CreateCampaignPayment"));

export const AccountClientCreateMultiCampaign = () => {
    const currentWindow = useSelector((state) => state.createPromo.currentWindow);

    const components = [
        CreateMultiCampaignSelectAccounts,
        CreateCampaignPostContent,
        CreateCampaignCampaignStrategy,
        CreateCampaignPayment,
    ];

    const SelectedComponent = components[currentWindow];

    return (
        <Suspense fallback={<Loading/>}>
            <SelectedComponent />
        </Suspense>
    );
};
