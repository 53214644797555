import React, {useState} from 'react'
import {useSelector} from "react-redux";
import videoImg from "../../../../../../images/icons/iconsForCampaignReport/video 1.svg";

export const AccountClientSharedCampaignTableMobile = () => {
    const totalFollowers = useSelector(state => state.proposalSystem.totalFollowers);
    const price = useSelector(state => state.proposalSystem.price);
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector(state => state.proposalSystem.campaignContent);

    const [seeAllDescriptions, setSeeAllDescriptions] = useState({});
    const [seeAllSpecialRequests, setSeeAllSpecialRequests] = useState({});

    const getContentItemField = (contentItemFieldName, selectedCampaignContentItemId) => {
        const contentItem = campaignContent.find(contentItem => contentItem._id === selectedCampaignContentItemId);
        return contentItem ? contentItem[contentItemFieldName] : "";
    };

    const toggleSeeAllPostDescription = (username) => {
        setSeeAllDescriptions(prevState => ({
            ...prevState,
            [username]: !prevState[username]
        }));
    };
    
    const toggleSeeAllSpecialRequests = (username) => {
        setSeeAllSpecialRequests(prevState => ({
            ...prevState,
            [username]: !prevState[username]
        }));
    }
    
    return (<div className="proposal-system-shared-campaign-mobile-table">
        {addedAccounts.map((addedAccount, index) => {
            const isExpandedPostDescription = seeAllDescriptions[addedAccount.instagramUsername] || false;
            const isExpandedSpecialRequests = seeAllSpecialRequests[addedAccount.instagramUsername] || false;
            
            return (
                <div className="account-item">
                    <div className="header" style={{borderRadius: index === 0 ? "30px 30px 0 0" : "0"}}>
                        {addedAccount.instagramUsername}
                    </div>
                    <div className="body">
                        <div className="account-info"
                             style={{borderRadius: index === addedAccounts.length - 1 ? "0 0 0 30px" : 0}}>
                            <div id="total-followers" style={{marginTop: 13}}>
                                <p>Total Followers</p>
                                <span>{addedAccount.followersNumber}</span>
                            </div>
                            <div id="genres">
                                <p>Genres</p>
                                {addedAccount.genres?.length ? addedAccount.genres.map(genre => (<span>{genre}</span>)) : null}
                            </div>
                            <div id="countries" style={{marginBottom: 9}}>
                                <p>Top 5 Countries</p>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {addedAccount.countries?.length ? addedAccount.countries.map(country => (
                                        <span>{country.country} <span
                                            style={{fontSize: 9, marginLeft: 1}}>{country.percentage}%</span></span>)) : null}
                                </div>
                            </div>
                            {index === addedAccounts.length - 1 && (
                                <div className="footer-left-side">
                                    <p>PRICE: {price}€</p>
                                </div>
                            )}
                        </div>

                        <div className="campaign-info"
                             style={{borderRadius: index === addedAccounts.length - 1 ? "0 0 30px 0" : 0}}>
                            <div id="date-request" style={{marginTop: 13}}>
                                <p>Date Request</p>
                                <span>{addedAccount.dateRequest}</span>
                            </div>
                            <div id="videoLink">
                                <p>Video</p>
                                {addedAccount.selectedCampaignContentItem && (
                                    <div id="img" onClick={() => window.open(campaignContent.find(item => item._id === addedAccount.selectedCampaignContentItem).videoLink, "_blank")}>
                                        <img src={videoImg} alt="video"/>
                                        <span>#{campaignContent.findIndex((content) => addedAccount.selectedCampaignContentItem === content._id) + 1}</span>
                                    </div>
                                )}
                            </div>
                            <div id="post-description">
                                <p>Post Description</p>
                                <div style={{maxHeight: isExpandedPostDescription ? 1000 : 58, overflow: "hidden"}}>
                                    <span>{addedAccount.selectedCampaignContentItem && getContentItemField("postDescription", addedAccount.selectedCampaignContentItem)}</span>
                                </div>
                                <button onClick={() => toggleSeeAllPostDescription(addedAccount.instagramUsername)}>
                                    {isExpandedPostDescription ? "SEE LESS" : "SEE ALL"}
                                </button>
                            </div>
                            <div id="story-tag">
                                <p>Story Tag</p>
                                <span>{addedAccount.selectedCampaignContentItem && getContentItemField("storyTag", addedAccount.selectedCampaignContentItem)}</span>
                            </div>
                            <div id="story-link">
                                <p>Story Link</p>
                                <div>
                            <span
                                onClick={() => window.open(getContentItemField("swipeUpLink", addedAccount.selectedCampaignContentItem), "_blank")}>
                            {addedAccount.selectedCampaignContentItem && getContentItemField("swipeUpLink", addedAccount.selectedCampaignContentItem)}
                        </span>
                                </div>
                            </div>
                            <div id="post-description" style={{marginBottom: 9}}>
                                <p>Special Requests</p>
                                <div style={{maxHeight: isExpandedSpecialRequests ? 1000 : 58, overflow: "hidden"}}>
                                    <span>{addedAccount.selectedCampaignContentItem && getContentItemField("specialWishes", addedAccount.selectedCampaignContentItem)}</span>
                                </div>
                                <button onClick={() => toggleSeeAllSpecialRequests(addedAccount.instagramUsername)}>
                                    {isExpandedSpecialRequests ? "SEE LESS" : "SEE ALL"}
                                </button>
                            </div>
                            {index === addedAccounts.length - 1 && (
                                <div className="footer-right-side">
                                    <p>FOLLOWERS: {totalFollowers}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        })}
    </div>)
}
