import React, {useEffect, useRef, useState} from "react";
import TitleSection from "../../../../../TitleSection";
import backBtn from "../../../../../../images/icons/arrow.svg";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import PageLoading from "../../../../../form/PageLoading/pageLoading";
import instaIcon from "../../../../../../images/icons/socialMedias/instagram.png";
import tiktokIcon from "../../../../../../images/icons/socialMedias/tiktok.png";
import facebookIcon from "../../../../../../images/icons/socialMedias/facebook.png";
import youtubeIcon from "../../../../../../images/icons/socialMedias/youtube.png";
import spotifyIcon from "../../../../../../images/icons/socialMedias/spotify.png";
import soundcloudIcon from "../../../../../../images/icons/socialMedias/soundcloud.png";
import tabletIcon from "../../../../../../images/icons/socialMedias/tablet.png";
import altAvatar from "../../../../../../images/icons/rkqZiVo 69.svg"
import watch from "../../../../../../images/icons/view 1.svg";
import edit from "../../../../../../images/icons/edit 1.svg";
import linkIcon from "../../../../../../images/icons/iconsForCampaignReport/link 1.svg";
import instaRefLogo from "../../../../../../images/icons/iconsForCampaignReport/instagram 1.svg";
import hiddenIcon from "../../../../../../images/icons/hidden 63.svg";
import SubmitButton from "../../form/Influencers/SubmitFooter/SubmitButton";
import {formatDateStringReport} from "../../../../../../utils/validations";
import ModalWindow from "../../../../../ModalWindow";
import TextInput from "../../../../../form/TextInput";
import StandardButton from "../../../../../form/StandardButton";
import SearchBarComponent from "../../../../../form/SearchBar/SearchBar";
import SearchCountry from "../../../../../form/SearchCountry/SearchCountry";
import InputFile from "../../../../../form/InputFile";
import {NotificationManager} from "react-notifications";
import Loading from "../../../../../form/PageLoading/pageLoading";

const AdminInfluencers = () => {
    const [data, setData] = useState([]);
    const [searchResult, setSearchResult] = useState(null);
    const [activePlatform, setActivePlatform] = useState("Instagram");
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [tableWidth, setTableWidth] = useState(500);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isChangeLogoModalOpen, setIsChangeLogoModalOpen] = useState(false);
    const [pagedData, setPagedData] = useState([]);
    const PAGE_SIZE = 20;

    const platforms = [{
        name: 'Instagram',
        secondName: 'IG',
        icon: instaIcon
    }, {
        name: 'TikTok',
        secondName: 'TT',
        icon: tiktokIcon
    }, {
        name: 'Facebook',
        secondName: 'FB',
        icon: facebookIcon
    }, {
        name: 'Youtube',
        secondName: 'YT',
        icon: youtubeIcon
    }, {
        name: 'Spotify',
        secondName: 'SP',
        icon: spotifyIcon
    }, {
        name: 'Soundcloud',
        secondName: 'SC',
        icon: soundcloudIcon
    }, {
        name: 'Press',
        secondName: 'PR',
        icon: tabletIcon
    }]

    const [fieldsForChange, setFieldsForChange] = useState({
        instagramId: '',
        influencerId: '',
        instagramUsername: '',
        firstName: '',
        email: '',
        phone: '',
        followersNumber: '',
        instagramLink: '',
        price: '',
        publicPrice: '',
        balance: '',
        internalNote: '',
        logo: '',
        genres: [],
        categories: [],
        countries: []
    })

    const containerRef = useRef(null);
    const saveChangesRef = useRef(null);

    const genres = ['Techno (Melodic, Minimal)', 'Techno (Hard, Peak)', 'House (Tech House)', 'House (Melodic, Afro)', 'EDM', 'D&B', 'BASS', 'PSY']
    const categories = ['Dancing', 'Meme', 'Ibiza']
    const countries = ['US', 'Canada', 'UK', 'Germany', 'Italy', 'Spain', 'France', 'Mexico', 'Brazil', 'Argentina', 'Colombia', 'Russia', 'India', 'Indonesia', 'Chile', 'Serbia', 'Croatia', 'Ireland', 'Australia', 'Romania', 'Czech Republic', 'Austria', 'New Zealand', 'Kazakhstan', 'Iran', 'Portugal', 'UAE', 'Slovakia', 'Egypt', 'Tunisia', 'Libya', 'Algeria', 'Turkey', 'Morocco', 'Guatemala', 'Peru', 'Malaysia', 'South Africa', 'Ukraine', 'Moldova', 'Poland', 'Netherlands', 'Georgia', 'Ecuador', 'Sri Lanka']
    const [hiddenGenres, setHiddenGenres] = useState(false);
    const [hiddenCategories, setHiddenCategories] = useState(false);
    const [hiddenCountries, setHiddenCountries] = useState(false);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);

    useEffect(() => {
        const sliceData = data.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
        setPagedData(sliceData);
    }, [currentPage, data]);

    useEffect(() => {
        if (searchResult === null) {
            setSelectedGenres([]);
            setSelectedCategories([]);
            setSelectedCountries([]);
            return;
        }

        const accountDetails = searchResult.account;

        const genresSet = new Set();
        const categoriesSet = new Set();

        if (accountDetails.musicStyle || accountDetails.musicSubStyles || accountDetails.musicStyleOther) {
            if (accountDetails.musicSubStyles?.length > 0) {
                for (let i = 0; i < accountDetails.musicSubStyles.length; i++) {
                    if (accountDetails.musicSubStyles[i] === "Melodic, Minimal") {
                        genresSet.add("Techno (Melodic, Minimal)");
                    }
                    if (accountDetails.musicSubStyles[i] === "Hard, Peak") {
                        genresSet.add("Techno (Hard, Peak)");
                    }
                    if (accountDetails.musicSubStyles[i] === "Tech House") {
                        genresSet.add("House (Tech House)");
                    }
                    if (accountDetails.musicSubStyles[i] === "Melodic, Afro") {
                        genresSet.add("House (Melodic, Afro)");
                    }
                }
            } else if (accountDetails.musicStyle) {
                genresSet.add(accountDetails.musicStyle);
            }

            if (Array.isArray(accountDetails.musicStyleOther) && accountDetails.musicStyleOther.length > 0) {
                for (let i = 0; i < accountDetails.musicStyleOther.length; i++) {
                    if (accountDetails.musicStyleOther[i] !== "House") {
                        genresSet.add(accountDetails.musicStyleOther[i]);
                    }
                }
            }
        }

        setSelectedGenres(Array.from(genresSet));

        if (Array.isArray(accountDetails.categories) && accountDetails.categories.length > 0) {
            for (let i = 0; i < accountDetails.categories.length; i++) {
                if (accountDetails.categories[i] !== null) {
                    categoriesSet.add(accountDetails.categories[i]);
                }
            }
        }

        setSelectedCategories(Array.from(categoriesSet));

        if (Array.isArray(accountDetails.countries) && accountDetails.countries.length > 0) {
            setSelectedCountries(accountDetails.countries);
        }
    }, [searchResult]);

    const generatePageNumbers = () => {
        const totalPages = Math.ceil(data.length / PAGE_SIZE);
        const delta = 2; // Количество страниц до и после текущей
        const startPage = Math.max(currentPage - delta, 0);
        const endPage = Math.min(currentPage + delta, totalPages - 1);

        let pageNumbers = [];
        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    };

    const handleGenreSelect = (genre) => {
        if (selectedGenres.includes(genre)) {
            setSelectedGenres(selectedGenres.filter((selectedGenre) => selectedGenre !== genre));
        } else {
            setSelectedGenres([...selectedGenres, genre]);
        }
    }

    const handleCategorySelect = (category) => {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter((selectedCategory) => selectedCategory !== category));
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    }

    const filterMusicGenres = () => {
        let musicStyle;
        let musicSubStyles = [];
        let musicStyleOther = [];

        const extractSubGenres = (genre) => {
            const match = genre.match(/\(([^)]+)\)/);
            return match ? [match[1].trim()] : [];
        };

        if (selectedGenres.includes("Techno (Melodic, Minimal)") || selectedGenres.includes("Techno (Hard, Peak)")) {
            musicStyle = "Techno";
            musicSubStyles = selectedGenres.flatMap(genre => extractSubGenres(genre));
        }

        if (selectedGenres.includes("House (Tech House)") || selectedGenres.includes("House (Melodic, Afro)")) {
            musicStyle = musicStyle === "Techno" ? musicStyle : "House";
            musicSubStyles = selectedGenres.flatMap(genre => extractSubGenres(genre));
        }

        if (musicStyle === "Techno" || musicStyle === "House") {
            selectedGenres.forEach(genre => {
                if (!musicStyleOther.includes(genre) && !genre.includes(musicStyle)) {
                    if (genre.includes("House") && !musicStyleOther.includes("House")) {
                        musicStyleOther.push("House");
                    } else if (!genre.includes("House")) {
                        musicStyleOther.push(genre);
                    }
                }
            });
        }

        if (!selectedGenres.includes("Techno (Melodic, Minimal)") && !selectedGenres.includes("Techno (Hard, Peak)") && !selectedGenres.includes("House (Tech House)") && !selectedGenres.includes("House (Melodic, Afro)")) {
            musicStyle = selectedGenres[0];
            if (selectedGenres.length > 1) {
                musicStyleOther = selectedGenres.slice(1);
            }
        }

        return {musicStyle, musicSubStyles, musicStyleOther};
    };

    const handleCountryChange = (index, field, value) => {
        if (field === 'percentage' && !/^[0-9]*\.?[0-9]*$/.test(value)) return;

        const newCountries = [...selectedCountries];
        newCountries[index] = {...newCountries[index], [field]: value};
        setSelectedCountries(newCountries);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    const toggleHiddenGenres = () => {
        setHiddenGenres(!hiddenGenres);
    };

    const toggleHiddenCategories = () => {
        setHiddenCategories(!hiddenCategories);
    };

    const toggleHiddenCountries = () => {
        setHiddenCountries(!hiddenCountries);
    };

    const isColumnHidden = (column) => hiddenColumns.includes(column);
    const navigate = useNavigate();

    useEffect(() => {
        getData('instagram');
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                saveChangesRef.current &&
                !saveChangesRef.current.contains(event.target)
            ) {
                setFieldsForChange({
                    instagramId: '',
                    influencerId: '',
                    instagramUsername: '',
                    firstName: '',
                    email: '',
                    phone: '',
                    followersNumber: '',
                    instagramLink: '',
                    price: '',
                    publicPrice: '',
                    balance: '',
                    internalNote: '',
                    logo: ''
                });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const selectInfluencer = (influencer) => {
        if (fieldsForChange.instagramId !== influencer.account._id) {
            setFieldsForChange({
                instagramId: influencer.account._id,
                influencerId: influencer.influencerId,
                instagramUsername: influencer.account.instagramUsername,
                firstName: influencer.firstName,
                email: influencer.email,
                phone: influencer.phone,
                followersNumber: influencer.account.followersNumber,
                instagramLink: influencer.account.instagramLink,
                price: influencer.account.price,
                publicPrice: influencer.account.publicPrice,
                balance: influencer.balance,
                internalNote: influencer.internalNote,
                logo: influencer.account.logo,
            });
        }
    }

    const updateInfluencerFieldsInput = (e) => {
        setFieldsForChange({
            ...fieldsForChange,
            [e.target.name]: e.target.value,
        });
    }

    const updateInfluencerOnServer = async (newLogo = null) => {
        const result = await axios.put(
            `${process.env.REACT_APP_SERVER}/admin/influencer/update/personal`,
            {
                id: fieldsForChange.influencerId,
                firstName: fieldsForChange.firstName,
                email: fieldsForChange.email,
                phone: fieldsForChange.phone,
                balance: fieldsForChange.balance,
                internalNote: fieldsForChange.internalNote,
            }
        );
        if (result.status === 200) {
            const updatedData = {
                influencerId: fieldsForChange.influencerId,
                instagramId: fieldsForChange.instagramId,
                instagramUsername: fieldsForChange.instagramUsername,
                followersNumber: fieldsForChange.followersNumber,
                instagramLink: fieldsForChange.instagramLink,
                price: fieldsForChange.price,
                publicPrice: fieldsForChange.publicPrice,
                socialMedia: activePlatform.toLowerCase(),
                logo: newLogo ? newLogo : fieldsForChange.logo,
            };

            if (searchResult !== null) {
                const {musicStyle, musicSubStyles, musicStyleOther} = filterMusicGenres();
                Object.assign(updatedData, {
                    musicStyle: musicStyle,
                    musicSubStyles: musicSubStyles,
                    musicStyleOther: musicStyleOther,
                    countries: selectedCountries,
                    categories: selectedCategories,
                });
            }

            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/influencer/update/socialMediaAccount`,
                updatedData
            );

            if (result.status === 200) {
                if (searchResult) {
                    await updateInfluencerData(fieldsForChange);
                } else {
                    await getData(activePlatform.toLowerCase());
                }

                setFieldsForChange({
                    instagramId: '',
                    influencerId: '',
                    instagramUsername: '',
                    firstName: '',
                    email: '',
                    phone: '',
                    followersNumber: '',
                    instagramLink: '',
                    price: '',
                    publicPrice: '',
                    balance: '',
                    internalNote: '',
                    logo: ''
                });
            }
        }
    }

    const updateInfluencerData = async (influencer) => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_SERVER}/admin/influencer/getOne/${influencer.influencerId}/${activePlatform.toLowerCase()}/${influencer.instagramUsername}`
            );
            if (result.status === 200) {
                const updatedInfluencer = result.data.data;
                const updatedInfluencers = data.map((item) => {
                    if (item.influencerId === updatedInfluencer.influencerId) {
                        return {
                            ...item,
                            firstName: updatedInfluencer.firstName,
                            phone: updatedInfluencer.phone,
                            balance: updatedInfluencer.balance,
                            email: updatedInfluencer.email,
                            internalNote: updatedInfluencer.internalNote,
                            account: {
                                ...item.account,
                                ...(item.account.instagramUsername === updatedInfluencer.account.instagramUsername
                                    ? updatedInfluencer.account
                                    : {}),
                            },
                        };
                    }
                    return item;
                });

                setData(updatedInfluencers);

                if (searchResult) {
                    setSearchResult(updatedInfluencers.find(item => item.influencerId === searchResult.influencerId && item.account.instagramUsername === searchResult.account.instagramUsername));
                }
            } else {
                console.warn("Сервер вернул некорректный статус:", result.status);
            }
        } catch (error) {
            console.error("Ошибка при обновлении данных инфлюенсера:", error);
        }
    };

    const getData = async (socialMedia) => {
        const result = await axios(
            `${process.env.REACT_APP_SERVER}/admin/influencer/getAll/${socialMedia}`
        );

        if (result.status === 200) {
            setData(result.data.data);
        } else {
            setData([]);
        }
    }

    const handlePlatformClick = (platform) => {
        if (platform === 'Instagram') {
            setActivePlatform('Instagram');
        } else {
            if (activePlatform === platform) {
                setActivePlatform('Instagram');
            } else {
                setActivePlatform(platform);
            }
        }
    }

    useEffect(() => {
        getData(activePlatform.toLowerCase());
        setSearchResult(null);
        setSelectedCountries([]);
        setSelectedCategories([]);
        setSelectedGenres([]);
    }, [activePlatform]);

    const getSecondNameForActivePlatform = () => {
        return platforms.find(platform => platform.name === activePlatform).secondName;
    }

    const searchByUsername = (data, searchInput) => {
        return data.filter(influencer =>
            influencer.account.instagramUsername && influencer.account.instagramUsername.toLowerCase().includes(searchInput.toLowerCase())
        );
    };

    const checkGenre = (genre, account) => {
        if (!account) return false;

        const lowerCaseGenre = genre.toLowerCase();

        let normalizedGenre;
        switch (lowerCaseGenre) {
            case 'psy':
                normalizedGenre = 'Psy, Trance';
                break;
            case 'bass':
                normalizedGenre = 'Bass';
                break;
            default:
                normalizedGenre = genre;
        }

        const isKnownGenre = genres.includes(genre);

        if (isKnownGenre) {
            switch (normalizedGenre) {
                case 'Techno (Melodic, Minimal)':
                    return account.musicStyle === 'Techno' && account.musicSubStyles && account.musicSubStyles.includes('Melodic, Minimal');
                case 'Techno (Hard, Peak)':
                    return account.musicStyle === 'Techno' && account.musicSubStyles && account.musicSubStyles.includes('Hard, Peak');
                case 'House (Tech House)':
                    return account.musicStyle === 'House' && account.musicSubStyles && account.musicSubStyles.includes('Tech House');
                case 'House (Melodic, Afro)':
                    return account.musicStyle === 'House' && account.musicSubStyles && account.musicSubStyles.includes('Melodic, Afro');
                case 'EDM':
                    return account.musicStyle === 'EDM';
                case 'D&B':
                    return account.musicStyle === 'D&B';
                case 'Bass':
                    return account.musicStyle === 'Bass';
                case 'Psy, Trance':
                    return account.musicStyle === 'Psy, Trance';
                default:
                    return false;
            }
        }

        return account.musicStyleOther && account.musicStyleOther.includes(normalizedGenre);
    };

    const calculatePricePerFollower = (price, followers) => {
        const numericPrice = parseFloat(price.replace(/[^0-9.]/g, ''));
        if (followers === 0 || isNaN(numericPrice)) return 0;
        return (numericPrice / followers).toFixed(6);
    };

    const checkCategory = (category, account) => {
        if (!account?.categories) return false;
        switch (category) {
            case 'Dancing':
                return account?.categories.includes('Dancing');
            case 'Meme':
                return account?.categories.includes('Meme');
            case 'Ibiza':
                return account?.categories.includes('Ibiza');
            default:
                return false;
        }
    }

    const checkCountry = (country, account) => {
        if (!account?.countries) return false;
        const foundCountry = account?.countries.find(item => item.country === country);

        return foundCountry ? {found: true, percentage: foundCountry.percentage} : {found: false};
    };

    const handleHiddenColumns = (column) => {
        if (hiddenColumns.includes(column)) {
            setHiddenColumns(hiddenColumns.filter(item => item !== column));
        } else {
            setHiddenColumns([...hiddenColumns, column]);
        }
    };

    const restoreColumn = (column) => {
        setHiddenColumns(hiddenColumns.filter(item => item !== column));
    };

    const calculateTableWidth = () => {
        const hiddenCount = hiddenColumns.length;
        const countTrue = [hiddenGenres, hiddenCategories, hiddenCountries].filter(Boolean).length;

        if (countTrue === 3) {
            return 100;
        } else if (countTrue === 2) {
            if (hiddenCountries) {
                return 150;
            } else {
                return 330;
            }
        } else if (countTrue === 1) {
            if (hiddenCountries) {
                return 150;
            } else {
                return 400;
            }
        }

        let width = 500;

        const additionalReduction = Math.max(0, hiddenCount - 3) * 25;
        return Math.max(0, width - additionalReduction);
    };

    useEffect(() => {
        setTableWidth(calculateTableWidth());
    }, [hiddenColumns, hiddenGenres, hiddenCategories, hiddenCountries]);

    const EditLinkModal = () => {
        const [newLink, setNewLink] = useState('');

        const handleInputLinkChange = (e) => {
            setNewLink(e.target.value);
        };

        const saveNewLinkToFieldsForChange = () => {
            setFieldsForChange({
                ...fieldsForChange,
                instagramLink: newLink,
            });
        };

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
            }}>
                <p style={{
                    fontFamily: "Geometria",
                    fontSize: 20,
                    fontWeight: 700,
                    paddingLeft: 40,
                    paddingTop: 20,
                }}>
                    Current {getSecondNameForActivePlatform()} Link:
                </p>
                <p style={{
                    fontFamily: "Geometria",
                    fontSize: 18,
                    fontWeight: 500,
                    paddingLeft: 40,
                    cursor: "pointer"
                }}
                   onClick={() => {
                       window.open(fieldsForChange.instagramLink, '_blank');
                   }}>
                    {fieldsForChange.instagramLink}
                </p>
                <div>
                    <p style={{
                        fontFamily: "Geometria",
                        fontSize: 20,
                        fontWeight: 700,
                        paddingLeft: 40,
                        paddingTop: 20,
                    }}>
                        New {getSecondNameForActivePlatform()} Link:
                    </p>
                    <TextInput
                        style={{
                            fontFamily: "Geometria",
                            fontSize: 20,
                            fontWeight: 700,
                            margin: '10px 30px 30px 30px',
                            width: '70%',
                        }}
                        name='instagramLink'
                        value={newLink}
                        onChange={handleInputLinkChange}
                    />
                </div>

                <StandardButton style={{
                    width: '70%',
                    marginBottom: 30,
                    marginLeft: 35,
                }} text={'Save New Link'} onClick={() => saveNewLinkToFieldsForChange()}/>
            </div>
        );
    };

    const EditLogoModal = () => {
        const [newLogo, setNewLogo] = useState(null);
        const [previewImage, setPreviewImage] = useState(null);
        const [isFetching, setIsFetching] = useState(false);

        const handleAvatarChange = (file) => {
            if (file && file.type && file.type.startsWith("image/")) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    setPreviewImage(e.target.result);
                };
                reader.readAsDataURL(file);
                setNewLogo(file)
            } else {
                setNewLogo(null);
                setPreviewImage(null);
            }
        };

        const saveHandler = async () => {
            setIsFetching(true);
            if (newLogo && newLogo !== fieldsForChange.logo) {
                try {
                    const formData = new FormData();
                    formData.append('file', newLogo);

                    const response = await axios.post(
                        `${process.env.REACT_APP_SERVER}/promos/uploadScreenshot`,
                        formData,
                        {headers: {"Content-Type": "multipart/form-data"}}
                    );

                    if (response.data.code === 200) {
                        const logoUrl = response.data.data;
                        await updateInfluencerOnServer(logoUrl);
                    }

                    setNewLogo(null);
                    setIsChangeLogoModalOpen(false);
                    setIsFetching(false);
                } catch (error) {
                    console.log("Error uploading logo");
                    NotificationManager.error('Error uploading logo', 'Error', 3000);
                    setIsFetching(false);
                }
            }
            setIsFetching(false);
        }

        return (
            <div style={{width: '80%', margin: '60px auto 40px', textAlign: 'center'}} ref={containerRef}>
                <InputFile
                    title={`New logo for ${fieldsForChange.instagramUsername}`}
                    placeholder="Attach the logo for brand here"
                    value={newLogo}
                    style={{margin: '-25px auto 40px', maxWidth: '665px'}}
                    setValue={handleAvatarChange}
                    className="instagram-select-item-file"
                    setUploadProgress={() => {
                    }}
                />

                {newLogo && previewImage && (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '15px'
                    }}>
                        <img
                            src={previewImage}
                            alt="Uploaded Logo"
                            style={{
                                borderRadius: '50%',
                                width: '150px',
                                height: '150px',
                                objectFit: 'cover',
                                border: '2px solid #ddd'
                            }}
                        />
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {isFetching ? <Loading/> : (
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px'}}>
                                    <StandardButton
                                        isRed
                                        text="Cancel"
                                        onClick={() => handleAvatarChange(null)}
                                        style={{height: 35, fontSize: 16, padding: '5px 20px'}}
                                    />
                                    <StandardButton
                                        text="Save"
                                        onClick={saveHandler}
                                        style={{height: 35, fontSize: 16, padding: '5px 20px'}}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const hideInfluencerOnServer = async (influencer) => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/influencer/hide-instagram/${influencer.influencerId}/${influencer.account.instagramUsername}`
            );
            if (result.status === 200) {
                await updateInfluencerData({
                    influencerId: influencer.influencerId,
                    instagramUsername: influencer.account.instagramUsername
                });
            }
        } catch (err) {
            console.error("Ошибка при скрытии инфлюенсера:", err);
        }
    }

    return (
        <section className="admin">
            <div>
                <div className="admin-title-section">
                    <button onClick={() => navigate('/admin/home')}>
                        <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                    </button>
                    <TitleSection title='Influencers'/>
                </div>

                {data.length > 0 ? (
                    <div>
                        {fieldsForChange.influencerId && (
                            <SubmitButton ref={saveChangesRef} onSubmit={() => updateInfluencerOnServer()} />
                        )}

                        <div className="admin-influencers-platforms">
                            <span style={{
                                fontFamily: "Geometria",
                                fontSize: '25px',
                                fontWeight: 800,
                                textAlign: 'center',
                                color: "#3330E4",
                            }}>
                                PLATFORMS
                            </span>
                            <div>
                                <ul className="admin-influencers-platforms-container">
                                    {platforms.map((platform, index) => (
                                        <li key={index}
                                            className={`admin-influencers-platforms-container-item ${activePlatform === platform.name ? 'active' : ''}`}>
                                            <button onClick={() => {
                                                handlePlatformClick(platform.name)
                                            }}>
                                                <img src={platform.icon} alt={'icon'} style={{width: 35}}/>
                                                {platform.name}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className="admin-clients-searchbar">
                            <span style={{
                                fontFamily: "Geometria",
                                fontSize: '25px',
                                fontWeight: 800,
                                textAlign: 'center',
                                color: "#3330E4",
                            }}>
                                FULL LIST
                            </span>
                            <SearchBarComponent
                                data={data}
                                setSearchResult={setSearchResult}
                                searchFunction={searchByUsername}
                                className="large"
                                typeOfSearch="influencers"
                            />
                        </div>

                        <div className="hidden-columns-list">
                            {hiddenColumns.map((column, index) => (
                                <div key={index} className="hidden-column-item">
                                    <button onClick={() => restoreColumn(column)} style={{cursor: 'pointer'}}>
                                        <img src={hiddenIcon} alt="restore"/>
                                    </button>
                                    <span>{column}</span>
                                </div>
                            ))}
                            {hiddenGenres && (
                                <div className="hidden-column-item">
                                    <button onClick={toggleHiddenGenres} style={{cursor: 'pointer'}}>
                                        <img src={hiddenIcon} alt="restore"/>
                                    </button>
                                    <span>Genres</span>
                                </div>
                            )}
                            {hiddenCategories && (
                                <div className="hidden-column-item">
                                    <button onClick={toggleHiddenCategories} style={{cursor: 'pointer'}}>
                                        <img src={hiddenIcon} alt="restore"/>
                                    </button>
                                    <span>Categories</span>
                                </div>
                            )}
                            {hiddenCountries && (
                                <div className="hidden-column-item">
                                    <button onClick={toggleHiddenCountries} style={{cursor: 'pointer'}}>
                                        <img src={hiddenIcon} alt="restore"/>
                                    </button>
                                    <span>Countries</span>
                                </div>
                            )}
                        </div>

                        <div className="admin-influencers-table-container" ref={containerRef}>
                            <table className="admin-influencers-table"
                                   style={{width: `${tableWidth}%`}}>
                                <thead className="admin-influencers-table-header">
                                <tr>
                                    <th>
                                        <div className="admin-influencers-table-header-th">
                                            <div className="admin-influencers-table-header-button" id="first"></div>
                                            <div className="admin-influencers-table-header-text"></div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="admin-influencers-table-header-th">
                                            <div className="admin-influencers-table-header-button"
                                                 style={{borderRadius: 0}}></div>
                                            <div className="admin-influencers-table-header-text"></div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="admin-influencers-table-header-th">
                                            <div className="admin-influencers-table-header-button" id="second"></div>
                                            <div className="admin-influencers-table-header-text">
                                                <p>Networks</p>
                                            </div>
                                        </div>
                                    </th>
                                    {!isColumnHidden('First Name') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('First Name')}>
                                                        <img
                                                            src={hiddenColumns.includes('First Name') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>First Name</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Email') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Email')}>
                                                        <img
                                                            src={hiddenColumns.includes('Email') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Email</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Phone') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Phone')}>
                                                        <img
                                                            src={hiddenColumns.includes('Phone') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Phone</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Followers Number') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Followers Number')}>
                                                        <img
                                                            src={hiddenColumns.includes('Followers Number') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Followers</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Instagram Link') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Instagram Link')}>
                                                        <img
                                                            src={hiddenColumns.includes('Instagram Link') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>{getSecondNameForActivePlatform()} Link</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Public Price') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Public Price')}>
                                                        <img
                                                            src={hiddenColumns.includes('Public Price') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Public Price</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Internal Price') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Internal Price')}>
                                                        <img
                                                            src={hiddenColumns.includes('Internal Price') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Internal Price</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Price per Follower') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Price per Follower')}>
                                                        <img
                                                            src={hiddenColumns.includes('Price per Follower') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Price per Follower</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Id') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Id')}>
                                                        <img
                                                            src={hiddenColumns.includes('Id') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                }} className="admin-influencers-table-header-text">
                                                    <p>ID</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Balance') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Balance')}>
                                                        <img
                                                            src={hiddenColumns.includes('Balance') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Balance</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Campaigns Completed') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Campaigns Completed')}>
                                                        <img
                                                            src={hiddenColumns.includes('Campaigns Completed') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Campaigns Completed</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Campaigns Denied') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Campaigns Denied')}>
                                                        <img
                                                            src={hiddenColumns.includes('Campaigns Denied') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Campaigns Denied</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Latest Invoice') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Latest Invoice')}>
                                                        <img
                                                            src={hiddenColumns.includes('Latest Invoice') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Latest Invoice</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!isColumnHidden('Internal Note') && (
                                        <th>
                                            <div className="admin-influencers-table-header-th">
                                                <div className="admin-influencers-table-header-button">
                                                    <button onClick={() => handleHiddenColumns('Internal Note')}>
                                                        <img
                                                            src={hiddenColumns.includes('Internal Note') ? hiddenIcon : watch}
                                                            alt="hidden"
                                                        />
                                                    </button>
                                                </div>
                                                <div className="admin-influencers-table-header-text">
                                                    <p>Internal Note</p>
                                                </div>
                                            </div>
                                        </th>
                                    )}
                                    {!hiddenGenres && genres.map((genre, index) => (
                                        <th key={index}>
                                            <div className="admin-influencers-table-header-th">
                                                {index === 0 ? (
                                                    <div className="admin-influencers-table-header-button">
                                                        <button onClick={toggleHiddenGenres}>
                                                            <img
                                                                src={hiddenGenres ? hiddenIcon : watch}
                                                                alt="toggle"
                                                            />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="admin-influencers-table-header-button"></div>
                                                )}
                                                <div className="admin-influencers-table-header-text">
                                                    <p style={{display: hiddenGenres && index > 0 ? 'none' : 'block'}}>{genre}</p>
                                                </div>
                                            </div>
                                        </th>
                                    ))}

                                    {!hiddenCategories && categories.map((category, index) => (
                                        <th key={index}>
                                            <div className="admin-influencers-table-header-th">
                                                {index === 0 ? (
                                                    <div className="admin-influencers-table-header-button">
                                                        <button onClick={toggleHiddenCategories}>
                                                            <img
                                                                src={hiddenCategories ? hiddenIcon : watch}
                                                                alt="toggle"
                                                            />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="admin-influencers-table-header-button"></div>
                                                )}
                                                <div className="admin-influencers-table-header-text">
                                                    <p style={{display: hiddenCategories && index > 0 ? 'none' : 'block'}}>{category}</p>
                                                </div>
                                            </div>
                                        </th>
                                    ))}

                                    {!hiddenCountries && countries.map((country, index) => (
                                        <th key={index}>
                                            <div className="admin-influencers-table-header-th">
                                                {index === 0 ? (
                                                    <div className="admin-influencers-table-header-button">
                                                        <button onClick={toggleHiddenCountries}>
                                                            <img
                                                                src={hiddenCountries ? hiddenIcon : watch}
                                                                alt="toggle"
                                                            />
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <div className="admin-influencers-table-header-button"></div>
                                                )}
                                                <div className="admin-influencers-table-header-text">
                                                    <p style={{display: hiddenCountries && index > 0 ? 'none' : 'block'}}>{country}</p>
                                                </div>
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                                </thead>
                                <tbody className="admin-influencers-table-body">
                                {searchResult ? (
                                    <tr key={searchResult.index} onClick={() => selectInfluencer(searchResult)}>
                                        <td className="admin-influencers-table-body-td"
                                            onClick={() => hideInfluencerOnServer(searchResult)}
                                            style={{width: '20px', paddingLeft: 0}}>
                                            <img src={searchResult.account?.isHidden ? hiddenIcon : watch} alt='hide'
                                                 style={{cursor: 'pointer'}}/>
                                        </td>
                                        <td className="admin-influencers-table-body-td"
                                            style={{width: '70px', paddingLeft: 0}}>
                                            <img src={searchResult.account.logo} alt={altAvatar}
                                                 onClick={() => setIsChangeLogoModalOpen(true)}
                                                 style={{width: 40, margin: '0 auto'}}/>
                                        </td>
                                        <td className="admin-influencers-table-body-td"
                                            onClick={() => navigate(`/admin/influencer-edit-page/${searchResult.influencerId}`)}
                                            style={{
                                                width: 200,
                                                display: hiddenColumns.includes('Networks') ? 'none' : 'table-cell',
                                                cursor: 'pointer'
                                            }}>
                                            <p style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 700,
                                                textAlign: 'left',
                                                width: '100%',
                                            }}>
                                                {searchResult.account.instagramUsername}
                                            </p>
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            textAlign: "left",
                                            width: 100,
                                            display: hiddenColumns.includes('First Name') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "left",
                                                    width: '100%',
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.firstName : (searchResult.firstName ? searchResult.firstName : 'N/A')}
                                                name="firstName"
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            textAlign: "left",
                                            paddingRight: 6,
                                            width: 220,
                                            display: hiddenColumns.includes('Email') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "left",
                                                    width: '100%',
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.email : (searchResult.email ? searchResult.email : 'N/A')}
                                                name="email"
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            textAlign: "left",
                                            paddingRight: 6,
                                            width: 120,
                                            display: hiddenColumns.includes('Phone') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "left",
                                                    width: '100%',
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.phone : (searchResult.phone ? searchResult.phone : 'N/A')}
                                                name="phone"
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 60,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Followers Number') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.followersNumber : searchResult.account.followersNumber}
                                                name="followersNumber"
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td"
                                            style={{
                                                paddingLeft: 0,
                                                width: 100,
                                                display: hiddenColumns.includes('Instagram Link') ? 'none' : 'table-cell'
                                            }}>
                                            <div style={{display: 'flex'}}>
                                                <button
                                                    onClick={() => {
                                                        window.open(searchResult.account.instagramLink, '_blank');
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        width: 52,
                                                        height: 28,
                                                        borderRadius: "10px",
                                                        paddingLeft: 3,
                                                        paddingRight: 3,
                                                        border: "1.5px solid black",
                                                        boxSizing: 'border-box',
                                                        margin: '0 auto',
                                                        cursor: 'pointer'
                                                    }}>
                                                    <img src={instaRefLogo} alt="watch"/>
                                                    <img src={linkIcon} alt="edit"/>
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setIsEditModalOpen(true);
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        height: 28,
                                                        borderRadius: "10px",
                                                        paddingLeft: 3,
                                                        paddingRight: 3,
                                                        border: "1.5px solid black",
                                                        boxSizing: 'border-box',
                                                        margin: '0 auto',
                                                        cursor: 'pointer'
                                                    }}>
                                                    <img src={edit} alt="watch"/>
                                                </button>
                                            </div>
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 80,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Public Price') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.publicPrice : searchResult.account.publicPrice}
                                                name='publicPrice'
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 80,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Internal Price') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.price : searchResult.account.price}
                                                name="price"
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 80,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Price per Follower') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={calculatePricePerFollower(searchResult.account.price, searchResult.account.followersNumber)}
                                                readOnly={true}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 190,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Id') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={searchResult.influencerId}
                                                readOnly={true}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 80,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Balance') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.balance : searchResult.balance}
                                                name="balance"
                                                onChange={(e) => updateInfluencerFieldsInput(e)}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 90,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Campaigns Completed') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={searchResult.campaignsCompleted}
                                                readOnly={true}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 90,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Campaigns Denied') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={searchResult.campaignsDenied}
                                                readOnly={true}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 90,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Latest Invoice') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    textAlign: "center",
                                                    width: '100%',
                                                    margin: '0 auto'
                                                }}
                                                value={formatDateStringReport(searchResult.latestInvoice.createdAt)}
                                                readOnly={true}
                                            />
                                        </td>
                                        <td className="admin-influencers-table-body-td" style={{
                                            fontFamily: "Geometria",
                                            fontSize: 12,
                                            fontWeight: 400,
                                            width: 150,
                                            paddingLeft: 0,
                                            display: hiddenColumns.includes('Internal Note') ? 'none' : 'table-cell'
                                        }}>
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 400,
                                                    width: '100%',
                                                    margin: '0 auto',
                                                    paddingLeft: 5
                                                }}
                                                value={fieldsForChange.instagramId === searchResult.account._id ? fieldsForChange.internalNote : (searchResult.internalNote ? searchResult.internalNote : '')}
                                            />
                                        </td>
                                        {genres.map((genre, index) => (
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: checkGenre(genre, searchResult.account) ? 700 : 400,
                                                width: 100,
                                                paddingLeft: 0,
                                                display: hiddenGenres ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: checkGenre(genre, searchResult.account) ? 700 : 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={checkGenre(genre, searchResult.account) ? 'Yes' : ''}
                                                />
                                            </td>
                                        ))}
                                        {categories.map((category, index) => (
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: checkCategory(category, searchResult.account) ? 700 : 400,
                                                width: 100,
                                                paddingLeft: 0,
                                                display: hiddenCategories ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: checkCategory(category, searchResult.account) ? 700 : 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={checkCategory(category, searchResult.account) ? 'Yes' : ''}
                                                />
                                            </td>
                                        ))}
                                        {countries.map((country, index) => {
                                            const result = checkCountry(country, searchResult.account);

                                            return (
                                                <td key={index} className="admin-influencers-table-body-td" style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: result.found ? 700 : 400,
                                                    width: 150,
                                                    paddingLeft: 0,
                                                    display: hiddenCountries ? 'none' : 'table-cell'
                                                }}>
                                                    <input
                                                        style={{
                                                            fontFamily: "Geometria",
                                                            fontSize: 12,
                                                            fontWeight: result.found ? 700 : 400,
                                                            textAlign: "center",
                                                            width: '100%',
                                                            margin: '0 auto'
                                                        }}
                                                        value={result.found ? `Yes (${result.percentage}%)` : ''}
                                                    />
                                                </td>
                                            );
                                        })}
                                    </tr>
                                ) : (
                                    pagedData.map((influencer, index) =>
                                        <tr key={index} onClick={() => selectInfluencer(influencer)}>
                                            <td className="admin-influencers-table-body-td"
                                                onClick={() => hideInfluencerOnServer(influencer)}
                                                style={{width: '20px', paddingLeft: 0}}>
                                                <img src={influencer.account.isHidden ? hiddenIcon : watch} alt='hide'
                                                     style={{cursor: 'pointer'}}/>
                                            </td>
                                            <td className="admin-influencers-table-body-td"
                                                style={{width: '70px', paddingLeft: 0}}>
                                                <img src={influencer.account.logo} alt={altAvatar}
                                                     onClick={() => setIsChangeLogoModalOpen(true)}
                                                     style={{width: 40, cursor: 'pointer', margin: '0 auto'}}/>
                                            </td>
                                            <td className="admin-influencers-table-body-td"
                                                onClick={() => navigate(`/admin/influencer-edit-page/${influencer.influencerId}`)}
                                                style={{
                                                    width: 200,
                                                    display: hiddenColumns.includes('Networks') ? 'none' : 'table-cell',
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                <p style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                    textAlign: 'left',
                                                    width: '100%',
                                                }}>
                                                    {fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.instagramUsername : (influencer.account.instagramUsername ? influencer.account.instagramUsername : 'N/A')}
                                                </p>
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                textAlign: "left",
                                                width: 100,
                                                display: hiddenColumns.includes('First Name') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.firstName : (influencer.firstName ? influencer.firstName : 'N/A')}
                                                    name="firstName"
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                textAlign: "left",
                                                paddingRight: 6,
                                                width: 220,
                                                display: hiddenColumns.includes('Email') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.email : (influencer.email ? influencer.email : 'N/A')}
                                                    name="email"
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                textAlign: "left",
                                                paddingRight: 6,
                                                width: 120,
                                                display: hiddenColumns.includes('Phone') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.phone : (influencer.phone ? influencer.phone : 'N/A')}
                                                    name="phone"
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 60,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Followers Number') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.followersNumber : influencer.account.followersNumber}
                                                    name="followersNumber"
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td"
                                                style={{
                                                    paddingLeft: 0,
                                                    width: 100,
                                                    display: hiddenColumns.includes('Instagram Link') ? 'none' : 'table-cell'
                                                }}>
                                                <div style={{display: 'flex'}}>
                                                    <button
                                                        onClick={() => {
                                                            window.open(influencer.account.instagramLink, '_blank');
                                                        }}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            width: 52,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            paddingLeft: 3,
                                                            paddingRight: 3,
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            margin: '0 auto',
                                                            cursor: 'pointer'
                                                        }}>
                                                        <img src={instaRefLogo} alt="watch"/>
                                                        <img src={linkIcon} alt="edit"/>
                                                    </button>
                                                    <button
                                                        onClick={() => {
                                                            setIsEditModalOpen(true);
                                                        }}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            paddingLeft: 3,
                                                            paddingRight: 3,
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            margin: '0 auto',
                                                            cursor: 'pointer'
                                                        }}>
                                                        <img src={edit} alt="watch"/>
                                                    </button>
                                                </div>
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 80,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Public Price') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.publicPrice : influencer.account.publicPrice}
                                                    name='publicPrice'
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 80,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Internal Price') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.price : influencer.account.price}
                                                    name="price"
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 80,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Price per Follower') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={calculatePricePerFollower(influencer.account.price, influencer.account.followersNumber)}
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 190,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Id') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={influencer.influencerId}
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 80,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Balance') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.balance : influencer.balance}
                                                    name="balance"
                                                    onChange={(e) => updateInfluencerFieldsInput(e)}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 90,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Campaigns Completed') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={influencer.campaignsCompleted}
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 90,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Campaigns Denied') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={influencer.campaignsDenied}
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 90,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Latest Invoice') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        width: '100%',
                                                        margin: '0 auto'
                                                    }}
                                                    value={formatDateStringReport(influencer.latestInvoice.createdAt)}
                                                    readOnly={true}
                                                />
                                            </td>
                                            <td className="admin-influencers-table-body-td" style={{
                                                fontFamily: "Geometria",
                                                fontSize: 12,
                                                fontWeight: 400,
                                                width: 150,
                                                paddingLeft: 0,
                                                display: hiddenColumns.includes('Internal Note') ? 'none' : 'table-cell'
                                            }}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        width: '100%',
                                                        margin: '0 auto',
                                                        paddingLeft: 5
                                                    }}
                                                    value={fieldsForChange.instagramId === influencer.account._id ? fieldsForChange.internalNote : (influencer.internalNote ? influencer.internalNote : '')}
                                                />
                                            </td>
                                            {genres.map((genre, index) => (
                                                <td className="admin-influencers-table-body-td" style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: checkGenre(genre, influencer.account) ? 700 : 400,
                                                    width: 100,
                                                    paddingLeft: 0,
                                                    display: hiddenGenres ? 'none' : 'table-cell'
                                                }}>
                                                    <input
                                                        style={{
                                                            fontFamily: "Geometria",
                                                            fontSize: 12,
                                                            fontWeight: checkGenre(genre, influencer.account) ? 700 : 400,
                                                            textAlign: "center",
                                                            width: '100%',
                                                            margin: '0 auto'
                                                        }}
                                                        value={checkGenre(genre, influencer.account) ? 'Yes' : ''}
                                                    />
                                                </td>
                                            ))}
                                            {categories && categories.map((category, index) => (
                                                <td className="admin-influencers-table-body-td" style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: 12,
                                                    fontWeight: checkCategory(category, influencer.account) ? 700 : 400,
                                                    width: 100,
                                                    paddingLeft: 0,
                                                    display: hiddenCategories ? 'none' : 'table-cell'
                                                }}>
                                                    <input
                                                        style={{
                                                            fontFamily: "Geometria",
                                                            fontSize: 12,
                                                            fontWeight: checkCategory(category, influencer.account) ? 700 : 400,
                                                            textAlign: "center",
                                                            width: '100%',
                                                            margin: '0 auto'
                                                        }}
                                                        value={checkCategory(category, influencer.account) ? 'Yes' : ''}
                                                    />
                                                </td>
                                            ))}
                                            {countries.map((country, index) => {
                                                const result = checkCountry(country, influencer.account);

                                                return (
                                                    <td key={index} className="admin-influencers-table-body-td" style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: 12,
                                                        fontWeight: result.found ? 700 : 400,
                                                        width: 150,
                                                        paddingLeft: 0,
                                                        display: hiddenCountries ? 'none' : 'table-cell'
                                                    }}>
                                                        <input
                                                            style={{
                                                                fontFamily: "Geometria",
                                                                fontSize: 12,
                                                                fontWeight: result.found ? 700 : 400,
                                                                textAlign: "center",
                                                                width: '100%',
                                                                margin: '0 auto'
                                                            }}
                                                            value={result.found ? `Yes (${result.percentage}%)` : ''}
                                                        />
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </table>

                            {isEditModalOpen && (
                                <ModalWindow isOpen={isEditModalOpen} title="Edit Influencer" setClose={closeEditModal}>
                                    <EditLinkModal/>
                                </ModalWindow>
                            )}

                            {isChangeLogoModalOpen && (
                                <ModalWindow isOpen={isChangeLogoModalOpen} title="Edit Logo"
                                             setClose={setIsChangeLogoModalOpen}>
                                    <EditLogoModal/>
                                </ModalWindow>
                            )}
                        </div>

                        {searchResult && (
                            <div className="genres-countries-categories-container" ref={containerRef}
                                 onClick={() => selectInfluencer(searchResult)}>
                                <div className="block"
                                     style={{width: activePlatform === "Spotify" && '100%'}}>
                                    <p id='title'>MUSIC GENRES</p>
                                    <p>Select <b>ALL</b> the applicable</p>
                                    {genres.map((genre, index) => (
                                        <div key={index} className="checkbox">
                                            <input type="checkbox"
                                                   id={genre}
                                                   name={genre}
                                                   checked={selectedGenres.includes(genre)}
                                                   onChange={() => handleGenreSelect(genre)}
                                            />
                                            <label htmlFor={genre}>{genre}</label>
                                        </div>
                                    ))}
                                </div>
                                {activePlatform !== "Spotify" && (
                                    <div className="block">
                                        <p id='title'>TOP LOCATIONS</p>
                                        <p>Enter the top 5 Countries, and their percentage</p>
                                        <div className='countries-container'>
                                            <div className='country'>
                                                <span>#1</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           placeholder='19.4%'
                                                           value={selectedCountries[0]?.percentage}
                                                           setValue={(value) => handleCountryChange(0, "percentage", value)}/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={0}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#2</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           placeholder='9.4%'
                                                           value={selectedCountries[1]?.percentage}
                                                           setValue={(value) => handleCountryChange(1, "percentage", value)}/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={1}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#3</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           value={selectedCountries[2]?.percentage}
                                                           setValue={(value) => handleCountryChange(2, "percentage", value)}
                                                           placeholder='4.4%'/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={2}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#4</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           value={selectedCountries[3]?.percentage}
                                                           setValue={(value) => handleCountryChange(3, "percentage", value)}
                                                           placeholder='3.4%'/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={3}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                            <div className='country'>
                                                <span>#5</span>
                                                <TextInput style={{
                                                    padding: '13px 10px',
                                                    width: window.innerWidth < 768 ? '15%' : '30%'
                                                }}
                                                           silverColor={true}
                                                           value={selectedCountries[4]?.percentage}
                                                           setValue={(value) => handleCountryChange(4, "percentage", value)}
                                                           placeholder='1.4%'/>
                                                <div style={{marginTop: 10}}>
                                                    <SearchCountry indexOfSelectingCountry={4}
                                                                   handleCountryChange={handleCountryChange}
                                                                   selectedCountries={selectedCountries}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {activePlatform === 'Instagram' && (
                                    <div className="block">
                                        <p id='title'>KEY TOPICS</p>
                                        <p>Select <b>THIS</b> if the main core theme of the page</p>
                                        {categories.map((category, index) => (
                                            <div key={index} className="checkbox">
                                                <input type="checkbox"
                                                       id={category}
                                                       name={category}
                                                       checked={selectedCategories.includes(category.toLowerCase())}
                                                       onChange={() => handleCategorySelect(category.toLowerCase())}
                                                />
                                                <label htmlFor={category}>{category}</label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="pagination-container">
                            <button
                                className="arrow"
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))}
                            >
                                ❮
                            </button>
                            <div className="page-numbers">
                                {generatePageNumbers().map((page) => (
                                    <button
                                        key={page}
                                        className={`page-button ${page === currentPage ? "active" : ""}`}
                                        onClick={() => setCurrentPage(page)}
                                    >
                                        {page + 1}
                                    </button>
                                ))}
                            </div>
                            <button
                                className="arrow"
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(data.length / PAGE_SIZE) - 1))}
                            >
                                ❯
                            </button>
                        </div>
                    </div>
                ) : (
                    <div style={{margin: '0 auto'}}>
                        <PageLoading/>
                    </div>
                )}
            </div>
        </section>
    );
}

export default AdminInfluencers;