import React, {useEffect, useState} from "react";
import TitleSection from "../../../../TitleSection";
import FormContainer from "../../../../form/FormContainer";
import TextInput from "../../../../form/TextInput";
import StandartButton from "../../../../form/StandardButton";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import ModalWindow from "../../../../ModalWindow";
import InputFile from "../../../../form/InputFile";
import {PuffLoader} from "react-spinners";
import {getSocialMedia} from "../../../../../utils/typeOfSocialAccounts";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {decodeAndDecrypt, encryptAndEncode} from "../../../../../utils/crypt";
import {useQuery, useMutation, useQueryClient} from "react-query";
import Loading from "../../../../form/PageLoading/pageLoading";
import {Error} from "../../../../form/Error/Error";
import {useDispatch, useSelector} from "react-redux";
import {
    setFullInfluencerUpdateOngoingCampaign, setInfluencerUpdateOngoingCampaignClear,
    setOngoingCampaignDatePost,
    setOngoingCampaignImpressions, setOngoingCampaignLike,
    setOngoingCampaignPostLink,
    setOngoingCampaignScreenshot
} from "../../../../../redux/slice/influencer-update-ongoing-campaign";

const fetchCampaignData = async ({queryKey}) => {
    const [_, influencerId, promoId, networkUsername, selectInfluencersId] = queryKey;

    if (!influencerId || !promoId || !networkUsername) {
        throw new Error("Missing params");
    }

    const encodedInfluencerId = encryptAndEncode(
        decodeAndDecrypt(influencerId, process.env.REACT_APP_URL_PAGES_SECRET_KEY),
        process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY
    );
    const encodedCampaignId = encryptAndEncode(
        decodeAndDecrypt(promoId, process.env.REACT_APP_URL_PAGES_SECRET_KEY),
        process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY
    );

    const result = await axios.get(
        `${process.env.REACT_APP_SERVER}/promos/influencer-get-ongoing-campaign/one`,
        {
            params: {
                influencerId: encodedInfluencerId,
                campaignId: encodedCampaignId,
                socialNetworkUsername: networkUsername,
                selectInfluencersId: selectInfluencersId
            },
        }
    );

    if (result.data.code !== 200) {
        throw new Error("Failed to fetch data");
    }

    return result.data.promo;
};

const updateCampaignData = async ({formData, screenshotUrl, params}) => {
    const encodedInfluencerId = encryptAndEncode(
        decodeAndDecrypt(params.influencerId, process.env.REACT_APP_URL_PAGES_SECRET_KEY),
        process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY
    );
    const encodedCampaignId = encryptAndEncode(
        decodeAndDecrypt(params.promoId, process.env.REACT_APP_URL_PAGES_SECRET_KEY),
        process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY
    );

    try {
        const response = await axios.put(
            `${process.env.REACT_APP_SERVER}/promos/influencer-update-ongoing`,
            {
                ...formData,
                screenshot: screenshotUrl || formData.screenshot,
            },
            {
                params: {
                    influencerId: encodedInfluencerId,
                    campaignId: encodedCampaignId,
                    socialNetworkUsername: params.networkUsername,
                    selectInfluencersId: params.selectInfluencersId,
                },
            }
        );

        if (response.data.code !== 200) {
            console.error(`Error: ${response.status}`);
            return [];
        }

        return response.data;  
    } catch (error) {
        console.error(`Error updating campaign: ${error?.response?.data?.message || error.message || "Unknown error"}`);
    }
};

const uploadScreenshot = async (file) => {
    const formDataScreenshot = new FormData();
    formDataScreenshot.append("file", file);

    const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/promos/uploadScreenshot`,
        formDataScreenshot,
        {
            headers: {"Content-Type": "multipart/form-data"},
        }
    );
    
    return response.data.data;
};

const UpdateOngoingPromo = () => {
    const params = useParams();
    const navigation = useNavigate();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    
    const formData = useSelector((state) => state.influencerUpdateOngoingCampaign);
    const [accountSocialMedia, setAccountSocialMedia] = useState("");

    const [uploadProgress, setUploadProgress] = useState(false);
    const [screenshot, setScreenshot] = useState('');
    const [isWindowTwo, setIsWindowTwo] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isServerRequestProcessing, setIsServerRequestProcessing] = useState(false);
    
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setInfluencerUpdateOngoingCampaignClear());
        }
    }, [dispatch]);
    
    const {data, isLoading, isError} = useQuery(
        ["influencerUpdateOngoingPageCampaignData", params.influencerId, params.promoId, params.networkUsername, params.selectInfluencersId],
        fetchCampaignData,
        {
            enabled: !!params.influencerId && !!params.promoId && !!params.networkUsername && !!params.selectInfluencersId,
            onSuccess: (data) => {
                dispatch(setFullInfluencerUpdateOngoingCampaign(data));
                setAccountSocialMedia(data.socialMedia);
            },
            onError: (error) => {
                console.error("Error fetching data", error);
                NotificationManager.error("Failed to fetch data", "Error", 3000);
            },
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchOnMount: false,
            refetchOnFocus: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchIntervalActive: false,
        }
    );

    const mutation = useMutation(updateCampaignData, {
        onSuccess: () => {
            queryClient.invalidateQueries(["influencerUpdateOngoingPageCampaignData", params.influencerId, params.promoId, params.networkUsername]);
            setIsWindowTwo(true);
        },
        onError: (error) => {
            console.error("Error updating promo", error.message());
            NotificationManager.error("Failed to update promo", "Error", 3000);
        }
    });

    const uploadMutation = useMutation(uploadScreenshot, {
        onSuccess: (url) => {
            dispatch(setOngoingCampaignScreenshot(url));
            setUploadProgress(false);
        },
        onError: () => NotificationManager.error("Error uploading file", "Error", 3000),
    });
    
    const returnForm = () => {
        if (accountSocialMedia === 'spotify' || accountSocialMedia === 'soundcloud') {
            return (
                <FormContainer style={{
                    marginTop: "60px",
                    padding: windowWidth < 850 && '20px',
                    width: windowWidth < 1000 && '80%',
                    margin: windowWidth < 1000 ? '60px auto' : '60px auto 0 auto'
                }}>
                    <InputFile
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title="Screenshot insights"
                        placeholder="Attach the screenshot of the insights"
                        setValue={setScreenshot}
                        setUploadProgress={setUploadProgress}
                        onChange={(event) => {
                            setUploadProgress(true);
                            uploadMutation.mutate(event.target.files[0]);
                        }}
                    />

                    {uploadProgress === true && (
                        <div style={{
                            width: '70%',
                            margin: '20px auto',
                            fontFamily: 'Geometria',
                            fontSize: 18,
                            fontWeight: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <p style={{
                                textAlign: 'center',
                            }}>Please wait. Screenshot is loading</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: 8,
                                paddingLeft: 9
                            }}>
                                <PuffLoader color={"#3330e4"} loading={true} size={10}/>
                            </div>
                        </div>
                    )}

                    {formData.screenshot && (
                        <div>
                            <img src={formData.screenshot} alt="screenshot" style={{width: "70%", margin: '30px auto 0', maxHeight: 600, borderRadius: 10}}/>
                        </div>
                    )}

                    {formData.screenshot && !uploadProgress && (
                        <div style={{marginTop: "60px", display: "flex", justifyContent: "center"}}>
                            <StandartButton text="Submit" onClick={() => mutation.mutate({
                                formData,
                                screenshotUrl: formData.screenshot,
                                params
                            })}/>
                        </div>
                    )}
                </FormContainer>
            )
        } else if (accountSocialMedia === 'press') {
            return (
                <FormContainer style={{
                    marginTop: "60px",
                    padding: windowWidth < 850 && '20px',
                    width: windowWidth < 1000 && '80%',
                    margin: windowWidth < 1000 ? '60px auto' : '60px auto 0 auto'
                }}>
                    <TextInput
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title="Article Link"
                        placeholder="Enter Article link"
                        value={formData.postLink}
                        setValue={(value) => dispatch(setOngoingCampaignPostLink(value))}
                    />
                    {formData.postLink && (
                        <div style={{marginTop: "60px", display: "flex", justifyContent: "center"}}>
                            <StandartButton text="Submit" onClick={() => mutation.mutate({
                                formData,
                                screenshotUrl: formData.screenshot,
                                params
                            })}/>
                        </div>
                    )}
                </FormContainer>
            );
        } else {
            return (
                <FormContainer style={{
                    marginTop: "60px",
                    padding: windowWidth < 850 && '20px',
                    width: windowWidth < 1000 && '80%',
                    margin: windowWidth < 1000 ? '60px auto' : '60px auto 0 auto'
                }}>
                    <InputFile
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title="Screenshot insights"
                        placeholder="Attach the screenshot of the insights"
                        setValue={setScreenshot}
                        setUploadProgress={setUploadProgress}
                        onChange={(event) => {
                            setUploadProgress(true);
                            uploadMutation.mutate(event.target.files[0]);
                        }}
                    />

                    {uploadProgress === true && (
                        <div style={{
                            width: '70%',
                            margin: '20px auto',
                            fontFamily: 'Geometria',
                            fontSize: 18,
                            fontWeight: 400,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <p style={{
                                textAlign: 'center',
                            }}>Please wait. Screenshot is loading</p>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingBottom: 8,
                                paddingLeft: 9
                            }}>
                                <PuffLoader color={"#3330e4"} loading={true} size={10}/>
                            </div>
                        </div>
                    )}

                    {formData.screenshot && (
                        <div>
                            <img src={formData.screenshot} alt="screenshot" style={{width: "70%", margin: '30px auto 0', maxHeight: 600, borderRadius: 10}}/>
                        </div>
                    )}

                    <TextInput
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title={`${getSocialMedia(accountSocialMedia)} Link`}
                        placeholder={`Enter ${getSocialMedia(accountSocialMedia)} link`}
                        value={formData.postLink}
                        setValue={(value) => dispatch(setOngoingCampaignPostLink(value))}
                    />

                    <TextInput
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title="Date Post"
                        placeholder="Enter date post dd/mm/yyyy"
                        value={formData.datePost}
                        setValue={(value) => {
                            const numericValue = value.replace(/[^\d]/g, "");

                            let formattedValue = "";
                            if (numericValue.length <= 2) {
                                formattedValue = numericValue;
                            } else if (numericValue.length <= 4) {
                                formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2)}`;
                            } else {
                                formattedValue = `${numericValue.slice(0, 2)}/${numericValue.slice(2, 4)}/${numericValue.slice(4, 8)}`;
                            }

                            dispatch(setOngoingCampaignDatePost(formattedValue));
                        }}
                    />

                    <TextInput
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title="Impressions"
                        placeholder="Enter impressions"
                        value={formData.impressions}
                        setValue={(value) => dispatch(setOngoingCampaignImpressions(value))}
                    />

                    <TextInput
                        style={{maxWidth: "665px", margin: "60px auto 0 auto"}}
                        title="Likes"
                        placeholder="Enter the Likes number here"
                        value={formData.like}
                        setValue={(value) => dispatch(setOngoingCampaignLike(value))}
                    />

                    {formData.like && formData.screenshot && formData.postLink && formData.datePost && formData.impressions && !uploadProgress && (
                        <div style={{marginTop: "60px", display: "flex", justifyContent: "center"}}>
                            {!isServerRequestProcessing ? (
                                <StandartButton text="Submit" onClick={() => {
                                    setIsServerRequestProcessing(true);
                                    mutation.mutate({
                                        formData,
                                        screenshotUrl: formData.screenshot,
                                        params
                                    })
                                }}/>
                            ) : <Loading/>}
                        </div>
                    )}
                </FormContainer>
            );
        }
    };

    if (isLoading || isError) {
        return <div>
            {isLoading && <Loading/>}
            {isError && <div style={{width: '100%'}}>
                <Error errorMessage={"Something went wrong on our end. We'll sort it out soon!"}/>
            </div>}
            <NotificationContainer/>
        </div>
    }

    return (
        <section className="invoice-result">
            <div className="container-form">
                <div className="account-client-title-section" style={{marginTop: windowWidth <= 650 && '-30px'}}>
                    <TitleSection title="campaign result"/>
                </div>
                <div className="invoice-result-block">
                    {returnForm()}
                </div>
            </div>

            <ModalWindow header="CONGRATULATIONS!" isOpen={isWindowTwo} setClose={setIsWindowTwo}>
                <div className="account-influencer-details-form">
                    <div
                        style={{marginTop: "60px", display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <h2
                            style={{
                                color: "#000",
                                textAlign: "center",
                                fontFamily: "Geometria",
                                fontSize: 22,
                                fontWeight: 900,
                                textTransform: "uppercase",
                                marginBottom: 20,
                            }}
                        >
                            CONGRATULATIONS
                        </h2>
                        <p
                            style={{
                                color: "#000",
                                textAlign: "center",
                                fontFamily: "Geometria",
                                fontSize: 18,
                                fontWeight: 400,
                                marginBottom: 20,
                            }}
                        >
                            The campaign has concluded, and the remaining balance is now accessible in your account.
                        </p>
                        <StandartButton
                            text="Submit"
                            onClick={() => {
                                setIsWindowTwo(false);
                                navigation("/");
                            }}
                        />
                    </div>
                </div>
            </ModalWindow>
            <NotificationContainer/>
        </section>
    );
};

export default UpdateOngoingPromo;
