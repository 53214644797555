import html2canvas from 'html2canvas';
import {jsPDF} from 'jspdf';
import {NotificationManager} from "react-notifications";

export const captureAndDownloadPDF = async (campaignName) => {
    try {
        const elementsToCapture = [
            document.getElementById('campaign-report-title'),
            document.getElementById('campaign-report-campaign-name'),
            document.getElementById('campaign-report-details'),
            document.getElementById('campaign-report-table'),
        ];

        if (elementsToCapture.some(element => !element)) {
            throw new Error('Один или несколько элементов для захвата не найдены!');
        }

        const container = document.createElement('div');
        container.style.position = 'absolute';
        container.style.left = '-9999px';
        container.style.width = '110%';
        document.body.appendChild(container);

        const originalStyles = {
            table: [],
            imagesUsernameContainer: [],
            followersText: [],
            datePostColumn: [],
            datePostText: [],
            videoLinkColumn: [],
            videolinkContainers: [],
            paragraphsVideoLink: [],
            imagesVideoLink: [],
            postDescriptionContainers: [],
            postDescriptionParagraphs: [],
            postDescriptionText: [],
            storyTagContainers: [],
            storyTagParagraphs: [],
            storyTagText: [],
            postLinkColumn: [],
            postLinkContainer: [],
            impressionsText: [],
            footerText: []
        };

        const preloadImages = () => {
            const images = document.querySelectorAll('img');
            const promises = Array.from(images).map(img => {
                if (img.complete) return Promise.resolve();
                return new Promise((resolve) => {
                    img.onload = resolve;
                    img.onerror = resolve;
                });
            });
            return Promise.all(promises);
        };

        await preloadImages();

        elementsToCapture.forEach(element => {
            const clone = element.cloneNode(true);
            container.appendChild(clone);
        });

        const table = document.querySelectorAll('#campaign-report-table');
        table.forEach(table => {
            originalStyles.table.push({
                element: table,
                border: table.style.border,
            });
            table.style.border = '2px solid #c8c4f4';
        })

        const imagesUsernameContainer = document.querySelectorAll('#username-container img');
        imagesUsernameContainer.forEach(img => {
            originalStyles.imagesUsernameContainer.push({
                element: img,
                width: img.style.width,
                height: img.style.height,
                marginTop: img.style.marginTop,
            });
            img.style.width = '15px';
            img.style.height = '15px';
            img.style.marginTop = '9px';
        });

        const followersText = container.querySelectorAll('#followers p');
        followersText.forEach(p => {
            originalStyles.followersText.push({
                element: p,
                height: p.style.height,
                alignItems: p.style.alignItems,
            })
            p.style.height = '30px';
            p.style.alignItems = 'center';
        })

        const datePostColumn = container.querySelectorAll('#date');
        datePostColumn.forEach(td => {
            originalStyles.datePostColumn.push({
                element: td,
                padding: td.style.padding,
            })
            td.style.padding = '0 0 0 10px';
        })

        const datePostText = container.querySelectorAll('#date p');
        datePostText.forEach(p => {
            originalStyles.datePostText.push({
                element: p,
                height: p.style.height,
                alignItems: p.style.alignItems,
            })
            p.style.height = '30px';
            p.style.alignItems = 'center';
        })

        const videoLinkColumn = container.querySelectorAll('#videolink');
        videoLinkColumn.forEach(td => {
            originalStyles.videoLinkColumn.push({
                element: td,
                width: td.style.width,
            })
            td.style.width = '70px'
        })

        const videolinkContainers = container.querySelectorAll('.videolink-container');
        videolinkContainers.forEach(container => {
            originalStyles.videolinkContainers.push({
                element: container,
                margin: container.style.margin,
                cursor: container.style.cursor,
                display: container.style.display,
                alignItems: container.style.alignItems,
                justifyContent: container.style.justifyContent,
                gap: container.style.gap,
                minWidth: container.style.minWidth,
                maxWidth: container.style.maxWidth,
                height: container.style.height,
                borderRadius: container.style.borderRadius,
                border: container.style.border,
                padding: container.style.padding,
            });
            container.style.margin = '0 auto';
            container.style.cursor = 'pointer';
            container.style.display = 'flex';
            container.style.alignItems = 'center';
            container.style.justifyContent = 'center';
            container.style.gap = '3px';
            container.style.minWidth = '45px';
            container.style.maxWidth = '60px';
            container.style.height = '28px';
            container.style.borderRadius = '10px';
            container.style.border = '1px solid black';
            container.style.padding = '0 3px';
        });

        const paragraphsVideoLink = container.querySelectorAll('.videolink-container p');
        paragraphsVideoLink.forEach(p => {
            originalStyles.paragraphsVideoLink.push({
                element: p,
                fontFamily: p.style.fontFamily,
                fontSize: p.style.fontSize,
                fontWeight: p.style.fontWeight,
                marginLeft: p.style.marginLeft,
                alignItems: p.style.alignItems,
                height: p.style.height,
                lineHeight: p.style.lineHeight,
            });
            p.style.fontFamily = 'Geometria, sans-serif';
            p.style.fontSize = '15px';
            p.style.fontWeight = '500';
            p.style.marginLeft = '5px';
            p.style.alignItems = 'center';
            p.style.height = '28px';
            p.style.lineHeight = '17px';
        });

        const imagesVideoLink = container.querySelectorAll('.videolink-container img');
        imagesVideoLink.forEach(img => {
            originalStyles.imagesVideoLink.push({
                element: img,
                width: img.style.width,
            });
            img.style.width = '18px';
        });

        const postDescriptionContainers = container.querySelectorAll('#post-decription');
        postDescriptionContainers.forEach(postDescription => {
            originalStyles.postDescriptionContainers.push({
                element: postDescription,
                padding: postDescription.style.padding,
            })
            postDescription.style.padding = '0 0 0 8px';
        })

        const postDescriptionParagraphs = container.querySelectorAll('#post-decription div');
        postDescriptionParagraphs.forEach(div => {
            originalStyles.postDescriptionParagraphs.push({
                element: div,
                minHeight: div.style.minHeight,
                maxHeight: div.style.maxHeight,
            })
            div.style.minHeight = '50px';
            div.style.maxHeight = '50px';
        })

        const postDescriptionText = container.querySelectorAll('#post-decription div p');
        postDescriptionText.forEach(p => {
            originalStyles.postDescriptionParagraphs.push({
                element: p,
                alignItems: p.style.alignItems,
                height: p.style.height,
            });
            p.style.alignItems = 'center';
            p.style.height = '50px';
        })

        const storyTagContainers = container.querySelectorAll('#story-tag');
        storyTagContainers.forEach(tag => {
            originalStyles.storyTagContainers.push({
                element: tag,
                padding: tag.style.padding,
            })
            tag.style.padding = '0 0 0 8px'
        })

        const storyTagParagraphs = container.querySelectorAll('#story-tag div');
        storyTagParagraphs.forEach(div => {
            originalStyles.storyTagParagraphs.push({
                element: div,
                minHeight: div.style.minHeight,
                maxHeight: div.style.maxHeight,
            })
            div.style.minHeight = '50px';
            div.style.maxHeight = '50px';
        })

        const storyTagText = container.querySelectorAll('#story-tag div');
        storyTagText.forEach(p => {
            originalStyles.storyTagParagraphs.push({
                element: p,
                height: p.style.height,
                alignItems: p.style.alignItems,
            })
            p.style.height = '50px';
            p.style.alignItems = 'center';
            p.style.padding = '0 0 5px 0';
        })

        const postLinkColumn = container.querySelectorAll('#post-link');
        postLinkColumn.forEach(td => {
            originalStyles.postLinkColumn.push({
                element: td,
                width: td.style.width,
            })
            td.style.width = '80px';
        })

        const postLinkContainer = container.querySelectorAll('#post-link div');
        postLinkContainer.forEach(div => {
            originalStyles.postLinkContainer.push({
                element: div,
                width: div.style.width,
            })
            div.style.width = '55px';
        })

        const impressionsText = container.querySelectorAll('#impressions p');
        impressionsText.forEach(p => {
            originalStyles.impressionsText.push({
                element: p,
                height: p.style.height,
                alignItems: p.style.alignItems,
                padding: p.style.padding,
            })
            p.style.height = '50px';
            p.style.alignItems = 'center';
            p.style.padding = '10px 0 0 0';
        })

        const footerText = container.querySelectorAll('#footer-info-td p');
        footerText.forEach(p => {
            originalStyles.footerText.push({
                element: p,
                padding: p.style.padding,
            })
            p.style.padding = '0 0 10px 0';
        })

        await new Promise((resolve) => {
            setTimeout(async () => {
                const canvas = await html2canvas(container, {scale: 2});
                const imgData = canvas.toDataURL('image/png');

                const maxPageHeight = 4999;

                const pdf = new jsPDF({
                    unit: 'px',
                    format: [canvas.width, maxPageHeight]
                });

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const scale = pdfWidth / canvas.width;
                const pdfHeight = Math.min(canvas.height * scale, maxPageHeight);

                let position = 0;

                while (position < canvas.height) {
                    if (position > 0) {
                        pdf.addPage();
                    }

                    const pageHeight = Math.min(canvas.height - position, maxPageHeight / scale);

                    pdf.addImage(
                        imgData,
                        'PNG',
                        0,
                        -position * scale,
                        pdfWidth,
                        canvas.height * scale
                    );

                    position += pageHeight;
                }

                pdf.save(`${campaignName}-report.pdf`);

                restoreStyles(originalStyles);
                document.body.removeChild(container);

                resolve(true);
            }, 500);
        });

        return true;
    } catch (err) {
        console.error(err);
        NotificationManager.error('Something went wrong!', 'Try download later');
        return false;
    }
};

const restoreStyles = (originalStyles) => {
    originalStyles.table.forEach((table) => {
        table.element.style.border = table.border;
    })

    originalStyles.imagesUsernameContainer.forEach(img => {
        img.element.style.width = img.width;
        img.element.style.height = img.height;
        img.element.style.marginTop = img.marginTop;
    });

    originalStyles.followersText.forEach(p => {
        p.element.style.height = p.height;
        p.element.style.alignItems = p.alignItems;
    })

    originalStyles.datePostColumn.forEach(td => {
        td.element.style.padding = td.padding;
    })

    originalStyles.datePostText.forEach(p => {
        p.element.style.height = p.height;
        p.element.style.alignItems = p.alignItems;
    })

    originalStyles.videoLinkColumn.forEach(td => {
        td.element.style.width = td.width;
    })

    originalStyles.videolinkContainers.forEach(container => {
        container.element.style.margin = container.margin;
        container.element.style.cursor = container.cursor;
        container.element.style.display = container.display;
        container.element.style.alignItems = container.alignItems;
        container.element.style.justifyContent = container.justifyContent;
        container.element.style.gap = container.gap;
        container.element.style.minWidth = container.minWidth;
        container.element.style.maxWidth = container.maxWidth;
        container.element.style.height = container.height;
        container.element.style.borderRadius = container.borderRadius;
        container.element.style.border = container.border;
        container.element.style.padding = container.padding;
    });

    originalStyles.paragraphsVideoLink.forEach(p => {
        p.element.style.fontFamily = p.fontFamily;
        p.element.style.fontSize = p.fontSize;
        p.element.style.fontWeight = p.fontWeight;
        p.element.style.marginLeft = p.marginLeft;
        p.element.style.alignItems = p.alignItems;
        p.element.style.height = p.height;
        p.element.style.lineHeight = p.lineHeight;
    });

    originalStyles.imagesVideoLink.forEach(img => {
        img.element.style.width = img.width;
    });

    originalStyles.postDescriptionContainers.forEach(postDesc => {
        postDesc.element.style.padding = postDesc.padding;
    })

    originalStyles.postDescriptionParagraphs.forEach(div => {
        div.element.style.minHeight = div.minHeight;
        div.element.style.maxHeight = div.maxHeight;
    })

    originalStyles.postDescriptionText.forEach(p => {
        p.element.style.height = p.height;
        p.element.style.alignItems = p.alignItems;
    })

    originalStyles.storyTagContainers.forEach(storyTag => {
        storyTag.element.style.padding = storyTag.padding;
    })

    originalStyles.storyTagParagraphs.forEach(div => {
        div.element.style.minHeight = div.minHeight;
        div.element.style.maxHeight = div.maxHeight;
    })

    originalStyles.storyTagText.forEach(p => {
        p.element.style.height = p.height;
        p.element.style.alignItems = p.alignItems;
        p.element.style.padding = p.padding;
    })

    originalStyles.postLinkColumn.forEach(td => {
        td.element.style.width = td.with;
    })

    originalStyles.postLinkContainer.forEach(div => {
        div.element.style.width = div.with;
    })

    originalStyles.impressionsText.forEach(p => {
        p.element.style.height = p.height;
        p.element.style.alignItems = p.alignItems;
        p.element.style.padding = p.padding;
    })

    originalStyles.footerText.forEach(p => {
        p.element.style.padding = p.padding;
    })
};