import React, {useEffect} from 'react'
import {getShortSocialMedia} from "../../../utils/typeOfSocialAccounts";

export const OfferItem = ({offer, influencersList, isSelected}) => {
    return (
        <div className={`offer-item ${isSelected ? "selected" : ""}`}>
            <div className="offer-item-title">{getShortSocialMedia(offer.socialMedia) + " " + offer.id}M</div>
            <div className="offer-item-description">
                <p>{offer.story}</p>
                <p>{offer.network}</p>
                <p>{offer.followers}</p>
            </div>
            <div className="offer-item-influencers-list">
                {influencersList.length > 0 && influencersList.map((influencer, index) => (
                    <div key={index} className="influencer-item">
                        <img src={influencer.avatar} alt=""/>
                        <p>{influencer.instagramUsername}</p>
                    </div>
                ))}
            </div>
            <div className="offer-item-price">{offer.price} €</div>
        </div>
    )
}
