import { createSlice } from "@reduxjs/toolkit";

const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("clientNewFullServicedCampaign", serializedState);
    } catch (e) {
        console.error("Error saving state to localStorage:", e);
    }
};

const loadStateFromLocalStorage = (isClearing = false) => {
    const defaultState = {
        campaignGoal: '',
        videos: '',
        music: '',
        budget: '',
        additional: ''
    };

    if (isClearing) {
        return defaultState;
    }

    try {
        const serializedState = localStorage.getItem("clientNewFullServicedCampaign");
        return serializedState ? JSON.parse(serializedState) : defaultState;
    } catch (e) {
        console.error("Error loading state from localStorage:", e);
        return defaultState;
    }
};

const initialState = loadStateFromLocalStorage();

export const createNewFullServicedCampaignSlice = createSlice({
    name: "new-full-serviced-campaign",
    initialState,
    reducers: {
        clearNewFullServicedCampaignState(state, action) {
            saveStateToLocalStorage(loadStateFromLocalStorage(true));
        },
        setFieldNewFullServicedCampaignState(state, action) {
            state[action.payload.field] = action.payload.value;
            saveStateToLocalStorage(state);
        }
    },
})

export const {
    clearNewFullServicedCampaignState,
    setFieldNewFullServicedCampaignState,
} = createNewFullServicedCampaignSlice.actions;

export const createNewFullServicedCampaign = createNewFullServicedCampaignSlice.reducer;