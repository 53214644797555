import React, {useEffect, useState} from 'react'
import FormContainer from "../../../../../../form/FormContainer";
import StandardButton from "../../../../../../form/StandardButton";
import TextInput from "../../../../../../form/TextInput";
import TextArea from "../../../../../../form/TextArea";
import deleteImg from "../../../../../../../images/icons/close.svg";
import {
    returnCampaignContentItemPostName
} from "../../../../client/CreatePromo/CreateCampaignPagesComponents/CreateCampaignPostContent";

export const CampaignContentItem = ({
                                        numOfContent,
                                        typeOfSocialMedia = 'instagram',
                                        contentItemId,
                                        addContentItem,
                                        getContentFieldValue,
                                        setContentFieldValue,
                                        showAddButton = true,
                                        showRemoveButton = false,
                                        removeContentItem,
                                        fadeIn = false,
                                        errors = {videoLink: false, postDescription: false, storyTag: false}
                                    }) => {
    const [fadeClass, setFadeClass] = useState("");

    useEffect(() => {
        if (fadeIn) {
            const timeout = setTimeout(() => setFadeClass("fade-in"), 10);
            return () => clearTimeout(timeout);
        }
    }, [fadeIn]);

    const returnCampaignContentItemForm = () => {
        if (typeOfSocialMedia === 'spotify' || typeOfSocialMedia === 'soundcloud') {
            return (
                <div>
                    <div className="content-form-item">
                        <TextInput
                            title={typeOfSocialMedia === "spotify" ? "Spotify Track Link" : "Soundcloud Track Link"}
                            style={{marginTop: 0}}
                            placeholder={typeOfSocialMedia === "spotify" ? "Enter spotify track link" : "Enter soundcloud track link"}
                            value={getContentFieldValue(contentItemId, "videoLink")}
                            setValue={(value) => setContentFieldValue(contentItemId, "videoLink", value)}
                            silverColor={true}
                            error={errors.videoLink}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextArea
                            title="Track Title"
                            value={getContentFieldValue(contentItemId, "postDescription")}
                            setValue={(value) => setContentFieldValue(contentItemId, "postDescription", value)}
                            style={{marginTop: 0}}
                            placeholder="Artist - Song Title"
                            error={errors.postDescription}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextArea
                            title="Special Requests"
                            value={getContentFieldValue(contentItemId, "specialWishes")}
                            setValue={(value) => setContentFieldValue(contentItemId, "specialWishes", value)}
                            style={{marginTop: 0}}
                            placeholder="Enter special requests"
                        />
                    </div>
                </div>
            );
        } else if (typeOfSocialMedia === 'press') {
            return (
                <div>
                    <div className="content-form-item">
                        <TextInput
                            title="Link to Music, Events, News"
                            style={{marginTop: 0}}
                            placeholder="Enter link to music, events, news"
                            value={getContentFieldValue(contentItemId, "videoLink")}
                            setValue={(value) => setContentFieldValue(contentItemId, "videoLink", value)}
                            silverColor={true}
                            error={errors.videoLink}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextArea
                            title="Link to Artwork & Press Shots"
                            value={getContentFieldValue(contentItemId, "postDescription")}
                            setValue={(value) => setContentFieldValue(contentItemId, "postDescription", value)}
                            style={{marginTop: 0}}
                            placeholder="Enter link to artwork & press shots"
                            error={errors.postDescription}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextInput
                            title="Link to Press Release"
                            style={{marginTop: 0}}
                            placeholder="Enter link to press release"
                            value={getContentFieldValue(contentItemId, "storyTag")}
                            setValue={(value) => setContentFieldValue(contentItemId, "storyTag", value)}
                            silverColor={true}
                            error={errors.storyTag}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextArea
                            title="Special Requests"
                            value={getContentFieldValue(contentItemId, "specialWishes")}
                            setValue={(value) => setContentFieldValue(contentItemId, "specialWishes", value)}
                            style={{marginTop: 0}}
                            placeholder="Enter special requests"
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div className="content-form-item">
                        <TextInput
                            title="Videolink"
                            style={{marginTop: 0}}
                            placeholder="Enter videolink"
                            value={getContentFieldValue(contentItemId, "videoLink")}
                            setValue={(value) => setContentFieldValue(contentItemId, "videoLink", value)}
                            silverColor={true}
                            error={errors.videoLink}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextArea
                            title="Post Description"
                            value={getContentFieldValue(contentItemId, "postDescription")}
                            setValue={(value) => setContentFieldValue(contentItemId, "postDescription", value)}
                            style={{marginTop: 0}}
                            placeholder="Enter description"
                            error={errors.postDescription}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextInput
                            title="Story Tag"
                            style={{marginTop: 0}}
                            placeholder="Enter story tag"
                            value={getContentFieldValue(contentItemId, "storyTag")}
                            setValue={(value) => setContentFieldValue(contentItemId, "storyTag", value)}
                            silverColor={true}
                            error={errors.storyTag}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextInput
                            title="Story Link"
                            style={{marginTop: 0}}
                            placeholder="Enter story link"
                            value={getContentFieldValue(contentItemId, "swipeUpLink")}
                            setValue={(value) => setContentFieldValue(contentItemId, "swipeUpLink", value)}
                            silverColor={true}
                        />
                    </div>
                    <div className="content-form-item">
                        <TextArea
                            title="Special Requests"
                            value={getContentFieldValue(contentItemId, "specialWishes")}
                            setValue={(value) => setContentFieldValue(contentItemId, "specialWishes", value)}
                            style={{marginTop: 0}}
                            placeholder="Enter special requests"
                        />
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={`content-form-container ${numOfContent === 1 ? '' : fadeClass}`}>
            <div className="title">
                <p>{returnCampaignContentItemPostName(typeOfSocialMedia)} {numOfContent}</p>
            </div>

            <FormContainer style={{margin: '20px auto 0', width: '100%'}}>
                <div className="content-form">
                    <div className="remove-button">
                        {showRemoveButton && (
                            <div className="remove-content-button" onClick={() => removeContentItem(contentItemId)}>
                                <img src={deleteImg} alt="delete"/>
                            </div>
                        )}
                    </div>
                    {returnCampaignContentItemForm()}
                </div>
            </FormContainer>

            {showAddButton && (
                <div className="add-additional-content-button">
                    <StandardButton isBlue={true} onClick={addContentItem} text="Add Additional Video"/>
                </div>
            )}
        </div>
    );
};