export function returnMusicStyles (musicStyle, musicStyleOther, musicSubStyles) {
    const genresSet = new Set();
    
    if (musicStyle !== undefined && musicStyle) {
        if (musicSubStyles && musicSubStyles.length > 0) {
            for (let i = 0; i < musicSubStyles.length; i++) {
                if (musicSubStyles[i] === "Melodic, Minimal") {
                    genresSet.add("Techno (Melodic, Minimal)");
                }
                if (musicSubStyles[i] === "Hard, Peak") {
                    genresSet.add("Techno (Hard, Peak)");
                }
                if (musicSubStyles[i] === "Tech House") {
                    genresSet.add("House (Tech House)");
                }
                if (musicSubStyles[i] === "Melodic, Afro") {
                    genresSet.add("House (Melodic, Afro)");
                }
            }
        } else {
            genresSet.add(musicStyle);
        }

        if (musicStyleOther && musicStyleOther.length > 0) {
            for (let i = 0; i < musicStyleOther.length; i++) {
                if (musicStyleOther[i] !== "House") {
                    genresSet.add(musicStyleOther[i]);
                }
            }
        }
        
        return Array.from(genresSet);
    }
}

export function getDisplayGenre (musicSubStyles, musicStyle, musicStyleOther) {
    const technoSubgenres = ["Hard, Peak", "Melodic, Minimal"];
    const houseSubgenres = ["Tech House", "Melodic, Afro"];

    const isTechnoInStyle = musicStyle === "Techno";
    const isHouseInStyle = musicStyle === "House";

    const isTechnoInOther = musicStyleOther && musicStyleOther.includes("Techno");
    const isHouseInOther = musicStyleOther && musicStyleOther.includes("House");

    const hasTechnoSubgenres = musicSubStyles && musicSubStyles.some(subgenre => technoSubgenres.includes(subgenre));
    const hasHouseSubgenres = musicSubStyles && musicSubStyles.some(subgenre => houseSubgenres.includes(subgenre));

    if (musicSubStyles && (isTechnoInStyle || isTechnoInOther)) {
        if (hasTechnoSubgenres) {
            const allSubgenresPresent = technoSubgenres.every(subgenre => musicSubStyles.includes(subgenre));
            return `Techno${allSubgenresPresent ? " (All)" : ""}`;
        }
        return "Techno";
    }

    if (musicSubStyles && (isHouseInStyle || isHouseInOther)) {
        if (hasHouseSubgenres) {
            const allSubgenresPresent = houseSubgenres.every(subgenre => musicSubStyles.includes(subgenre));
            return `House${allSubgenresPresent ? " (All)" : ""}`;
        }
        return "House";
    }

    return musicStyle;
};
