import React, {useState} from 'react'
import {getSocialMediaIcon} from "../../../../../../../utils/typeOfSocialAccounts";
import editIcon from "../../../../../../../images/icons/edit 1.svg";
import deleteIcon from "../../../../../../../images/icons/trash-bin.svg";
import acceptIcon from "../../../../../../../images/icons/check.svg";
import declineIcon from "../../../../../../../images/icons/close11.svg";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setCurrentWindowIndex, setFullState} from "../../../../../../../redux/slice/proposal-system";
import {generateMongoObjectId} from "../../../../../../../utils/generateId";
import {NotificationManager} from "react-notifications";
import axios from "axios";
import {encryptAndEncode} from "../../../../../../../utils/crypt";
import shareIcon from "../../../../../../../images/icons/Share.svg";

export const CampaignItem = ({campaign}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

    const handleShareClick = (campaignId) => {
        try {
            let encryptedCampaignId = encryptAndEncode(campaignId, process.env.REACT_APP_PROPOSAL_SHARE_SECRET_KEY);
            let shareLink = `${process.env.REACT_APP_CLIENT}/proposal-system/shared-campaign/${encryptedCampaignId}`;
            navigator.clipboard.writeText(shareLink);
            NotificationManager.success('Link copied to clipboard', '', 3000);
        } catch (err) {
            NotificationManager.error('Failed to copy link to clipboard');
        }
    };

    const handleEditButton = () => {
        const selectedSocialMedias = [];

        campaign.selectInfluencers && campaign.selectInfluencers.forEach(acc => {
            if (selectedSocialMedias && !selectedSocialMedias.includes(acc.socialMedia)) {
                selectedSocialMedias.push(acc.socialMedia);
            }
        })

        const state = {
            campaignId: campaign._id,
            selectedSocialMedias: selectedSocialMedias,
            selectedClientId: campaign.client.clientId || "",
            campaignName: campaign.campaignName,
            addedAccounts: campaign.selectInfluencers.map(acc => (
                {
                    influencerId: acc.accountId,
                    instagramUsername: acc.socialMediaUsername,
                    selectedCampaignContentItem: acc.selectedCampaignContentItem ?? '',
                    dateRequest: acc.dateRequest ?? 'ASAP',
                }
            )),
            campaignContent: campaign.campaignContent,
            price: campaign.publicPrice ?? 0,
            createdDate: campaign.createdAt ?? null,
        }
        dispatch(setFullState(state));
        dispatch(setCurrentWindowIndex(3));
        navigate('/admin/proposal-system/manage-campaign');
    };

    const handleDeleteButton = async () => {
        try {
            const encodedCampaignId = encryptAndEncode(campaign._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);
            const result = await axios.delete(`${process.env.REACT_APP_SERVER}/proposal-system/delete-proposal-campaign/${encodedCampaignId}/${campaign.campaignName}`);

            if (result.data.status === 200) {
                NotificationManager.success(`${campaign.campaignName} has been deleted`, "Success", 3000);
                window.location.reload();
                return;
            }

            throw new Error(result.data.message);
        } catch (error) {
            console.error("Error: ", error);
            NotificationManager.error(`Error while deleting ${campaign.campaignName}`, "Error", 3000);
        }
    };

    return (
        <div className="campaign">
            <div className="campaign-icon-name-block">
                <img src={getSocialMediaIcon(campaign.socialMedia)} alt="N/A"/>
                <p>{campaign.campaignName}</p>
            </div>

            <div className="campaign-info-item">
                <span>Client Name:</span>
                <p>{campaign.client.firstName || "N/A"}</p>
            </div>

            <div className="campaign-info-item">
                <span>Followers:</span>
                <p>{campaign.totalFollowers || "N/A"}</p>
            </div>

            <div className="campaign-info-item">
                <span>Price:</span>
                <p>{campaign.price + "€" || "N/A"}</p>
            </div>

            <div className="campaign-info-item">
                <span>Public Price:</span>
                <p>{campaign.publicPrice + "€" || "N/A"}</p>
            </div>

            <div className="manage-buttons-block" style={{gap: isDeleteConfirmOpen ? 10 : 5}}>
                {!isDeleteConfirmOpen && (
                    <button onClick={() => handleShareClick(campaign._id)}>
                        <img src={shareIcon} alt="share"/>
                    </button>
                )}
                {!isDeleteConfirmOpen && (
                    <button style={{marginLeft: 5}} onClick={() => handleEditButton()}>
                        <img src={editIcon} alt="edit"/>
                    </button>
                )}
                <div className={`delete-confirmation ${isDeleteConfirmOpen ? "active" : ""}`} style={{
                    padding: isDeleteConfirmOpen ? "3px 8px" : "0",
                }}>
                    <p>Are you sure?</p>
                    <div id="buttons">
                        <button id="accept" onClick={() => handleDeleteButton()}>
                            <img src={acceptIcon} alt="accept-deleting"/>
                        </button>
                        <button onClick={() => setIsDeleteConfirmOpen(!isDeleteConfirmOpen)}>
                            <img src={declineIcon} alt="decline-deleting"/>
                        </button>
                    </div>
                </div>
                <button onClick={() => setIsDeleteConfirmOpen(!isDeleteConfirmOpen)}>
                    <img src={deleteIcon} alt="delete"/>
                </button>
            </div>
        </div>
    )
}
