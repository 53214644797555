import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {CreateCampaign} from "../../../components/layout/account/client/CreatePromo/AccountClientCreateCampaign";

const AccountClientCreateCampaignPage = () => {
    return (
        <>
            <Header path="Sponsoring client / My Account / New Campaigns"/>
            <CreateCampaign/>
            <Background/>
        </>
    );
};

export default AccountClientCreateCampaignPage;
