import React from 'react';
import {
    getSocialMedia,
    getSocialMediaIconForPickAndChoose,
    socialMediaKeys
} from "../../../utils/typeOfSocialAccounts";
import "./selectSocialMediaHorizontalList.css";

export const SelectSocialMediaHorizontalList = ({ selectSocialMedia, selectedSocialMedia }) => {
    return (
        <div className="social-media-horizontal-list">
            {socialMediaKeys.map((socialMedia, index) => {
                const isSelected = Array.isArray(selectedSocialMedia)
                    ? selectedSocialMedia.includes(socialMedia)
                    : selectedSocialMedia === socialMedia;

                return (
                    <div
                        key={index}
                        className={`social-media-horizontal-list-item ${isSelected ? "selected" : ""}`}
                        onClick={() => selectSocialMedia(socialMedia)}
                    >
                        <img src={getSocialMediaIconForPickAndChoose(socialMedia)} alt={socialMedia} />
                        <p>{getSocialMedia(socialMedia)}</p>
                    </div>
                );
            })}
        </div>
    );
};
