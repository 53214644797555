import React, { useState } from 'react';
import "./selectDate.css";

export const SelectDate = ({ selectOption, selectedOption, listOfOptions }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleOptionClick = (option) => {
        selectOption(option);
        setIsOpen(false);
    };

    return (
        <div className="custom-select-container">
            <div
                className={`custom-select-header ${isOpen ? 'open' : ''}`}
                onClick={toggleDropdown}
            >
                {selectedOption || "Select option"}
            </div>
            <div className={`custom-select-dropdown ${isOpen ? 'open' : ''}`}>
                {listOfOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`custom-select-item ${selectedOption === option ? 'selected' : ''}`}
                        onClick={() => handleOptionClick(option)}
                    >
                        {option}
                    </div>
                ))}
            </div>
        </div>
    );
};