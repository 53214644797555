import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import CreateVipCampaign from "../../../components/layout/account/client/VIPCampaign/CreateVIPCampaign";

const AccountClientCreateVIPCampaign = () => {
    return (
        <>
            <Header path="Sponsoring client / My Account / VIP Managed Campaign" />
            <CreateVipCampaign/>
            <Background />
        </>
    );
};

export default AccountClientCreateVIPCampaign;
