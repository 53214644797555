import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {
    AccountClientCreateMultiCampaign
} from "../../../components/layout/account/client/CreatePromo/AccountClientCreateMultiCampaign";

const AccountClientCreateMultiCampaignPage = () => {
    return (
        <>
            <Header path="Sponsoring client / My Account / New MultiPromo" />
            <AccountClientCreateMultiCampaign/>
            <Background />
        </>
    );
};

export default AccountClientCreateMultiCampaignPage;
