import React, {useState} from 'react'
import ModalWindow from "../../../../../../ModalWindow";
import videoImg from "../../../../../../../images/icons/iconsForCampaignReport/video 1.svg";
import linkImg from "../../../../../../../images/icons/iconsForCampaignReport/link 1.svg";
import StandardButton from "../../../../../../form/StandardButton";

export const SelectCampaignContentItemModal = ({isOpen, setClose, campaignItems, selectItem}) => {
    const [chosenItem, setChosenItem] = useState(campaignItems[0]);

    const handleOnClick = (item) => {
        if (chosenItem?._id === item._id) {
            setChosenItem(null);
        } else {
            setChosenItem(item);
        }
    };

    return (
        <ModalWindow isOpen={isOpen} setClose={setClose}>
            <div className="select-campaign-content-item-modal">
                <div className="title">
                    <p>SELECT CONTENT FOR INFLUENCER</p>
                </div>

                <div className="select-container">
                    {campaignItems.map((item, index) => (
                        <div className={`campaign-content-item ${chosenItem?._id === item._id ? "chosen" : ""}`}
                             onClick={() => handleOnClick(item)}>
                            <img src={videoImg} alt="video"/>
                            <span>#{index + 1}</span>
                            <img src={linkImg} alt="link"/>
                        </div>
                    ))}
                </div>

                <div
                    className={`chosen-content-item-container ${
                        chosenItem ? "visible" : ""
                    }`}
                >
                    {chosenItem && (
                        <div className="chosen-content-item">
                            <div className="chosen-content-item-details">
                                <p>VideoLink:</p>
                                <a href={chosenItem.videoLink} target="_blank" rel="noreferrer">{chosenItem.videoLink}</a>
                                <p>Post Description:</p>
                                <span>{chosenItem.postDescription}</span>
                                <p>Special Requests:</p>
                                <span>{chosenItem.specialWishes}</span>
                            </div>
                        </div>
                    )}

                    {chosenItem && (
                        <div className="select-button">
                            <StandardButton isBlue={true} onClick={() => {
                                selectItem(chosenItem._id);
                                setClose();
                            }} text="Select"/>
                        </div>
                    )}
                </div>
            </div>
        </ModalWindow>
    )
}
