import React, {useEffect, useState} from 'react';
import GenreButton from './GenreButton';
import './genreButtonList.css';

const genres = [
    {genre: 'Techno', subText: 'Melodic'},
    {genre: 'Techno', subText: 'Hard, Peak'},
    {genre: 'House', subText: 'Tech House'},
    {genre: 'House', subText: 'Melodic, Afro'},
    {genre: 'EDM'},
    {genre: 'D&B'},
    {genre: 'Bass'},
    {genre: 'Dubstep'},
];

const OffersGenreButtonsList = ({setSelectedOffersGenres}) => {
    const [activeGenre, setActiveGenre] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const initialGenre = 'Techno (Melodic)';
        setActiveGenre(initialGenre);
        setSelectedOffersGenres([]);
    }, [setSelectedOffersGenres]);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleGenreClick = (genre, subText) => {
        const selectedGenre = subText ? `${genre} (${subText})` : genre;

        if (activeGenre === selectedGenre) {
            setActiveGenre('Techno (Melodic)');
            setSelectedOffersGenres([]);
        } else {
            setActiveGenre(selectedGenre);
            if (selectedGenre === 'Techno (Melodic)') {
                setSelectedOffersGenres([]);
            } else {
                setSelectedOffersGenres([selectedGenre]);
            }
        }
    };

    return (
        <div className="genre-buttons-list-container">
            {windowWidth <= 1300 && (
                <p id="message">
                    *You can scroll the genres to see more
                </p>
            )}
            <div className="genre-buttons-list">
                {genres.map(({genre, subText}) => {
                    const selectedGenre = subText ? `${genre} (${subText})` : genre;
                    return (
                        <GenreButton
                            key={selectedGenre}
                            genre={genre}
                            subText={subText}
                            isActive={activeGenre === selectedGenre}
                            onClick={() => handleGenreClick(genre, subText)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default OffersGenreButtonsList;
