import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import PromoShare from "../../../components/layout/account/client/SharePromo/PromoShare";

const PromoSharePage = () => {
    return (
        <>
            <Header path="Sharing Promo"/>
            <PromoShare />
            <Background />
        </>
    );
};

export default PromoSharePage;
