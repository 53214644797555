import React, {useEffect} from "react";
import logo from "../images/icons/logo.svg";
import profile from "../images/icons/profile.svg";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setAuthenticated} from "../redux/slice/authenticated";

const Header = ({userType = "client", page = "login", path = ""}) => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.authenticated.isAuthenticated);
    const role = useSelector((state) => state.authenticated.role);

    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    const logoutRequest = () => {
        localStorage.setItem("token", "");
        window.sessionStorage.setItem("isAdmin", null);
        dispatch(setAuthenticated(false));
        navigation("/");
    };
    
    return (
        <>
            <header className="header">
                <div className="container">
                    <div className="header-block">
                        <div className="header-thoomb">
                            <button className="header-logo" onClick={() => navigation("/")}>
                                <img src={logo}/>
                            </button>
                            <p className="header-path">{path}</p>
                        </div>

                        <div className="header-navigation">
                            {isAuth && (
                                <button
                                    className="header-logout"
                                    type="button"
                                    onClick={logoutRequest}
                                >
                                    Logout
                                </button>
                            )}
                            {isAuth && windowWidth > 500 && (
                                <button
                                    className="header-profile"
                                    onClick={() => {
                                        if (role === "influencer") {
                                            navigation("/account/influencer/details");
                                        }
                                        if (role === "client") {
                                            navigation("/account/client/details");
                                        }
                                    }}
                                >
                                    <img className="header-profile-icon" src={profile}/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
