import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {formatDateStringReport} from "../validations";

export const CsvCampaignConverter = async (campaignData) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Campaign Report");

    let reportHeader = [
        ["SOUNDINFLUENCER'S CAMPAIGN REPORT:"],
        ["CONTACT: ", "Eric Simi \n 📧 eric@soundinfluencers.com \n 📞 +447390030363"],
        ["CAMPAIGN NAME:", campaignData.campaignName],
        ["DATE:", formatDateStringReport(campaignData.createdAt)],
        ["PRICE:", campaignData.isPriceHidden ? '' : `${campaignData.amount}€`],
        ["POST & STORIES:", campaignData.accountsCount],
        ["COMBINED FOLLOWERS:", campaignData.totalFollowers],
        ["IMPRESSIONS:", campaignData.totalImpressions],
        ["LIKES:", campaignData.totalLikes],
        ["CPM:", campaignData.isCpmAndResultHidden ? '' : campaignData.cpm],
        ["Average Instagram CPM:", campaignData.isCpmAndResultHidden ? '' : "5€ to 12€"],
        ["RESULT:", campaignData.isCpmAndResultHidden ? '' : campaignData.cpmResult],
        ["", ""],
    ];

    const tableHeader = [
        "Pages",
        "Followers",
        "Video Link",
        "Description",
        "Story Tags",
        "Story Link",
        "Post Link",
        "Screenshot Insights",
        "Impressions",
        "Likes",
    ];

    reportHeader.forEach((row) => {
        const addedRow = worksheet.addRow(row);

        addedRow.eachCell((cell) => {
            if (row[0] && row[0].trim() !== "") {
                if (addedRow === worksheet.getRow(1)) {
                    cell.font = { bold: true, size: 12 };
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "F0ECEC" },
                    };
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                } else {
                    cell.font = { bold: false, size: 12 };
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "F0ECEC" },
                    };
                    cell.border = {
                        top: { style: "thin" },
                        left: { style: "thin" },
                        bottom: { style: "thin" },
                        right: { style: "thin" },
                    };
                }
            }
        });
    });

    reportHeader = reportHeader.filter(row => row[0].trim() !== "");

    const headerRow = worksheet.addRow(tableHeader);
    headerRow.eachCell((cell) => {
        cell.font = { bold: true, size: 12 };
        cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "D9E1F2" },
        };
        cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        };
    });

    worksheet.columns = [
        { key: "pages", width: 40 },
        { key: "followers", width: 15 },
        { key: "videoLink", width: 30 },
        { key: "description", width: 40 },
        { key: "storyTags", width: 20 },
        { key: "storyLink", width: 30 },
        { key: "postLink", width: 30 },
        { key: "screenshot", width: 25 },
        { key: "impressions", width: 15 },
        { key: "likes", width: 15 },
    ];

    campaignData.selectInfluencers.forEach((influencer) => {
        const row = worksheet.addRow({
            pages: influencer.socialMediaUsername,
            followers: influencer.followersNumber,
            videoLink: influencer.selectedCampaignContentItemObj.videoLink,
            description: influencer.selectedCampaignContentItemObj.postDescription,
            storyTags: influencer.selectedCampaignContentItemObj.storyTag,
            storyLink: influencer.selectedCampaignContentItemObj.swipeUpLink,
            postLink: influencer.postLink,
            screenshot: influencer.screenshot,
            impressions: influencer.impressions,
            likes: influencer.like,
        });

        row.eachCell((cell) => {
            cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
            };
            cell.alignment = { vertical: "middle", horizontal: "left" };
            cell.font = { size: 12 };
        });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, `${campaignData.campaignName}_campaign_report.xlsx`);
};
