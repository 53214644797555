import React from "react";
import { formatDateStringReport} from "../../../../../utils/validations";

const statusMessagesCampaigns = {
    0: "REQUESTED (CAN BE ACCEPTED OR DENIED)",
    1: "ACCEPTED (WAITING FOR FINISH)",
    2: "DENIED (CANNOT BE COMPLETED)",
    3: "COMPLETED (BALANCE UPDATED)",
};
const statusMessagesInvoices = {
    0: "INVOICE REQUESTED FOR: ",
};

const AdminInfluencerHistory = ({influencerHistory}) => {
    const getStatusMessage = (actionNumber, type, invoiceAmount = null) => {
        let message = "";
        let firstWord = "";
        let restMessage = "";

        if (type === "campaign") {
            message = statusMessagesCampaigns[actionNumber];
        } else if (type === "invoice") {
            message = statusMessagesInvoices[0] + invoiceAmount;
        }

        const messageParts = message.split(" ");
        firstWord = messageParts[0];
        restMessage = messageParts.slice(1).join(" ");

        return (
            <span>
            <span style={{fontWeight: 600}}>{firstWord}</span> {restMessage}
        </span>
        );
    };

    return (
        <table className="admin-table" style={{marginBottom: 40}}>
            <thead className="admin-table-header">
            <tr>
                <td>DATE</td>
                <td>ACCOUNT USERNAME</td>
                <td>CAMPAIGN NAME</td>
                <td>REWARD</td>
                <td>PAYMENT METHOD</td>
                <td>INVOICE ID</td>
                <td>STATUS</td>
                <td>CURRENT BALANCE</td>
            </tr>
            </thead>
            <tbody className="admin-table-body">
            {influencerHistory?.history.length > 0 && influencerHistory.history.map((historyAction, index) => (
                <tr>
                    <td className="admin-table-body-td" style={{
                        padding: "6px 0",
                        textAlign: "center",
                        fontWeight: 600
                    }}>{formatDateStringReport(historyAction.date)}</td>
                    <td className="admin-table-body-td"
                        style={{
                            color: historyAction.campaignId ? "black" : "red",
                            fontWeight: historyAction.campaignId ? 400 : 600
                        }}>{historyAction.networkUsername || "INVOICE"}</td>
                    <td className="admin-table-body-td"
                        style={{
                            fontWeight: historyAction.campaignName ? 400 : 600,
                            color: historyAction.campaignName ? "black" : "red"
                        }}>{historyAction.campaignName || "INVOICE"}</td>
                    <td className="admin-table-body-td"
                        style={{
                            color: historyAction.rewardForInfluencer ? "black" : "red",
                            fontWeight: historyAction.rewardForInfluencer ? 400 : 600
                        }}>{historyAction.rewardForInfluencer ? historyAction.rewardForInfluencer + "€" : "INVOICE"}</td>
                    <td className="admin-table-body-td"
                        style={{
                            fontWeight: historyAction.paymentMethod ? 400 : 600,
                            color: historyAction.paymentMethod ? "black" : "red"
                        }}>{historyAction.paymentMethod || "CAMPAIGN"}</td>
                    <td className="admin-table-body-td"
                        style={{
                            fontWeight: historyAction.paymentMethod ? 400 : 600,
                            color: historyAction.paymentMethod ? "black" : "red"
                        }}>{historyAction.invoiceId || "CAMPAIGN"}</td>
                    <td className="admin-table-body-td">{getStatusMessage(historyAction.actionNumber, historyAction.typeOfAction, historyAction.amount ? historyAction.amount : null)}</td>
                    <td className="admin-table-body-td" style={{fontWeight: 600}}>{historyAction.currentBalance}€</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
}

export default AdminInfluencerHistory;