import React, {useState, useEffect} from 'react';
import "./offersList.css";
import {OfferItem} from "./OfferItem";
import nextImage from "../../../images/icons/adminPanel/offers/Chevron right.svg";
import previousImage from "../../../images/icons/adminPanel/offers/Chevron left.svg";
import GenreButtonList from "../OffersGenreButtonList/GenreButtonList";

export const OffersList = ({offers, selectedOffer, selectOffer}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(4);
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [filteredOffers, setFilteredOffers] = useState(offers || []);

    const updateItemsPerPage = () => {
        const width = window.innerWidth;
        if (width > 1900) {
            setItemsPerPage(4);
        } else if (width > 1350) {
            setItemsPerPage(3);
        } else if (width > 768) {
            setItemsPerPage(2);
        } else {
            setItemsPerPage(1);
        }
    };

    useEffect(() => {
        updateItemsPerPage();
        window.addEventListener("resize", updateItemsPerPage);
        return () => window.removeEventListener("resize", updateItemsPerPage);
    }, []);

    useEffect(() => {
        setCurrentPage(0)

        if (selectedGenres.length === 0) {
            setFilteredOffers(
                offers
                    .filter(offer => offer.connectInfluencer && offer.connectInfluencer.length > 0)
                    .map(offer => ({
                        id: offer.id,
                        connectInfluencer: offer.connectInfluencer,
                        story: offer.story,
                        network: offer.network,
                        followers: offer.followers,
                        price: offer.price,
                    }))
            );
        } else {
            setFilteredOffers(
                offers
                    .filter(offer => offer.musicStyles.length > 0 && offer.musicStyles.some(style => selectedGenres.includes(style.genres[0])))
                    .map(offer => {
                        const offerWithNeededGenre = offer.musicStyles.find(style => selectedGenres.includes(style.genres[0]));
                        return {
                            id: offer.id,
                            connectInfluencer: offerWithNeededGenre.connectInfluencer,
                            story: offer.story,
                            network: offer.network,
                            followers: offer.followers,
                            price: offerWithNeededGenre.price,
                        };
                    })
            );
        }

    }, [selectedGenres, offers]);

    const totalPages = Math.ceil(filteredOffers.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const currentItems = filteredOffers.slice(startIndex, startIndex + itemsPerPage);

    const handleNextPage = () => {
        if ((currentPage + 1) * itemsPerPage < filteredOffers.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const isOfferSelected = (id, genre) => {
        if (!selectedOffer) return false;
        const isSameId = selectedOffer.id === id;
        const isSameGenre = selectedOffer.genre === genre || (!selectedOffer.genre && !genre);
        return isSameId && isSameGenre;
    };

    return (
        <div className="offers-list-container">
            <div className="genres-list">
                <GenreButtonList setSelectedOffersGenres={setSelectedGenres}/>
            </div>
            <div className="offers-list">
                <div className="control-button">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 0}
                    >
                        <img src={previousImage} alt="Previous"/>
                    </button>
                </div>
                {currentItems.map((offer, index) => (
                    <div onClick={() => selectOffer(offer.id, selectedGenres.length > 0 ? selectedGenres[0] : "")}>
                        <OfferItem
                            offer={offer}
                            influencersList={offer.connectInfluencer || []}
                            isSelected={isOfferSelected(offer.id, selectedGenres.length > 0 ? selectedGenres[0] : "")}
                        />
                    </div>
                ))}
                <div className="control-button">
                    <button
                        onClick={handleNextPage}
                        disabled={(currentPage + 1) * itemsPerPage >= filteredOffers.length}
                    >
                        <img src={nextImage} alt="Next"/>
                    </button>
                </div>
            </div>

            <div className="pagination">
                {Array.from({length: totalPages}).map((_, index) => (
                    <span
                        key={index}
                        className={`dot ${index === currentPage ? "active" : ""}`}
                        onClick={() => setCurrentPage(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};
