import React, {useState} from 'react'
import videoImg from "../../../../../images/icons/iconsForCampaignReport/video 1.svg";
import instaIcon from "../../../../../images/icons/iconsForCampaignReport/instagram 1.svg";
import linkIcon from "../../../../../images/icons/iconsForCampaignReport/link 1.svg";
import screenshotIcon from "../../../../../images/icons/iconsForCampaignReport/image- 1.svg";
import {getSocialMediaIconForPickAndChoose} from "../../../../../utils/typeOfSocialAccounts";

export const PromoShareDesktopTable = ({
                                           data,
                                           totalFollowers,
                                           totalPrice,
                                           totalImpressions,
                                           isPriceHidden,
                                           totalLikes
                                       }) => {
    const [headers] = useState([
        {
            Header: "Networks", accessor: "Networks",
        }, {
            Header: "Followers", accessor: "Followers",
        }, {
            Header: "Date Post", accessor: "Date Post",
        }, {
            Header: "Video", accessor: "Video",
        }, {
            Header: "Post Description", accessor: "Post Description",
        }, {
            Header: "Story Tag", accessor: "Story Tag",
        }, {
            Header: "Story Link", accessor: "Story Link",
        }, {
            Header: "Post Link", accessor: "Post Link",
        }, {
            Header: "Screenshot", accessor: "Screenshot",
        }, {
            Header: "Impressions", accessor: "Impressions",
        }, {
            Header: "Likes", accessor: "Likes",
        }
    ]);

    return (
        <table className="report-table">
            <thead className="report-table-header">
            <tr>
                {headers.map((header, index) => (
                    <th key={index}>{header.Header}</th>
                ))}
            </tr>
            </thead>
            <tbody className="report-table-body">
            {data?.selectInfluencers.map((account, index) => (
                <tr key={index}>
                    <td id="username">
                        <div id='username-container'>
                            <img src={getSocialMediaIconForPickAndChoose(account.socialMedia)} alt=''/>
                            <p>{account.socialMediaUsername}</p>
                        </div>
                    </td>
                    <td id="followers">{account.followersNumber}</td>
                    <td id="date">{account.dateRequest}</td>
                    <td id="videolink">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItem !== '' && (
                            <div className='videolink-container' onClick={() => window.open(account.selectedCampaignContentItemObj.videoLink, "_blank")}>
                                <img src={videoImg} alt=""/>
                                <p>#{data.campaignContent.findIndex((content) => account.selectedCampaignContentItemObj._id === content._id) + 1}</p>
                            </div>
                        )}
                    </td>
                    <td id="post-decription">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItemObj && (
                            <div>
                                {account.selectedCampaignContentItemObj.postDescription}
                            </div>
                        )}
                    </td>
                    <td id="story-tag">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItemObj && (
                            <div>
                                {account.selectedCampaignContentItemObj.storyTag}
                            </div>
                        )}
                    </td>
                    <td id="story-link">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItemObj && (
                            <div
                                onClick={() => window.open(account.selectedCampaignContentItemObj.swipeUpLink, "_blank")}>
                                {account.selectedCampaignContentItemObj.swipeUpLink}
                            </div>
                        )}
                    </td>
                    <td id="post-link">
                        {account.postLink && (
                            <div className='post-link-container' onClick={() => window.open(account.postLink, "_blank")}>
                                <img src={instaIcon} alt=""/>
                                <img src={linkIcon} alt=""/>
                            </div>
                        )}
                    </td>
                    <td id="post-link">
                        {account.screenshot && (
                            <div className='post-link-container' onClick={() => window.open(account.screenshot, "_blank")}>
                                <img src={screenshotIcon} alt=""/>
                                <img src={linkIcon} alt=""/>
                            </div>
                        )}
                    </td>
                    <td id="impressions">
                        {account.impressions}
                    </td>
                    <td id="impressions">
                        {account.like}
                    </td>
                </tr>
            ))}
            </tbody>
            <tfoot className="report-table-footer">
            <tr>
                {!isPriceHidden ? <td>PRICE: {totalPrice}€</td> : <td></td>}
                <td>{totalFollowers}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{totalImpressions}</td>
                <td>{totalLikes}</td>
            </tr>
            </tfoot>
        </table>
    )
};