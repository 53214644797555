import React, {useEffect, useState} from "react";
import backBtn from "../../../../../images/icons/arrow.svg";
import TitleSection from "../../../../TitleSection";
import {useNavigate} from "react-router-dom";
import {getSocialMediaIcon, typeOfAccounts} from "../../../../../utils/typeOfSocialAccounts";
import Loading from "../../../../form/PageLoading/pageLoading";
import axios from "axios";
import {NotificationContainer, NotificationManager} from "react-notifications";
import SearchBarComponent from "../../../../form/SearchBar/SearchBar";
import AdminInfluencerHistory from "./AdminInfluencerHistory";
import {useQuery} from "react-query";
import {Error} from "../../../../form/Error/Error";

const fetchInfluencersHistories = async () => {
    try {
        const result = await axios.get(`${process.env.REACT_APP_SERVER}/influencer-actions-history/get/all`);

        if (result.data.code === 200) {
            return result.data.influencerActionsHistory;
        }

        return [];
    } catch (e) {
        console.log(e);
        throw e;
    }
}

const AdminInfluencersHistoryPage = () => {
    const navigate = useNavigate();

    const [selectedSocialMedia, setSelectedSocialMedia] = useState("instagram");
    const [influencerHistorySearchResult, setInfluencerHistorySearchResult] = useState(null);
    const [influencerHistory, setInfluencerHistory] = useState(null);

    const {data: influencers, isFetching, isLoading, isError} = useQuery("influencersHistories",
        fetchInfluencersHistories,
        {
            placeholderData: [],
            cacheTime: 1000 * 60 * 30,
            staleTime: 1000 * 60 * 15,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: true,
            refetchInterval: 1000 * 60 * 15,
            onError: (error) => {
                NotificationManager.error("An error occurred while trying to fetch the influencers histories. Please try again later.");
            },
        });

    useEffect(() => {
        if (influencerHistorySearchResult) {
            setInfluencerHistory(influencers.find(influencer => influencer.influencerId === influencerHistorySearchResult.influencerId));
        }
    }, [influencerHistorySearchResult]);

    useEffect(() => {
        setInfluencerHistorySearchResult(null);
        setInfluencerHistory(null);
    }, [selectedSocialMedia]);

    const searchFunction = (data, searchInput) => {
        return data.filter(account => account.networkUsername.toLowerCase().includes(searchInput.toLowerCase()));
    };

    return (
        <section className="admin">
            <div className="admin-influencers-history">
                <div className="admin-title-section">
                    <button onClick={() => navigate('/admin/home')}>
                        <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                    </button>
                    <TitleSection title="Influencers" span="History"/>
                </div>

                {isError &&
                    <div style={{marginTop: 40}}>
                        <Error
                            errorMessage="An error occurred while trying to fetch the influencers histories. Please try again later."/>
                    </div>}
                {(isLoading || isFetching) && 
                    <div style={{marginTop: 40}}>
                        <Loading/>
                    </div>}
                
                {!isError && !isLoading && !isFetching && influencers && (
                    <div>
                        <div className="admin-influencers-history-search-container">
                            <span>PLATFORMS</span>

                            <div className="social-medias-container">
                                <div className="admin-influencers-platforms-container">
                                    {typeOfAccounts.map((socialMediaType, index) => (
                                        <li key={index}
                                            className={`admin-influencers-platforms-container-item ${selectedSocialMedia === socialMediaType.db ? 'active' : ''}`}>
                                            <button onClick={() => {
                                                setSelectedSocialMedia(socialMediaType.db)
                                            }}>
                                                <img src={getSocialMediaIcon(socialMediaType.db)} alt={'icon'}
                                                     style={{width: 35}}/>
                                                {socialMediaType.publicLink}
                                            </button>
                                        </li>
                                    ))}
                                </div>
                            </div>

                            {selectedSocialMedia !== "" && selectedSocialMedia ? (
                                <div className="admin-influencers-history-search">
                                    <SearchBarComponent
                                        data={influencers.flatMap(influencer => {
                                            const usernamesFilteredBySocialMedia = influencer.influencerNetworkUsernames.filter(account => account.socialMedia === selectedSocialMedia);
                                            return usernamesFilteredBySocialMedia;
                                        })}
                                        setSearchResult={setInfluencerHistorySearchResult}
                                        searchFunction={searchFunction}
                                        className="large"
                                        typeOfSearch="influencersHistory"
                                    />
                                </div>
                            ) : (<Loading/>)}
                        </div>
                        {influencerHistorySearchResult && influencerHistory && (
                            <AdminInfluencerHistory influencerHistory={influencerHistory}/>
                        )}
                    </div>
                )}
                <NotificationContainer/>
            </div>
        </section>
    );
};

export default AdminInfluencersHistoryPage;