import React, {useState} from 'react'
import videoImg from "../../../../../images/icons/iconsForCampaignReport/video 1.svg";
import instaIcon from "../../../../../images/icons/iconsForCampaignReport/instagram 1.svg";
import linkIcon from "../../../../../images/icons/iconsForCampaignReport/link 1.svg";
import screenshotIcon from "../../../../../images/icons/iconsForCampaignReport/image- 1.svg";
import {getSocialMediaIconForPickAndChoose} from "../../../../../utils/typeOfSocialAccounts";

export const ReportCampaignDesktopTable = ({data, totalFollowers, totalPrice, totalImpressions, totalLikes}) => {
    const [headers] = useState([
        {
            Header: "Networks", accessor: "Networks",
        }, {
            Header: "Followers", accessor: "Followers",
        }, {
            Header: "Date Post", accessor: "Date Post",
        }, {
            Header: "Video", accessor: "Video",
        }, {
            Header: "Post Description", accessor: "Post Description",
        }, {
            Header: "Story Tag", accessor: "Story Tag",
        }, {
            Header: "Story Link", accessor: "Story Link",
        }, {
            Header: "Post Link", accessor: "Post Link",
        }, {
            Header: "Screenshot", accessor: "Screenshot",
        }, {
            Header: "Impressions", accessor: "Impressions",
        }, {
            Header: "Likes", accessor: "Likes",
        }
    ]);

    return (
        <table className="report-table" id='campaign-report-table'>
            <thead className="report-table-header">
            <tr>
                {headers.map((header, index) => (
                    <th key={index}>{header.Header}</th>
                ))}
            </tr>
            </thead>
            <tbody className="report-table-body">
            {data?.selectInfluencers.map((account, index) => (
                <tr key={index}>
                    <td id="username">
                        <div id='username-container'>
                            <img src={getSocialMediaIconForPickAndChoose(account.socialMedia)} alt=''/>
                            <p>{account.socialMediaUsername}</p>
                        </div>
                    </td>
                    <td id="followers">
                        <p>{account.followersNumber}</p>
                    </td>
                    <td id="date">
                        <p>{account.dateRequest}</p>
                    </td>
                    <td id="videolink">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItem !== '' && (
                            <div className='videolink-container' onClick={() => window.open(account.selectedCampaignContentItemObj.videoLink, "_blank")}>
                                <img src={videoImg} alt=""/>
                                <p>#{data.campaignContent.findIndex((content) => account.selectedCampaignContentItem === content._id) + 1}</p>
                            </div>
                        )}
                    </td>
                    <td id="post-decription">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItemObj && (
                            <div>
                                <p>{account.selectedCampaignContentItemObj.postDescription}</p>
                            </div>
                        )}
                    </td>
                    <td id="story-tag">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItemObj && (
                            <div>
                                {account.selectedCampaignContentItemObj.storyTag}
                            </div>
                        )}
                    </td>
                    <td id="story-link">
                        {account.selectedCampaignContentItem && account.selectedCampaignContentItemObj && (
                            <div className="story-link-container"
                                onClick={() => window.open(account.selectedCampaignContentItemObj.swipeUpLink, "_blank")}>
                                {account.selectedCampaignContentItemObj.swipeUpLink}
                            </div>
                        )}
                    </td>
                    <td id="post-link">
                        {account.postLink && (
                            <div className='post-link-container' onClick={() => window.open(account.postLink, "_blank")}>
                                <img src={instaIcon} alt=""/>
                                <img src={linkIcon} alt=""/>
                            </div>
                        )}
                    </td>
                    <td id="post-link">
                        {account.screenshot && (
                            <div className='post-link-container' onClick={() => window.open(account.screenshot, "_blank")}>
                                <img src={screenshotIcon} alt=""/>
                                <img src={linkIcon} alt=""/>
                            </div>
                        )}
                    </td>
                    <td id="impressions">
                        <p>{account.impressions}</p>
                    </td>
                    <td id="impressions">
                        <p>{account.like}</p>
                    </td>
                </tr>
            ))}
            </tbody>
            <tfoot className="report-table-footer">
            <tr>
                <td id='footer-info-td'>
                    <p>PRICE: {totalPrice}€</p>
                </td>
                <td id='footer-info-td'>
                    <p>{totalFollowers}</p>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td id='footer-info-td'>
                    <p>{totalImpressions}</p>
                </td>
                <td id='footer-info-td'>
                    <p>{totalLikes}</p>
                </td>
            </tr>
            </tfoot>
        </table>
    )
}
