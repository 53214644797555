import React, {useEffect, useState} from 'react'
import "./addInfluencerToCampaignModal.css";
import ModalWindow from "../../ModalWindow";
import TitleSection from "../../TitleSection";
import SearchBar from "../SearchBar/SearchBar";
import StandardButton from "../StandardButton";
import {getSocialMediaIconForPickAndChoose} from "../../../utils/typeOfSocialAccounts";
import {genres} from "../../../utils/genresList";
import {returnMusicStyles} from "../../../utils/musicStyles";

export const AddInfluencerToCampaignModal = ({isOpen, setClose, influencers, selectInfluencer}) => {
    const [searchResult, setSearchResult] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedGenres, setSelectedGenres] = useState([]);

    useEffect(() => {
        if (searchResult) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [searchResult]);

    const searchByUsername = (data, searchInput) => {
        return data.filter(influencer => {
            const influencerGenres = returnMusicStyles(
                influencer.musicStyle,
                influencer.musicStyleOther,
                influencer.musicSubStyles
            );

            const matchesUsername = influencer.instagramUsername
                && influencer.instagramUsername.toLowerCase().includes(searchInput.toLowerCase());

            const matchesGenres = selectedGenres.length === 0
                || selectedGenres.every(genre => influencerGenres && influencerGenres.includes(genre));

            return matchesUsername && matchesGenres;
        });
    };

    return (
        <ModalWindow isOpen={isOpen} setClose={setClose} maxWidth={1000}>
            <div className="add-influencer-modal">
                <TitleSection title="find" span="influencer"/>

                <div id="genres-container">
                    {genres.map((genre, i) => {
                        const onClick = () => {
                            if (selectedGenres && selectedGenres.includes(genre)) {
                                setSelectedGenres(selectedGenres.filter(item => item !== genre));
                            } else {
                                setSelectedGenres([...selectedGenres, genre]);
                            }
                        };

                        return (
                            <div id="genre-button"
                                 className={selectedGenres.includes(genre) ? 'genre-button-selected' : ''}
                                 key={i}
                                 onClick={onClick}>
                                <p>{genre}</p>
                            </div>
                        );
                    })}
                </div>

                <div id="search-container">
                    <SearchBar data={influencers} className="medium" setSearchResult={setSearchResult}
                               searchFunction={searchByUsername} typeOfSearch="influencers"/>
                </div>

                {searchResult && (
                    <div className={`result-block ${isVisible ? "visible" : ""}`}>
                        <div id='acc-info'>
                            <div className="logo-username">
                                <div id='sm-logo'>
                                    <img id="sm" src={getSocialMediaIconForPickAndChoose(searchResult.socialMedia)}
                                         alt=""/>
                                    <img id="logo" src={searchResult.logo} alt=""/>
                                </div>
                                <p>{searchResult.instagramUsername}</p>
                            </div>
                            {(searchResult.musicStyle || searchResult.musicStyleOther || searchResult.musicSubStyles) && (
                                <div className="genres-list">
                                    {returnMusicStyles(searchResult.musicStyle, searchResult.musicStyleOther, searchResult.musicSubStyles).map((genre) =>
                                    <p>{genre}</p>)}
                                </div>
                            )}
                            <div className="price-followers">
                                <p>Price: <span>{searchResult.publicPrice}€</span></p>
                                <p>Followers: <span>{searchResult.followersNumber}</span></p>
                            </div>
                        </div>
                        <div className="add-influencer-button">
                            <StandardButton text="Add Influencer" isBlue={true} onClick={() => {
                                selectInfluencer(searchResult);
                                setClose();
                            }}/>
                        </div>
                    </div>
                )}
            </div>
        </ModalWindow>
    )
}
