import React, {useEffect, useState} from "react";
import TitleSection from "../../../../../TitleSection";
import TextInput from "../../../../../form/TextInput";
import StandardButton from "../../../../../form/StandardButton";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    setCurrentWindow,
    setCampaignName,
    setCreatedAt,
    addVideo,
    updateVideo,
    removeVideo,
} from "../../../../../../redux/slice/create-promo";
import arrow from "../../../../../../images/icons/arrow.svg";
import {useParams} from "react-router-dom";
import {createSelector} from "@reduxjs/toolkit";
import {memoize} from "lodash";
import {CampaignContentItem} from "../../../admin/AdminProposalSystem/components/form/CampaignContentItem";
import {generateMongoObjectId} from "../../../../../../utils/generateId";
import {getSocialMediaIconForPickAndChoose} from "../../../../../../utils/typeOfSocialAccounts";

const returnTitle = (socialMedia) => {
    let obj = {title: '', span: ''};
    if (socialMedia === "spotify" || socialMedia === "soundcloud") {
        obj.title = 'submit';
        obj.span = 'this song';
    } else if (socialMedia === "press") {
        obj.title = 'review';
        obj.span = 'this content';
    } else {
        obj.title = 'post';
        obj.span = 'this content';
    }
    return obj;
}

export const returnCampaignContentItemPostName = (socialMedia) => {
    if (socialMedia === "spotify" || socialMedia === "soundcloud") {
        return `${socialMedia.toUpperCase()} SONG`;
    } else if (socialMedia === "press") {
        return 'PRESS RELEASE';
    } else {
        return `${socialMedia.toUpperCase()} VIDEO`;
    }
}

const CreateCampaignPostContent = () => {
    const dispatch = useDispatch();
    const {socialMedia} = useParams();

    const isMultiPromo = window.location.pathname.includes("multipromo");

    const currentWindow = useSelector((state) => state.createPromo.currentWindow);
    const {videos: campaignContent, campaignName} = useSelector((state) => state.createPromo.data);

    const [formError, setFormError] = useState({
        campaignName: false,
    });
    const [isAddAdditionalContent, setIsAddAdditionalContent] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setWindowWidth(window.innerWidth);
            });
        };
    }, []);

    const memoSelectedSocialMedias = createSelector(
        state => state.createPromo.data.selectInfluencers,
        memoize(selectInfluencers => {
            let socialMedias = [];

            selectInfluencers.forEach(influencer => {
                if (!socialMedias.includes(influencer.socialMedia)) socialMedias.push(influencer.socialMedia);
            });

            return socialMedias;
        })
    );
    const selectedSocialMedias = useSelector(memoSelectedSocialMedias, shallowEqual);

    useEffect(() => {
        if (campaignContent.length === 0) {
            selectedSocialMedias.forEach(socialMedia => {
                addNewCampaignContentItem(socialMedia);
            })
        }
    }, []);

    const addNewCampaignContentItem = (socialMedia) => {
        dispatch(addVideo({
            _id: generateMongoObjectId(),
            videoLink: "",
            postDescription: "",
            storyTag: "",
            swipeUpLink: "",
            dateRequest: "01/01/01",
            specialWishes: "",
            socialMedia: socialMedia
        }));
    };

    const nextForm = () => {
        let haveError = false;
        let newFormError = {...formError};

        if (campaignName === "") {
            haveError = true;
            newFormError.campaignName = true;
        }

        campaignContent.forEach((campaignContentItem, index) => {
            if (["spotify", "soundcloud"].includes(campaignContentItem.socialMedia)) {
                if (campaignContentItem.postDescription === "") {
                    haveError = true;
                    newFormError[`postDescription${index}`] = true;
                }
                if (campaignContentItem.videoLink === "") {
                    haveError = true;
                    newFormError[`videoLink${index}`] = true;
                }
            } else {
                if (campaignContentItem.postDescription === "") {
                    haveError = true;
                    newFormError[`postDescription${index}`] = true;
                }
                if (campaignContentItem.videoLink === "") {
                    haveError = true;
                    newFormError[`videoLink${index}`] = true;
                }
                if (campaignContentItem.storyTag === "") {
                    haveError = true;
                    newFormError[`storyTag${index}`] = true;
                }
            }
        });

        if (haveError) {
            setFormError(newFormError);
            return;
        }

        dispatch(setCreatedAt(new Date().toISOString().split("T")[0]));
        dispatch(setCurrentWindow(currentWindow + 1));
    };


    const getContentFieldValueFunc = (contentId, fieldName) => {
        const content = campaignContent.find((content) => content._id === contentId);
        return content[fieldName];
    };

    const setContentFieldValueFunc = (contentId, fieldName, value) => {
        dispatch(updateVideo({contentId, fieldName, value}));
    };

    return (
        <section className="account-client">
            <div className="container-post-campaign-form">
                <div className="account-client-block" style={{position: "relative"}}>
                    <div className="back-btn-title" style={{width: windowWidth <= 850 ? '95%' : windowWidth <= 1200 ? '70%' : '50%'}}>
                        <button onClick={() => {
                            if (isMultiPromo) {
                                dispatch(setCurrentWindow(0));
                            } else {
                                dispatch(setCurrentWindow(1));
                            }
                        }}>
                            <img src={arrow} alt={'back'}/>
                        </button>
                        {isMultiPromo ? <TitleSection title='Post' span='this content'/> :
                            <TitleSection title={returnTitle(socialMedia).title} span={returnTitle().span}/>
                        }
                    </div>

                    <div className="account-client-post-campaign-name">
                        <p>CAMPAIGN NAME</p>
                        <TextInput
                            placeholder="Enter Campaign Name"
                            style={{marginTop: "21px", fontSize: windowWidth <= 500 && 18}}
                            value={campaignName}
                            setValue={(value) => dispatch(setCampaignName(value))}
                            error={formError.campaignName}
                            onFocus={() => setFormError({...formError, campaignName: false})}
                            silverColor={true}
                        />
                    </div>

                    <div className="account-client-post-campaign-content-item-block">
                        {campaignContent.map((campaignContentItem, index) => {
                            const thisSocialMediaItems = campaignContent.filter(
                                item => item.socialMedia === campaignContentItem.socialMedia
                            );

                            const socialMediaIndex = thisSocialMediaItems.findIndex(
                                item => item === campaignContentItem
                            ) + 1;

                            return (
                                <CampaignContentItem
                                    numOfContent={socialMediaIndex}
                                    typeOfSocialMedia={campaignContentItem.socialMedia}
                                    contentItemId={campaignContentItem._id}
                                    getContentFieldValue={getContentFieldValueFunc}
                                    setContentFieldValue={setContentFieldValueFunc}
                                    showAddButton={false}
                                    addContentItem={null}
                                    showRemoveButton={index !== 0}
                                    removeContentItem={() => dispatch(removeVideo(index))}
                                    fadeIn={index !== 0}
                                    errors={{
                                        postDescription: formError[`postDescription${index}`] || false,
                                        videoLink: formError[`videoLink${index}`] || false,
                                        storyTag: formError[`storyTag${index}`] || false,
                                    }}
                                />
                            );
                        })}
                    </div>

                    <div className="account-client-post-campaign-content" style={{
                        gap: isAddAdditionalContent ? "30px" : "0",
                    }}>
                        <StandardButton
                            text={isMultiPromo ? "ADD ADDITIONAL POST CONTENT" : "ADD ADDITIONAL " + returnCampaignContentItemPostName(socialMedia)}
                            isBlue={true}
                            onClick={() => isMultiPromo ? setIsAddAdditionalContent(!isAddAdditionalContent) : addNewCampaignContentItem(socialMedia)}
                        />

                        <div
                            className={`multicampaign-post-content-additional-modal ${isAddAdditionalContent ? 'visible' : ''}`}>
                            <div className={`select-content-type`}>
                                {isAddAdditionalContent && selectedSocialMedias.map((socialMedia, index) => (
                                    <div className="select-content-type-item" key={index}
                                         onClick={() => addNewCampaignContentItem(socialMedia)}>
                                        <img src={getSocialMediaIconForPickAndChoose(socialMedia)} alt={socialMedia}/>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <StandardButton text="Continue" onClick={() => nextForm()} style={{marginBottom: 40}}/>
                    </div>
                </div>
            </div>
        </section>
    )
        ;
};

export default CreateCampaignPostContent;
