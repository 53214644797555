import React from "react";
import "./styles/main.scss";
import {Route, Routes} from "react-router-dom";
import Signup from "./pages/signup";
import SignupClient from "./pages/signup-client/signup-client";
import SignupClientAgreement from "./pages/signup-client/signup-client-agreement";
import AccountClientHome from "./pages/account/client/home";
import SignupInfluencerAgreement from "./pages/signup-influencer/signup-influencer-agreement";
import AccountInfluencerHome from "./pages/account/influencer/home";
import AccountInfluencerDetails from "./pages/account/influencer/details";
import LoginClientPage from "./pages/login/login-client";
import LoginInfluencerPage from "./pages/login/login-influencer";
import ForgotPasswordEmail from "./pages/login/forgot-password-email";
import ForgotPasswordCode from "./pages/login/forgot-password-code";
import AccountClientDetails from "./pages/account/client/details";
import AccountClientInvoiceDetails from "./pages/account/client/invoice-details";
import AccountClientPastPromos from "./pages/account/client/past-promos";
import AccountInfluencerCreateInvoicePage from "./pages/account/influencer/create-invoice";
import AccountInfluencerInvoicesPage from "./pages/account/influencer/invoices";
import {ThemeProvider} from "./ThemeContext";
import SignupInfluencer from "./pages/signup-influencer/signup-influencer";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import SignupInfluencerLast from "./pages/signup-influencer/signup-influencer-last";
import AccountInfluencerPastPromos from "./pages/account/influencer/past-promos";
import AccountInfluencerPastPromosCurrentPage from "./pages/account/influencer/past-promos-current";
import ReportCampaignsPage from "./pages/account/influencer/report-campaigns";
import InvoiceResultPage from "./pages/account/influencer/invoice-result";
import AccountInfluencerOngoingPromos from "./pages/account/influencer/ongoing-promos";
import AccountInfluencerNewPromosPage from "./pages/account/influencer/new-promos";
import AccountInfluencerOngoingPromosCurrentPage from "./pages/account/influencer/ongoing-promos-current";
import AccountClientOngoingPromos from "./pages/account/client/ongoing-promos";
import AcountClientOngoingCurrentPage from "./pages/account/client/ongoing-promos-current";
import AccountInfluencerUpdateIngoingPromoPage from "./pages/account/influencer/update-ongoing-promo";
import TermsClient from "./pages/terms-client";
import TermsInfluencer from "./pages/terms-influencer";
import JivoChat from "./components/JivoChat";
import AccountClientListOffers from "./pages/account/client/select-campaign-type";
import PromoSharePage from "./pages/account/client/promo-share";
import AccountAdminHome from "./pages/account/admin/admin-home";
import AdminCheckPassword from "./pages/account/admin/admin-check-password";
import AccountAdminClients from "./pages/account/admin/admin-clients";
import AccountAdminInfluencers from "./pages/account/admin/admin-influencers";
import AccountAdminCampaigns from "./pages/account/admin/admin-campaigns";
import AccountAdminInvoicesClients from "./pages/account/admin/admin-invoices-clients";
import AccountAdminInvoicesInfluencers from "./pages/account/admin/admin-invoices-influencers";
import AccountAdminOffers from "./pages/account/admin/admin-offers-navigation";
import PaymentInvoiceDownload from "./pages/account/client/payment-invoice-download";
import AccountAdminCampaignManagement from "./pages/account/admin/admin-campaign-management";
import InfluencerEditAccount from "./pages/account/influencer/details-edit-account";
import {SocketProvider} from "./redux/SocketContext";
import AccountInfluencerApprovedAccounts from "./pages/account/influencer/approved-accounts";
import AdminRoute from "./components/AdminRoute";
import AccountAdminInfluencersHistory from "./pages/account/admin/admin-influencers-history";
import AccountAdminInfluencerEdit from "./pages/account/admin/admin-influencer-edit-page";
import AccountAdminProposalSystem from "./pages/account/admin/admin-proposal-system";
import AccountAdminProposalSystemManageCampaign from "./pages/account/admin/admin-proposal-system-manage-campaign";
import AccountClientProposalSystemSharedCampaign from "./pages/account/client/proposal-system-shared-campaign";
import AccountClientCreateMultiCampaign from "./pages/account/client/create-multicampaign";
import AccountClientCreateCampaignPage from "./pages/account/client/create-campaign";
import {NotificationContainer} from "react-notifications";
import AccountClientCreateVIPCampaign from "./pages/account/client/new-vip-campaign";
const App = () => {
    return (
        <>
            <JivoChat/>
            <SocketProvider>
                <ThemeProvider>
                    <Routes>
                        <Route path="/signup" element={<PublicRoute element={Signup}/>}/>
                        <Route
                            path="/signup/client"
                            element={<PublicRoute role="client" element={SignupClient}/>}
                        />
                        <Route
                            path="/signup/client/agreement"
                            element={
                                <PublicRoute role="client" element={SignupClientAgreement}/>
                            }
                        />
                        <Route
                            path="/signup/influencer"
                            element={
                                <PublicRoute role="influencer" element={SignupInfluencer}/>
                            }
                        />
                        <Route
                            path="/signup/influencer/agreement"
                            element={
                                <PublicRoute
                                    role="influencer"
                                    element={SignupInfluencerAgreement}
                                />
                            }
                        />
                        <Route
                            path="/signup/influencer/last"
                            element={
                                <PublicRoute role="influencer" element={SignupInfluencerLast}/>
                            }
                        />

                        <Route
                            path="/account/client"
                            element={<PrivateRoute role="client" element={AccountClientHome}/>}
                        />

                        <Route
                            path="/account/client/details"
                            element={
                                <PrivateRoute role="client" element={AccountClientDetails}/>
                            }
                        />
                        <Route
                            path="/account/client/invoice-details"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AccountClientInvoiceDetails}
                                />
                            }
                        />
                        <Route
                            path="/account/client/past-promos"
                            element={
                                <PrivateRoute role="client" element={AccountClientPastPromos}/>
                            }
                        />
                        <Route
                            path="/account/client/list-promo"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AccountClientListOffers}
                                />
                            }
                        />
                        <Route
                            path="/account/client/new-vip-campaign"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AccountClientCreateVIPCampaign}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer/approved-accounts"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerApprovedAccounts}
                                />
                            }
                        />
                        <Route
                            path="/account/client/promo/multipromo"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AccountClientCreateMultiCampaign}
                                />
                            }
                        />
                        <Route
                            path="/account/client/promo/:socialMedia"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AccountClientCreateCampaignPage}
                                />
                            }
                        />
                        <Route
                            path="/account/client/ongoing-promos"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AccountClientOngoingPromos}
                                />
                            }
                        />
                        <Route
                            path="/account/client/past-promos/:id"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AcountClientOngoingCurrentPage}
                                />
                            }
                        />
                        <Route
                            path="/account/client/ongoing-promos/:id"
                            element={
                                <PrivateRoute
                                    role="client"
                                    element={AcountClientOngoingCurrentPage}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer"
                            element={
                                <PrivateRoute role="influencer" element={AccountInfluencerHome}/>
                            }
                        />
                        <Route
                            path="/account/influencer/details"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerDetails}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer/details/:accountId"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={InfluencerEditAccount}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer/invoices"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerInvoicesPage}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer/past-promos"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerPastPromos}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer/past-promos/:campaignId/:socialMediaUsername/:selectedCampaignContentItem"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerPastPromosCurrentPage}
                                />
                            }
                        />

                        <Route
                            path="/account/influencer/ongoing-promos"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerOngoingPromos}
                                />
                            }
                        />

                        <Route
                            path="/account/influencer/ongoing-promos/:influencerId/:promoId/:networkUsername/:selectInfluencersId"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerOngoingPromosCurrentPage}
                                />
                            }
                        />

                        <Route
                            path="/account/influencer/update-ongoing-promos/:promoId/:influencerId/:networkUsername/:selectInfluencersId"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerUpdateIngoingPromoPage}
                                />
                            }
                        />

                        <Route
                            path="/account/influencer/new-promos"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerNewPromosPage}
                                />
                            }
                        />

                        <Route
                            path="/account/influencer/create-invoice"
                            element={
                                <PrivateRoute
                                    role="influencer"
                                    element={AccountInfluencerCreateInvoicePage}
                                />
                            }
                        />
                        <Route
                            path="/account/influencer/reports"
                            element={
                                <PrivateRoute role="influencer" element={ReportCampaignsPage}/>
                            }
                        />
                        <Route
                            path="/account/influencer/invoice-result"
                            element={
                                <PrivateRoute role="influencer" element={InvoiceResultPage}/>
                            }
                        />
                        <Route
                            path="/login/client"
                            element={<PublicRoute role="client" element={LoginClientPage}/>}
                        />
                        <Route
                            path="/login/influencer"
                            element={
                                <PublicRoute role="influencer" element={LoginInfluencerPage}/>
                            }
                        />
                        <Route path="/forgot" element={<ForgotPasswordEmail/>}/>
                        <Route path="/forgot/code/:email" element={<ForgotPasswordCode/>}/>
                        <Route path="/terms-client" element={<TermsClient/>}/>
                        <Route path="/terms-influencer" element={<TermsInfluencer/>}/>
                        <Route path="/" element={<PublicRoute element={Signup}/>}/>
                        <Route path="/promo-share/:promoId/:socialMedia" element={<PromoSharePage/>}/>
                        <Route path="/proposal-system/shared-campaign/:campaignId" element={<AccountClientProposalSystemSharedCampaign/>}/>
                        <Route path="/invoice-download/:invoiceId" element={<PaymentInvoiceDownload/>}/>
                        <Route path="/admin" element={
                            <PrivateRoute element={AdminCheckPassword}/>
                        }/>
                        <Route path="/admin/home" element={<AdminRoute element={<AccountAdminHome />} />} />
                        <Route path="/admin/clients" element={<AdminRoute element={<AccountAdminClients />} />} />
                        <Route path="/admin/influencers" element={<AdminRoute element={<AccountAdminInfluencers />} />} />
                        <Route path="/admin/campaigns" element={<AdminRoute element={<AccountAdminCampaigns />} />} />
                        <Route path="/admin/campaigns/campaign-management/:campaignId" element={<AdminRoute element={<AccountAdminCampaignManagement />} />} />
                        <Route path="/admin/invoices-clients" element={<AdminRoute element={<AccountAdminInvoicesClients />} />} />
                        <Route path="/admin/invoices-influencers" element={<AdminRoute element={<AccountAdminInvoicesInfluencers />} />} />
                        <Route path="/admin/offers" element={<AdminRoute element={<AccountAdminOffers />} />} />
                        <Route path="/admin/influencers/history" element={<AdminRoute element={<AccountAdminInfluencersHistory />} />} />
                        <Route path="/admin/influencer-edit-page/:influencerId" element={<AdminRoute element={<AccountAdminInfluencerEdit />} />} />
                        <Route path="/admin/proposal-system" element={<AdminRoute element={<AccountAdminProposalSystem />} />} />
                        <Route path="/admin/proposal-system/manage-campaign" element={<AdminRoute element={<AccountAdminProposalSystemManageCampaign />} />} />
                    </Routes>
                    <NotificationContainer/>
                </ThemeProvider>
            </SocketProvider>
        </>
    );
};

export default App;
