import React, {useEffect, useState} from 'react'
import TitleSection from "../../../../../TitleSection";
import {CampaignContentItem} from "./form/CampaignContentItem";
import {useDispatch, useSelector} from "react-redux";
import {generateMongoObjectId} from "../../../../../../utils/generateId";
import {
    addContentToCampaign,
    removeContentItemFromCampaign,
    setContentFieldValue
} from "../../../../../../redux/slice/proposal-system";
import {removeVideo} from "../../../../../../redux/slice/create-promo";
import StandardButton from "../../../../../form/StandardButton";
import {
    returnCampaignContentItemPostName
} from "../../../client/CreatePromo/CreateCampaignPagesComponents/CreateCampaignPostContent";
import {getSocialMediaIconForPickAndChoose} from "../../../../../../utils/typeOfSocialAccounts";

export const SelectContent = () => {
    const dispatch = useDispatch();
    const {campaignContent, selectedSocialMedias} = useSelector((state) => state.proposalSystem);
    const [isAddAdditionalContent, setIsAddAdditionalContent] = useState(false);

    useEffect(() => {
        if (campaignContent && campaignContent.length === 0) {
            selectedSocialMedias.forEach((socialMedia) => {
                addNewContentItemFunc(socialMedia)
            })
        }
    }, [campaignContent]);

    const addNewContentItemFunc = (socialMedia) => {
        const newId = generateMongoObjectId();
        dispatch(
            addContentToCampaign({
                _id: newId,
                videoLink: "",
                postDescription: "",
                storyTag: "",
                swipeUpLink: "",
                specialWishes: "",
                socialMedia: socialMedia,
            })
        );
    };

    const removeContentItemFunc = (contentId) => {
        dispatch(removeContentItemFromCampaign(contentId));
    };

    const getContentFieldValueFunc = (contentId, fieldName) => {
        const content = campaignContent.find((content) => content._id === contentId);
        return content[fieldName];
    };

    const setContentFieldValueFunc = (contentId, fieldName, value) => {
        dispatch(setContentFieldValue({ contentId, fieldName, value }));
    };

    return (
        <section className="proposal-system-component" style={{marginBottom: 40}}>
            <div className="account-client-title">
                <TitleSection title={"Create"} span={"Content"} />
            </div>

            {campaignContent.map((content, index) => {
                const thisSocialMediaItems = campaignContent.filter(
                    item => item.socialMedia === content.socialMedia
                );

                const socialMediaIndex = thisSocialMediaItems.findIndex(
                    item => item === content
                ) + 1;

                return (
                    <CampaignContentItem
                        numOfContent={socialMediaIndex}
                        typeOfSocialMedia={content.socialMedia}
                        contentItemId={content._id}
                        getContentFieldValue={getContentFieldValueFunc}
                        setContentFieldValue={setContentFieldValueFunc}
                        showAddButton={false}
                        addContentItem={null}
                        showRemoveButton={index !== 0}
                        removeContentItem={() => removeContentItemFunc(content._id)}
                        fadeIn={index !== 0}
                    />
                )
            })}

            <div className="account-client-post-campaign-content" style={{
                gap: isAddAdditionalContent ? "30px" : "0",
            }}>
                <StandardButton
                    text={"ADD ADDITIONAL POST CONTENT"}
                    isBlue={true}
                    onClick={() => setIsAddAdditionalContent(!isAddAdditionalContent)}
                />

                <div
                    className={`multicampaign-post-content-additional-modal ${isAddAdditionalContent ? 'visible' : ''}`}>
                    <div className={`select-content-type`}>
                        {isAddAdditionalContent && selectedSocialMedias.map((socialMedia, index) => (
                            <div className="select-content-type-item" key={index}
                                 onClick={() => addNewContentItemFunc(socialMedia)}>
                                <img src={getSocialMediaIconForPickAndChoose(socialMedia)} alt={socialMedia}/>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};
