import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import AccountClientSelectCampaignType from "../../../components/layout/account/client/AccountClientSelectCampaignType";

const AccountClientSelectCampaignTypePage = () => {
  return (
    <>
      <Header path="Sponsoring client / My Account / New Campaigns" />
      <AccountClientSelectCampaignType />
      <Background />
    </>
  );
};

export default AccountClientSelectCampaignTypePage;