import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    datePost: "",
    impressions: "",
    like: "",
    postLink: "",
    screenshot: "",
}

export const influencerUpdateOngoingCampaignSlice = createSlice({
    name: "influencer-update-ongoing-campaign",
    initialState,
    reducers: {
        setOngoingCampaignDatePost: (state, action) => {
            state.datePost = action.payload;
        },
        setOngoingCampaignImpressions: (state, action) => {
            state.impressions = action.payload;
        },
        setOngoingCampaignLike: (state, action) => {
            state.like = action.payload;
        },
        setOngoingCampaignPostLink: (state, action) => {
            state.postLink = action.payload;
        },
        setOngoingCampaignScreenshot: (state, action) => {
            state.screenshot = action.payload;
        },
        setInfluencerUpdateOngoingCampaignClear: (state) => {
            state.datePost = "";
            state.impressions = "";
            state.like = "";
            state.postLink = "";
            state.screenshot = "";
        },
        setFullInfluencerUpdateOngoingCampaign: (state, action) => {
            state.datePost = action.payload.datePost;
            state.impressions = action.payload.impressions;
            state.like = action.payload.like;
            state.postLink = action.payload.postLink;
            state.screenshot = action.payload.screenshot;
        }
    }
})

export const {
    setOngoingCampaignDatePost,
    setOngoingCampaignImpressions,
    setOngoingCampaignLike,
    setOngoingCampaignPostLink,
    setOngoingCampaignScreenshot,
    setInfluencerUpdateOngoingCampaignClear,
    setFullInfluencerUpdateOngoingCampaign
} = influencerUpdateOngoingCampaignSlice.actions;

export default influencerUpdateOngoingCampaignSlice.reducer;