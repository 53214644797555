import React, {useEffect, useState} from "react";
import TitleSection from "../../../../TitleSection";
import FormContainer from "../../../../form/FormContainer";
import {useParams, useNavigate} from "react-router-dom";
import axios from "axios";
import UseVerify from "../../../../../hooks/useVerify";
import altLogo from "../../../../../images/alt-logo.jpg";
import ImageWithFallback from "../../../../ImageWithFallback";
import arrow from "../../../../../images/icons/arrow.svg";
import {getSocialMedia} from "../../../../../utils/typeOfSocialAccounts";
import {decodeAndDecrypt, encryptAndEncode} from "../../../../../utils/crypt";
import Loading from "../../../../form/PageLoading/pageLoading";
import {NotificationContainer, NotificationManager} from "react-notifications";

const AcountClientPastPromosCurrent = () => {
    const params = useParams();
    const navigation = useNavigate();
    const [data, setData] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getData = async () => {
        try {
            const {dataFetch} = await UseVerify();

            const encryptedInfluencerId = encryptAndEncode(dataFetch._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);
            const encryptedCampaignId = encryptAndEncode(decodeAndDecrypt(params.campaignId, process.env.REACT_APP_URL_PAGES_SECRET_KEY), process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);
            const encodedSocialMediaUsername = encodeURI(decodeURI(params.socialMediaUsername));
            const encodedSelectedCampaignContentItem = encryptAndEncode(decodeAndDecrypt(params.selectedCampaignContentItem, process.env.REACT_APP_URL_PAGES_SECRET_KEY), process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);

            const result = await axios(
                `${process.env.REACT_APP_SERVER}/promos/influencer-completed-campaigns/one-campaign?influencerId=${encryptedInfluencerId}&campaignId=${encryptedCampaignId}&socialMediaUsername=${encodedSocialMediaUsername}&selectedCampaignContentItem=${encodedSelectedCampaignContentItem}`
            );

            if (result.data.code === 200) {
                setData(result.data.promo);
                return;
            }
            
            throw new Error(result.data.message);
        } catch (err) {
            NotificationManager.error(err.message, "Error", 5000);
            console.error(err);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [window.innerWidth]);
    
    const returnForm = () => {
        if (data?.socialMedia === 'spotify' || data?.socialMedia === 'soundcloud') {
            return (
                <div className="account-client-past-promos-form-current-content">
                    <h2 className="account-client-past-promos-form-current-content-title">
                        {`${data.campaignName ? data.campaignName : "Promo 1"}`}
                    </h2>
                    <p className="account-client-past-promos-form-current-content-client">
                        {data ? getSocialMedia(data?.socialMedia) : ""}:{" "}
                        <span
                            className="account-client-past-promos-form-current-content-client-value">
                        {data?.socialMediaUsername || ""}
                      </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-client">
                        Client:{" "}
                        <span className="account-client-past-promos-form-current-content-client-value">
                      {data.client}
                    </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-link">
                        {getSocialMedia(data?.socialMedia)} Track Link:{" "}
                        <a
                            href={data?.videoLink || ""}
                            className="account-client-past-promos-form-current-content-link-value"
                            target="_blank"
                        >
                            {data?.videoLink || ""}
                        </a>
                    </p>
                    <p className="account-client-past-promos-form-current-content-date">
                        Date Request:{" "}
                        <span className="account-client-past-promos-form-current-content-date-value">
                        {data?.dateRequest || ""}
                    </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-wish">
                        Special Requests:{" "}
                        <span className="account-client-past-promos-form-current-content-wish-value">
                        {data?.specialWishes || ""}
                    </span>
                    </p>
                </div>
            );
        } else if (data.socialMedia === 'press') {
            return (
                <div className="account-client-past-promos-form-current-content">
                    <h2 className="account-client-past-promos-form-current-content-title">
                        {data.campaignName ? data.campaignName : "Promo 1"}
                    </h2>
                    <p className="account-client-past-promos-form-current-content-client">
                        {data ? getSocialMedia(data?.socialMedia) : ""}:{" "}
                        <span
                            className="account-client-past-promos-form-current-content-client-value">
                        {data?.socialMediaUsername || ""}
                      </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-client">
                        Client:{" "}
                        <span
                            className="account-client-past-promos-form-current-content-client-value">
                        {data.client}
                      </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-link">
                        Link To Music / Event / News:{" "}
                        <a
                            href={data?.videoLink || ""}
                            className="account-client-past-promos-form-current-content-link-value"
                            target="_blank"
                        >
                            {data?.videoLink || ""}
                        </a>
                    </p>

                    <p className="account-client-past-promos-form-current-content-link">
                        Link To Artwork & Press Shots:{" "}
                        <a
                            href={data?.videoLink || ""}
                            className="account-client-past-promos-form-current-content-link-value"
                            target="_blank"
                        >
                            {data?.postDescription || ""}
                        </a>
                    </p>
                    <p className="account-client-past-promos-form-current-content-link">
                        Link To Press Release:{" "}
                        <a
                            href={data?.videoLink || ""}
                            className="account-client-past-promos-form-current-content-link-value"
                            target="_blank"
                        >
                            {data?.storyTag || ""}
                        </a>
                    </p>
                    <p className="account-client-past-promos-form-current-content-date">
                        Date Request:{" "}
                        <span
                            className="account-client-past-promos-form-current-content-date-value">
                        {data?.dateRequest || ""}
                      </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-wish">
                        Special Requests:{" "}
                        <span
                            className="account-client-past-promos-form-current-content-wish-value">
                            {data?.specialWishes || ""}
                      </span>
                    </p>
                </div>
            );
        } else {
            return (
                <div className="account-client-past-promos-form-current-content">
                    <h2 className="account-client-past-promos-form-current-content-title">
                        {`${data.campaignName ? data.campaignName : "Promo 1"}`}
                    </h2>
                    <p className="account-client-past-promos-form-current-content-client">
                        Client:{" "}
                        <span className="account-client-past-promos-form-current-content-client-value">
                        {data.client}
                    </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-link">
                        Videolink:{" "}
                        <a
                            href={data?.videoLink || ""}
                            className="account-client-past-promos-form-current-content-link-value"
                            target="_blank"
                        >
                            {data?.videoLink || ""}
                        </a>
                    </p>
                    <p className="account-client-past-promos-form-current-content-desc">
                        Description:{" "}
                        <span className="account-client-past-promos-form-current-content-desc-value">
                            {data?.postDescription || ""}
                    </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-date">
                        Date Request:{" "}
                        <span className="account-client-past-promos-form-current-content-date-value">
                            {data?.dateRequest || ""}
                    </span>
                    </p>
                    <p className="account-client-past-promos-form-current-content-wish">
                        Special Requests:{" "}
                        <span className="account-client-past-promos-form-current-content-wish-value">
                            {data?.specialWishes || ""}
                    </span>
                    </p>
                </div>
            );
        }
    }

    if (!data || data === {}) return (<div>
        <Loading/>
        <NotificationContainer/>
    </div>);
    return (
        <section className="account-client-past-promos" style={{marginBottom: 40}}>
            <div className="container">
                <div className="account-client-past-promos-block" style={{position: "relative"}}>
                    <div className="account-client-title-section">
                        <TitleSection title="MY" span="account"/>
                    </div>

                    <p className="account-client-past-promos-second" style={{marginTop: 20}}>Past promos</p>

                    <button
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: 50,
                            height: 50,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            navigation("/account/influencer/past-promos")
                        }}
                    >
                        <img src={arrow} style={{transform: "rotate(180deg)"}}/>
                    </button>

                    <FormContainer style={{
                        margin: '40px auto 0',
                        width: windowWidth > 1000 ? '100%' : windowWidth > 768 ? '90%' : '85%',
                        paddingBottom: 30,
                        paddingTop: 30,
                    }}>
                        <div className="account-client-past-promos-form-current">
                            <div className="account-client-past-promos-form-current-image">
                                <ImageWithFallback
                                    src={data ? data.logo : ""}
                                    style={{width: "100%", maxWidth: 400}}
                                    fallbackSrc={altLogo}
                                />
                            </div>
                            {returnForm()}
                        </div>
                    </FormContainer>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "30px",
                        }}
                    >
                        <a
                            style={{fontSize: windowWidth <= 850 && 18}}
                            href="mailto:admin@soundinfluencers.com?subject=Support%20Request"
                            className="account-client-past-promos-current-report"
                        >
                            Click here for support
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AcountClientPastPromosCurrent;
