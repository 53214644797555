import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import hide from "../../../../../../images/icons/hidden 63.svg";
import videoImg from "../../../../../../images/icons/iconsForCampaignReport/video 1.svg";
import {getSocialMediaIconForPickAndChoose} from "../../../../../../utils/typeOfSocialAccounts";

export const AccountClientSharedCampaignTableDesktop = ({isGenresShown, setIsGenresShown, isCountriesShown, setIsCountriesShown}) => {
    const totalFollowers = useSelector(state => state.proposalSystem.totalFollowers);
    const price = useSelector(state => state.proposalSystem.price);
    const addedAccounts = useSelector(state => state.proposalSystem.addedAccounts);
    const campaignContent = useSelector(state => state.proposalSystem.campaignContent);

    const getContentItemField = (contentItemFieldName, selectedCampaignContentItemId) => {
        const contentItem = campaignContent.find(contentItem => contentItem._id === selectedCampaignContentItemId);
        return contentItem ? contentItem[contentItemFieldName] : "";
    };

    return (<table className="proposal-system-influencers-table" style={{
        boxShadow: (isGenresShown || isCountriesShown) && 'none',
        marginTop: (isGenresShown || isCountriesShown) && 50,
        marginRight: 58,
        width: '100%',
        borderRadius: 30
    }}>
        <thead className="proposal-system-influencers-table-header">
        <tr>
            <th>Networks</th>
            <th>Followers</th>
            {isGenresShown && (
                <th className="th-with-text-above">
                    <div id="th-header" style={{minWidth: "92%"}} onClick={() => setIsGenresShown(!isGenresShown)}>
                        <img src={hide} alt=""/>
                        <span>HIDE MUSIC GENRES</span>
                    </div>
                    <div id="th-text">
                        <span>Genres</span>
                    </div>
                </th>
            )}
            {isCountriesShown && (
                <th className="th-with-text-above" onClick={() => setIsCountriesShown(!isCountriesShown)}>
                    <div id="th-header" style={{minWidth: '96%'}}>
                        <img src={hide} alt=""/>
                        <span>HIDE AUDIENCE LOCATIONS</span>
                    </div>
                    <div id="th-text">
                        <span>Top 5 Countries</span>
                    </div>
                </th>
            )}
            <th>Date Request</th>
            <th>Video</th>
            <th>Post Description</th>
            <th>Story Tag</th>
            <th>Story Link</th>
            <th>Special Requests</th>
        </tr>
        </thead>
        <tbody
            className={`proposal-system-shared-campaign-table-body ${(isGenresShown || isCountriesShown) && 'wider'}`}>
        {addedAccounts.map((account, index) => (<tr key={index}>
            <td id="username">
                <div style={{display: 'flex', gap: 10}}>
                    <img src={getSocialMediaIconForPickAndChoose(account.socialMedia)} alt=''
                         style={{width: 15}}/>
                    <p>{account.instagramUsername}</p>
                </div>
            </td>
            <td id="followers">
                {account.followersNumber}
            </td>
            {isGenresShown && (
                <td id="genres">
                    <div>
                        {account.genres?.length ? account.genres.map((genre, index) => (
                            <p>{genre} {index !== account.genres.length - 1 ? "- " : ""}</p>)) : null}
                    </div>
                </td>
            )}
            {isCountriesShown && (
                <td id="countries">
                    <div>
                        {account.countries?.length ? account.countries.map((country, index) => (<span id="country">{country.country} <span
                            id="percentage">{country.percentage}%</span> {index !== account.countries.length - 1 ? "- " : ""}</span>)) : null}
                    </div>
                </td>
            )}
            <td id="date">
                {account.dateRequest}
            </td>
            <td id="videolink">
                {account.selectedCampaignContentItem && (
                    <div onClick={() => window.open(campaignContent.find(item => item._id === account.selectedCampaignContentItem).videoLink, "_blank")}>
                        <img src={videoImg} alt=""/>
                        <p>#{campaignContent.findIndex((content) => account.selectedCampaignContentItem === content._id) + 1}</p>
                    </div>
                )}
            </td>
            <td id="post-decription">
                <div>
                    {account.selectedCampaignContentItem && getContentItemField("postDescription", account.selectedCampaignContentItem)}
                </div>
            </td>
            <td id="story-tag">
                <div>
                    {account.selectedCampaignContentItem && getContentItemField("storyTag", account.selectedCampaignContentItem)}
                </div>
            </td>
            <td id="story-link">
                <div onClick={() => window.open(getContentItemField("swipeUpLink", account.selectedCampaignContentItem), "_blank")}>
                    {account.selectedCampaignContentItem && getContentItemField("swipeUpLink", account.selectedCampaignContentItem)}
                </div>
            </td>
            <td id="special-requests">
                <div>
                    {account.selectedCampaignContentItem && getContentItemField("specialWishes", account.selectedCampaignContentItem)}
                </div>
            </td>
        </tr>))}
        </tbody>
        <tfoot className="proposal-system-influencers-table-footer">
        <tr>
            <td>PRICE: {price}€</td>
            <td>{totalFollowers}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        </tfoot>
    </table>)
}
