import CryptoJS from "crypto-js";

export const encryptAndEncode = (data, secretKey) => {
    const encrypted = CryptoJS.AES.encrypt(data, secretKey).toString();
    return encodeURIComponent(encrypted); 
};

export const decodeAndDecrypt = (cipherText, secretKey) => {
    const decodedCipherText = decodeURIComponent(cipherText); 
    const bytes = CryptoJS.AES.decrypt(decodedCipherText, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};