import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {
    AccountClientSharedCampaignPage
} from "../../../components/layout/account/client/ProposalSystem/SharedCampaign/AccountClientSharedCampaignPage";

const AccountClientProposalSystemSharedCampaign = () => {
    return (
        <>
            <Header />
            <AccountClientSharedCampaignPage />
            <Background />
        </>
    );
};

export default AccountClientProposalSystemSharedCampaign;
