import { createSlice } from "@reduxjs/toolkit";
import {generateMongoObjectId} from "../../utils/generateId";

const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("createPromoState", serializedState);
    } catch (e) {
        console.error("Error saving state to localStorage:", e);
    }
};

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem("createPromoState");
        return serializedState ? JSON.parse(serializedState) : {
            currentWindow: 0,
            data: {
                filterParams: {
                    selectedPlatforms: ['Instagram'],
                    selectedGenres: [],
                    selectedSubGenres: [],
                    selectedRegions: [],
                    selectedCountries: [],
                    selectedCategories: [],
                },
                sortAccountsBy: "Best Match",
                selectedBudget: null,
                platformsAccountsCount: {
                    instagram: 0,
                    tiktok: 0,
                    spotify: 0,
                    facebook: 0,
                    soundcloud: 0,
                    youtube: 0,
                    press: 0,
                },
                currency: "€",
                amount: 0,
                selectPrice: {
                    variant: 0,
                    genre: "",
                    price: 0,
                },
                selectInfluencers: [],
                selectPriceInfluencers: [],
                campaignName: "",
                paymentType: "",
                paymentStatus: "wait",
                createdAt: "",
                videos: [],
                socialMedia: "",
                canGoBack: true,
            },
            proposalSystem: {campaignId: null},
        };
    } catch (e) {
        console.error("Error loading state from localStorage:", e);
        return {
            currentWindow: 0,
            data: {
                filterParams: {
                    selectedPlatforms: ['Instagram'],
                    selectedGenres: [],
                    selectedSubGenres: [],
                    selectedRegions: [],
                    selectedCountries: [],
                    selectedCategories: [],
                },
                sortAccountsBy: "Best Match",
                selectedBudget: null,
                platformsAccountsCount: {
                    instagram: 0,
                    tiktok: 0,
                    spotify: 0,
                    facebook: 0,
                    soundcloud: 0,
                    youtube: 0,
                    press: 0,
                },
                currency: "€",
                amount: 0,
                selectPrice: {
                    variant: 0,
                    price: 0,
                },
                selectInfluencers: [],
                selectPriceInfluencers: [],
                campaignName: "",
                paymentType: "",
                paymentStatus: "wait",
                createdAt: "",
                videos: [],
                socialMedia: "",
                canGoBack: true,
            },
            proposalSystem: {campaignId: null},
        };
    }
};

const initialState = loadStateFromLocalStorage();

export const createPromoSlice = createSlice({
    name: "create-promo",
    initialState,
    reducers: {
        setCurrentWindow: (state, action) => {
            state.currentWindow = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectCurrency: (state, action) => {
            state.data.currency = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectPrice: (state, action) => {
            if (state.data.selectPrice.variant === action.payload.variant && state.data.selectPrice.genre === action.payload.genre) {
                state.data.selectPrice = {
                    variant: 0,
                    genre: "",
                    price: 0,
                };
                state.data.amount -= action.payload.price;
                saveStateToLocalStorage(state);
            } else {
                if (state.data.selectPrice.variant !== 0 && state.data.selectPrice.genre !== "") {
                    state.data.amount -= state.data.selectPrice.price;
                }
                
                state.data.selectPrice = action.payload;
                state.data.amount += action.payload.price;
                saveStateToLocalStorage(state);
            }
        },
        setSelectPlatform: (state, action) => {
            if (state.data.filterParams.selectedPlatforms.includes(action.payload)) {
                state.data.filterParams.selectedPlatforms = state.data.filterParams.selectedPlatforms.filter((platform) => platform !== action.payload);
            } else {
                state.data.filterParams.selectedPlatforms.push(action.payload);
            }
            
            saveStateToLocalStorage(state);
        },
        setSelectGenre: (state, action) => {
            if (state.data.filterParams.selectedGenres.includes(action.payload)) {
                state.data.filterParams.selectedGenres = state.data.filterParams.selectedGenres.filter((genre) => genre !== action.payload);
            } else {
                state.data.filterParams.selectedGenres.push(action.payload);
            }

            saveStateToLocalStorage(state);
        },
        setSelectSubGenre: (state, action) => {
            if (state.data.filterParams.selectedSubGenres.includes(action.payload)) {
                state.data.filterParams.selectedSubGenres = state.data.filterParams.selectedSubGenres.filter((subGenre) => subGenre !== action.payload);
            } else {
                state.data.filterParams.selectedSubGenres.push(action.payload);
            }
        },
        setClearSelectedGenres: (state) => {
            state.data.filterParams.selectedGenres = [];
            state.data.filterParams.selectedSubGenres = [];
            saveStateToLocalStorage(state);
        },
        setSelectRegion: (state, action) => {
            if (state.data.filterParams.selectedRegions.includes(action.payload)) {
                state.data.filterParams.selectedRegions = state.data.filterParams.selectedRegions.filter((region) => region !== action.payload);
            } else {
                state.data.filterParams.selectedRegions.push(action.payload);
            }
        },
        setSelectCountry: (state, action) => {
            if (state.data.filterParams.selectedCountries.includes(action.payload)) {
                state.data.filterParams.selectedCountries = state.data.filterParams.selectedCountries.filter((country) => country !== action.payload);
            } else {
                state.data.filterParams.selectedCountries.push(action.payload);
            }
            saveStateToLocalStorage(state);
        },
        setClearSelectedRegions: (state) => {
            state.data.filterParams.selectedRegions = [];
            state.data.filterParams.selectedCountries = [];
            saveStateToLocalStorage(state);
        },
        setSelectCategory: (state, action) => {
            if (state.data.filterParams.selectedCategories.includes(action.payload)) {
                state.data.filterParams.selectedCategories = state.data.filterParams.selectedCategories.filter((category) => category !== action.payload);
            } else {
                state.data.filterParams.selectedCategories.push(action.payload);
            }
            saveStateToLocalStorage(state);
        },
        setSelectBudget: (state, action) => {
            state.data.selectedBudget = action.payload;
            saveStateToLocalStorage(state);
        },
        setPlatformsAccountsCount: (state, action) => {
            state.data.platformsAccountsCount = action.payload;
            saveStateToLocalStorage(state);
        },
        setSortAccountsBy: (state, action) => {
            state.data.sortAccountsBy = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectPriceInfluencers: (state, action) => {
            state.data.selectPriceInfluencers = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectAmount: (state, action) => {
            state.data.amount = action.payload;
            saveStateToLocalStorage(state);
        },
        setSelectInfluencer: (state, action) => {
            state.data.selectInfluencers = action.payload;
            saveStateToLocalStorage(state);
        },
        updateSelectedInfluencer: (state, action) => {
          if (!action.payload.influencerId || !action.payload.instagramUsername) {
              return;
          }
          const index = state.data.selectInfluencers.findIndex(influencer => influencer.influencerId === action.payload.influencerId);
          if (index !== -1) {
              state.data.selectInfluencers[index] = action.payload;
          } else {
              state.data.selectInfluencers.push(action.payload);
          }
        },
        updateSelectInfluencer: (state, action) => {
            state.data.selectInfluencers = action.payload;
            saveStateToLocalStorage(state);
        },
        setCampaignName: (state, action) => {
            state.data.campaignName = action.payload;
            saveStateToLocalStorage(state);
        },
        setPaymentType: (state, action) => {
            state.data.paymentType = action.payload;
            saveStateToLocalStorage(state);
        },
        setCreatedAt: (state, action) => {
            state.data.createdAt = action.payload;
            saveStateToLocalStorage(state);
        },
        addVideo: (state, action) => {
            state.data.videos.push(action.payload);
            saveStateToLocalStorage(state);
        },
        updateVideo: (state, action) => {
            const content = state.data.videos.find(content => content._id === action.payload.contentId);
            content[action.payload.fieldName] = action.payload.value;
            saveStateToLocalStorage(state);
        },
        removeVideo: (state, action) => {
            const index = action.payload;
            if (state.data.videos[index]) {
                state.data.videos.splice(index, 1);
            }
            saveStateToLocalStorage(state);
        },
        setClearCampaignDetailsCreatePromo: (state, action) => {
            state.data.campaignName = "";
            state.data.videos = [];
            state.data.createdAt = "";
            saveStateToLocalStorage(state);
        },
        setClearFormCreatePromo: (state) => {
            state.data = {
                currency: "€",
                amount: 0,
                filterParams: {
                    selectedPlatforms: ['Instagram'],
                    selectedGenres: [],
                    selectedSubGenres: [],
                    selectedRegions: [],
                    selectedCountries: [],
                    selectedCategories: [],
                },
                sortAccountsBy: "Best Match",
                selectedBudget: null,
                platformsAccountsCount: {
                    instagram: 0,
                    tiktok: 0,
                    spotify: 0,
                    facebook: 0,
                    soundcloud: 0,
                    youtube: 0,
                    press: 0,
                },
                selectPrice: {
                    variant: 0,
                    genre: "",
                    price: 0,
                },
                selectInfluencers: [],
                selectPriceInfluencers: [],
                paymentType: "",
                paymentStatus: "wait",
                createdAt: "",
                videos: [],
                socialMedia: "",
                canGoBack: true,
            };
            state.proposalSystem = {campaignId: null};
            saveStateToLocalStorage(state);
        },
        setSocialMedia: (state, action) => {
            state.data.socialMedia = action.payload;
            saveStateToLocalStorage(state);
        },
        setCanGoBack: (state, action) => {
            state.data.canGoBack = action.payload;
            saveStateToLocalStorage(state);
        },
        setFullState: (state, action) => {
            state.currentWindow = action.payload.currentWindow;
            state.data.currency = action.payload.currency;
            state.data.amount = action.payload.amount;
            state.data.selectPrice = action.payload.selectPrice;
            state.data.selectInfluencers = action.payload.selectInfluencers;
            state.data.selectPriceInfluencers = action.payload.selectPriceInfluencers;
            state.data.campaignName = action.payload.campaignName;
            state.data.paymentType = action.payload.paymentType;
            state.data.paymentStatus = action.payload.paymentStatus;
            state.data.createdAt = action.payload.createdAt;
            state.data.videos = action.payload.videos;
            state.data.socialMedia = action.payload.socialMedia;
            state.data.canGoBack = action.payload.canGoBack;
            saveStateToLocalStorage(state);
        },
        setProposalSystemCampaignId: (state, action) => {
            if (!state.proposalSystem) {
                state.proposalSystem = { campaignId: null };
            }
            state.proposalSystem.campaignId = action.payload;
            saveStateToLocalStorage(state);
        }
    },
});

export const {
    setCurrentWindow,
    setSelectCurrency,
    setSelectPrice,
    setSelectPlatform,
    setSelectGenre,
    setSelectSubGenre,
    setClearSelectedGenres,
    setSelectRegion,
    setSelectCountry,
    setClearSelectedRegions,
    setSelectCategory,
    setSelectBudget,
    setPlatformsAccountsCount,
    setSortAccountsBy,
    setSelectAmount,
    setSelectInfluencer,
    updateSelectInfluencer,
    updateSelectedInfluencer,
    setCampaignName,
    setPaymentType,
    setCreatedAt,
    addVideo,
    updateVideo,
    removeVideo,
    setClearFormCreatePromo,
    setClearCampaignDetailsCreatePromo,
    setSelectPriceInfluencers,
    setSocialMedia,
    setCanGoBack,
    setFullState,
    setProposalSystemCampaignId,
} = createPromoSlice.actions;

export default createPromoSlice.reducer;
