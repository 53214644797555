import React from "react";
import Header from "../../../components/Header";
import Background from "../../../components/Background";
import {
    AdminCreateCampaignContainer
} from "../../../components/layout/account/admin/AdminProposalSystem/AdminCreateCampaignContainer";


const AccountAdminProposalSystemManageCampaign = () => {
    return (
        <div>
            <Header path="Admin Panel / Proposal System / Manage Proposal Campaign"/>
            <AdminCreateCampaignContainer/>
            <Background/>
        </div>
    );
};

export default AccountAdminProposalSystemManageCampaign;
