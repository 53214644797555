import React, {useEffect, useState} from 'react'
import arrow from "../../../../../images/icons/arrow.svg";
import TitleSection from "../../../../TitleSection";
import FormContainer from "../../../../form/FormContainer";
import TextInput from "../../../../form/TextInput";
import TextArea from "../../../../form/TextArea";
import StandardButton from "../../../../form/StandardButton";
import {useDispatch, useSelector} from "react-redux";
import {
    clearNewFullServicedCampaignState,
    setFieldNewFullServicedCampaignState
} from "../../../../../redux/slice/client-full-serviced-campaign";
import acceptIcon from "../../../../../images/icons/accept.svg";
import ModalWindow from "../../../../ModalWindow";
import axios from "axios";
import {encryptAndEncode} from "../../../../../utils/crypt";
import {NotificationManager} from "react-notifications";
import UseVerify from "../../../../../hooks/useVerify";

const CreateVipCampaign = () => {
    const dispatch = useDispatch();

    const {campaignGoal, videos, music, budget, additional} = useSelector((state) => state.createNewFullServicedCampaign);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setWindowWidth(window.innerWidth);
            });
        };
    }, []);

    const setValueOfField = (field, value) => {
        dispatch(setFieldNewFullServicedCampaignState({field, value}));
    }

    const clearForm = () => {
        dispatch(clearNewFullServicedCampaignState())
        window.location.reload();
    }

    const createNewFullServicedCampaign = async () => {
        const {dataFetch} = await UseVerify();

        const result = await axios.post(`${process.env.REACT_APP_SERVER}/promos/send-new-full-serviced-campaign-request`, {
            encryptedClientId: encryptAndEncode(dataFetch._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY),
            campaignGoal: campaignGoal,
            videos: videos,
            music: music,
            budget: budget,
            additional: additional,
        });

        if (result.data.code === 200) {
            setModalOpen(true);
            dispatch(clearNewFullServicedCampaignState());
            return;
        }

        NotificationManager.error('Something went wrong', 'Error', 3000);
    }

    return (
        <section className="account-client">
            <div className="account-client-block" style={{position: "relative", marginBottom: 60}}>
                <div className="back-btn-title" style={{width: windowWidth <= 900 ? '99%' : '90%'}}>
                    <button onClick={() => window.history.back()}>
                        <img src={arrow} alt={'back'}/>
                    </button>
                    <TitleSection title='FULL-SERVICE' span='CAMPAIGN REQUEST'/>
                </div>

                <FormContainer style={{width: windowWidth >= 1250 ? "45%" : "75%", margin: windowWidth >= 768 ? '60px auto 60px' : '30px auto 60px'}}>
                    <div className="new-vip-campaign-form-container">
                        <div id="inputs">
                            <TextInput
                                style={{marginTop: 0}}
                                title="Campaign Goal"
                                placeholder='Sell tickets, Promote a Residency, Brand Awareness'
                                value={campaignGoal}
                                setValue={(value) => setValueOfField('campaignGoal', value)}
                            />
                            <TextInput
                                style={{marginTop: 75}}
                                title="Videos"
                                placeholder='drive.google.com / dropbox link'
                                value={videos}
                                setValue={(value) => setValueOfField('videos', value)}
                            />
                            <TextInput
                                style={{marginTop: 75}}
                                title="Music"
                                placeholder='Enter downloadable music link, if applicable'
                                value={music}
                                setValue={(value) => setValueOfField('music', value)}
                            />
                            <TextInput
                                style={{marginTop: 75}}
                                title="Budget"
                                placeholder='3500€'
                                value={budget}
                                setValue={(value) => setValueOfField('budget', value)}
                            />
                            <TextArea
                                style={{marginTop: 75}}
                                title="Additional"
                                placeholder={'Any dates requests?\nAny Countries specific you want to reach?\nAny Links for Ticket sales or Music Streaming (Linkfire/FFM)?'}
                                value={additional}
                                setValue={(value) => setValueOfField('additional', value)}
                            />
                        </div>
                        <div id="buttons">
                            <StandardButton isRed={true}
                                            text='Delete'
                                            onClick={() => clearForm()}/>
                            <StandardButton isBlue={true}
                                            text='Create'
                                            onClick={() => createNewFullServicedCampaign()}/>
                        </div>
                    </div>
                </FormContainer>
            </div>

            <ModalWindow isOpen={isModalOpen} setClose={setModalOpen}>
                <div className="signup-client-modal">
                    <img className="signup-client-modal-icon" src={acceptIcon}/>

                    <h2 className="signup-client-modal-title">Congratulations!</h2>

                    <p className="signup-client-modal-second">
                        We will consider your request in the near future!
                    </p>

                    <StandardButton
                        text="Ok"
                        style={{
                            padding: "8px 80px",
                            marginTop: "30px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        onClick={() => {
                            setModalOpen(false);
                            window.location.reload();
                            window.location.href="/account/client";
                        }}
                    />
                </div>
            </ModalWindow>
        </section>
    )
}

export default CreateVipCampaign;