import React from 'react'
import "./NewCampaignDetails.css";
import {formatDateStringReport} from "../../../utils/validations";

export const NewCampaignDetails = ({
                                       date = "",
                                       price = "",
                                       canChangePrice = false,
                                       changePriceFunction = () => {
                                       },
                                       totalFollowers = "",
                                       countOfAccounts = "",
                                       countOfVideos = "",
                                   }) => {
    return (
        <div className="account-client-campaign-strategy-details">
            <div className="account-client-campaign-strategy-details-first">
                <p>Date Submitted: <span>{formatDateStringReport(date)}</span></p>
                {canChangePrice ? (
                    <p className="price-input">Price:
                        <input
                            value={price}
                            onChange={changePriceFunction}
                        />
                    </p>
                ) : (
                    <p>Price: <span>{price}€</span></p>
                )}
            </div>
            <div className="account-client-campaign-strategy-details-second">
                <p>Combined Followers: <span>{totalFollowers}</span></p>
                <p>Posts & Stories: <span>{countOfAccounts}</span></p>
            </div>
            <div className="account-client-campaign-strategy-details-third">
                <p>Video Options: <span>{countOfVideos}</span></p>
            </div>
        </div>
    )
}
